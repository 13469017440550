<template>
  <CField
    v-bind="{ ...$attrs, ...$props }"
    :label="label"
    :custom-class="customClass"
    :type="type"
    :message="firstErrorMessage"
  >
    <slot />
  </CField>
</template>

<script setup lang="ts">
import CField from '@cling/components/ui/Field'
import { Validation } from '@vuelidate/core'
import { computed } from 'vue'

type Props = {
  label?: string
  validator?: Validation
  messages?: Record<string, string>
}

const props = defineProps<Props>()

const type = computed(() => {
  if (props.validator?.$error) {
    return 'is-danger'
  }
  if (props.validator?.$dirty) {
    return 'is-success'
  }
  return undefined
})

const customClass = computed(() => {
  if (props.validator?.$error) {
    return 'text-red-500'
  }
  if (props.validator?.$dirty) {
    return 'text-green-500'
  }
  return undefined
})

const firstErrorMessage = computed(() => {
  if (!props.validator?.$errors.length) return ''

  const error = props.validator.$errors[0]
  const messageKey = error.$validator

  return props.messages?.[messageKey] ?? ''
})
</script>
