import {
  convertArrToObject,
  deepMergeReplace,
  getUniqueArrayElements
} from '@cling/store/utils'

import { mutationTypes } from './constants'

const {
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  DELETE_MANY_NOTIFICATIONS,
  SET_NOTIFICATIONS_FETCHED_AT,
  SET_NOTIFICATIONS_FETCHING,
  SET_NOTIFICATIONS_POSTING
} = mutationTypes

export default {
  /**
   * Set notifications
   * @param {Object} state Vuex state
   * @param {Object} object
   * @param {Object[]} object.data Array with notification objects
   */
  [SET_NOTIFICATIONS](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a notification or an array of notifications!')
    }
    const items = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(items)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  /**
   * @name CLEAR_NOTIFICATIONS
   * Clear all notifications
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_NOTIFICATIONS](state) {
    state.data = {}
    state.ids = []
  },

  /**
   * @name DELETE_MANY_NOTIFICATIONS
   * Deletes many notifications from the store
   *
   * @param {Object} state Vuex state
   * @param {Number|Number[]} ids One id or array of ids to delete
   */
  [DELETE_MANY_NOTIFICATIONS](state, ids) {
    const idArr = Array.isArray(ids) ? ids : [ids]
    idArr.forEach(id => {
      if (!state.data[id]) return
      delete state.data[id]

      state.ids = state.ids.filter(x => x !== id)
    })
  },

  /**
   * @name SET_NOTIFICATIONS_FETCHING
   * Set fetching status of documents
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_NOTIFICATIONS_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_NOTIFICATIONS_POSTING
   * Set posting status of documents
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_NOTIFICATIONS_POSTING](state, isPosting) {
    state.isPosting = isPosting
  },

  /**
   * @name SET_NOTIFICATIONS_FETCHED_AT
   * Set when notifications was last fetched at
   *
   * @param {Object} state Vuex state
   * @param {STRING} timestamp The timestamp when it was last fetched (ms)
   */
  [SET_NOTIFICATIONS_FETCHED_AT](state, timestamp) {
    state.lastFetchedAt = timestamp
  }
}
