<template>
  <portal to="modal">
    <transition name="slide-vertical" mode="out-in">
      <div v-if="shouldShow" :key="menuActions[0].tooltip" class="menu">
        <template v-for="(btn, i) in menuActions" :key="i">
          <div
            v-if="btn.type === 'divider' && !btn.hide"
            :key="'divider-' + i"
            class="menu-divider"
          />
          <div
            v-else-if="!btn.hide"
            :key="i"
            v-click-outside="
              () => {
                if (btn.popup) popupOpen = false
              }
            "
            :data-texteditor-tooltip="btn.tooltip"
            :class="{
              'is-active': btn.active && btn.active()
            }"
            class="menu-button"
            @mousedown="
              $event => {
                !btn.popup ? $event.preventDefault() : btn.onClick()
              }
            "
            @click="btn.onClick"
          >
            <CIcon :type="btn.icon || 'info'" size="22" />
            <div
              v-if="btn.popup && popupOpen"
              style="
                position: absolute;
                left: 50%;
                bottom: calc(100% + 1em);
                background-color: white;
                padding: 10px 10px calc(2 * var(--rem)) 10px;
                transform: translate(-50%, 0%);
                color: hsl(0, 0%, 35%);
                width: 250px;
                box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
                border: 1px solid hsl(0, 0%, 88%);
                border-radius: 8px;
                cursor: default;
              "
            >
              <FormInputRow
                v-for="(opt, k) in btn.popup()"
                :key="k"
                :label="opt.label"
                :inputs="opt.inputs"
              />
            </div>
          </div>
        </template>
        <TextTemplates
          v-if="extensions.snippets && !editor.isActive('table')"
          :placement="'top'"
          @mousedown.prevent
          @open="popupOpen = true"
          @select="setTextToEditor"
          @close="popupOpen = false"
        >
          <div
            :data-texteditor-tooltip="
              $t('_common:insert', { thing: $t('_common:snippet') })
            "
            class="menu-button"
          >
            <CIcon type="editorSnippets" size="22" />
          </div>
        </TextTemplates>
        <input
          ref="inputImgUpload"
          type="file"
          style="display: none"
          @change="
            file => {
              $emit('upload-image', { file })
              popupOpen = false
            }
          "
        />
      </div>
    </transition>
  </portal>
</template>

<script>
import FormInputRow from '@cling/components/document/form/FormInputRow'
import windowSize from '@cling/mixins/windowSize'

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FormInputRow,
    TextTemplates: defineAsyncComponent(
      () => import('@cling/components/document/form/TextTemplates')
    )
  },
  emits: ['upload-image'],
  mixins: [windowSize],
  props: {
    editor: {
      type: Object,
      required: true
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    extensions: {
      type: Object,
      default: () => ({})
    }
  },
  i18nOptions: {
    namespaces: 'tableMenu',
    messages: {
      en: {
        addColumnBefore: 'Insert column to the left',
        addColumnAfter: 'Insert column to the right',
        deleteColumn: 'Delete column',
        addRowBefore: 'Insert row above',
        addRowAfter: 'Insert row below',
        deleteRow: 'Delete row',
        toggleHeaderCell: 'Toggle background cell',
        mergeOrSplit: 'Merge or split cells',
        appearance: 'Table style',
        deleteTable: 'Delete table'
      },
      sv: {
        addColumnBefore: 'Infoga kolumn till vänster',
        addColumnAfter: 'Infoga kolumn till höger',
        deleteColumn: 'Ta bort kolumn',
        addRowBefore: 'Infoga rad ovanför',
        addRowAfter: 'Infoga rad under',
        deleteRow: 'Ta bort rad',
        toggleHeaderCell: 'Växla bakgrund',
        mergeOrSplit: 'Slå samman eller dela upp cell',
        appearance: 'Utseende',
        deleteTable: 'Ta bort tabell'
      }
    }
  },
  data() {
    return {
      popupOpen: false,
      tableActions: [
        {
          // disabled: !this.editor.can().addColumnBefore(),
          onClick: () => this.editor.chain().focus().addColumnBefore().run(),
          icon: 'tableInsertColumnBefore',
          tooltip: this.$t('addColumnBefore')
        },
        {
          // disabled: !this.editor.can().addColumnAfter(),
          onClick: () => this.editor.chain().focus().addColumnAfter().run(),
          icon: 'tableInsertColumnAfter',
          tooltip: this.$t('addColumnAfter')
        },
        {
          // disabled: !this.editor.can().deleteColumn(),
          onClick: () => this.editor.chain().focus().deleteColumn().run(),
          icon: 'tableDeleteColumn',
          tooltip: this.$t('deleteColumn')
        },
        {
          type: 'divider'
        },
        {
          // disabled: !this.editor.can().addRowBefore(),
          onClick: () => this.editor.chain().focus().addRowBefore().run(),
          icon: 'tableInsertRowBefore',
          tooltip: this.$t('addRowBefore')
        },
        {
          // disabled: !this.editor.can().addRowAfter(),
          onClick: () => this.editor.chain().focus().addRowAfter().run(),
          icon: 'tableInsertRowAfter',
          tooltip: this.$t('addRowAfter')
        },
        {
          // disabled: !this.editor.can().deleteRow(),
          onClick: () => this.editor.chain().focus().deleteRow().run(),
          icon: 'tableDeleteRow',
          tooltip: this.$t('deleteRow')
        },
        {
          type: 'divider'
        },
        {
          // disabled: !this.editor.can().toggleHeaderCell(),
          onClick: () => this.editor.chain().focus().toggleHeaderCell().run(),
          icon: 'tableHeaderCell',
          tooltip: this.$t('toggleHeaderCell')
        },
        {
          // disabled: !this.editor.can().mergeOrSplit(),
          onClick: () => this.editor.chain().focus().mergeOrSplit().run(),
          icon: 'tableEditCell',
          tooltip: this.$t('mergeOrSplit')
        },
        {
          onClick: () => {
            this.popupOpen = true
          },
          icon: 'drop',
          tooltip: this.$t('appearance'),
          popup: () => {
            const { borderColor, borderWidth, cellPadding, headerBackground } =
              this.editor.getAttributes('table')
            return this.generateStyleInputs({
              borderColor,
              borderWidth,
              cellPadding,
              headerBackground,
              set: (key, value) =>
                this.editor.commands.updateAttributes('table', { [key]: value })
            })
          }
        },
        {
          type: 'divider'
        },
        {
          // disabled: !this.editor.can().deleteTable(),
          onClick: () => this.editor.chain().focus().deleteTable().run(),
          icon: 'tableDelete',
          tooltip: this.$t('deleteTable')
        }
      ],
      defaultActions: [
        {
          hide: this.mq === 'sm' || !this.editor.commands.toggleHeading,
          onClick: () => this.editor.commands.toggleHeading({ level: 1 }),
          active: () => this.editor.isActive('heading', { level: 1 }),
          icon: 'editorH1',
          tooltip: this.$t('_common:designLabels.h1'),
          hideForMobile: true
        },
        {
          hide: this.mq === 'sm' || !this.editor.commands.toggleHeading,
          onClick: () => this.editor.commands.toggleHeading({ level: 2 }),
          active: () => this.editor.isActive('heading', { level: 2 }),
          icon: 'editorH2',
          tooltip: this.$t('_common:designLabels.h2'),
          hideForMobile: true
        },
        {
          hide: this.mq === 'sm' || !this.editor.commands.toggleHeading,
          onClick: () => this.editor.commands.toggleHeading({ level: 3 }),
          active: () => this.editor.isActive('heading', { level: 3 }),
          icon: 'editorH3',
          tooltip: this.$t('_common:designLabels.h3'),
          hideForMobile: true
        },
        {
          hide: this.mq === 'sm' || !this.editor.commands.toggleBlockquote,
          onClick: () => this.editor.commands.toggleBlockquote(),
          active: () => this.editor.isActive('blockquote'),
          icon: 'editorQuote',
          tooltip: this.$t('_common:designLabels.blockquote'),
          hideForMobile: true
        },
        {
          type: 'divider',
          hideForMobile: true
        },
        {
          hide: !this.editor.commands.toggleOrderedList,
          onClick: () => this.editor.commands.toggleOrderedList(),
          active: () => this.editor.isActive('orderedList'),
          icon: 'editorNumberedList',
          tooltip: this.$t('_common:designLabels.numberedList')
        },
        {
          hide: !this.editor.commands.toggleBulletList,
          onClick: () => this.editor.commands.toggleBulletList(),
          active: () => this.editor.isActive('bulletList'),
          icon: 'editorBulletList',
          tooltip: this.$t('_common:designLabels.bulletList')
        },
        {
          type: 'divider'
        },
        {
          hide: !this.editor.commands.setImage,
          onClick: () => {
            this.popupOpen = true
            this.$refs.inputImgUpload.click()
          },
          icon: 'editorImage',
          tooltip: this.$t('_common:insert', {
            thing: this.$t('_common:image')
          })
        },
        {
          hide: !this.editor.commands.insertTable,
          onClick: () =>
            this.editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run(),
          icon: 'table',
          tooltip: this.$t('_common:table').capitalize()
        },
        {
          hide: !this.editor.commands.setHorizontalRule,
          onClick: () => this.editor.chain().focus().setHorizontalRule().run(),
          icon: 'editorLine',
          tooltip: this.$t('_common:designLabels.line')
        },
        {
          hide: !this.extensions.mentions && !this.extensions.snippets,
          type: 'divider'
        },
        {
          hide: !this.extensions.mentions,
          onClick: () => this.editor.chain().focus().insertContent('@').run(),
          icon: 'editorMention',
          tooltip: this.$t('_common:insert', {
            thing: this.$t('_common:reference')
          })
        }
      ]
    }
  },
  computed: {
    shouldShow() {
      return this.isFocused || this.popupOpen
    },
    menuActions() {
      const isTable = this.editor.isActive('table')
      if (isTable) return this.tableActions
      return this.defaultActions.filter(x =>
        x.hideForMobile ? this.mq !== 'sm' : true
      )
    }
  },
  methods: {
    generateStyleInputs({
      borderColor,
      borderWidth,
      cellPadding,
      headerBackground,
      set
    }) {
      const cssDefReg = /([\d+.]*)(.*)/
      const splitCssDefinition = str => {
        return (str || '').match(cssDefReg)
      }
      const borderWidthNumber = splitCssDefinition(borderWidth)[1]
      const cellPaddingNumber = splitCssDefinition(cellPadding)[1]
      return [
        {
          label: this.$t('_common:designLabels.borderWidth'),
          inputs: [
            {
              component: 'input',
              props: {
                value: borderWidthNumber,
                type: 'number',
                minValue: 0
              },
              onInput: v => set('borderWidth', `${v}px`)
            },
            {
              component: 'step',
              props: {
                value: borderWidthNumber
              },
              onIncrease: () =>
                set(
                  'borderWidth',
                  `${parseFloat(borderWidthNumber || 0) + 1}px`
                ),
              onDecrease: () =>
                set(
                  'borderWidth',
                  `${parseFloat(borderWidthNumber || 0) - 1}px`
                )
            }
          ]
        },
        {
          label: this.$t('_common:designLabels.color'),
          inputs: [
            {
              component: 'color',
              props: { value: borderColor || '' },
              onInput: v => set('borderColor', v)
            }
          ]
        },
        {
          label: this.$t('_common:designLabels.padding'),
          inputs: [
            {
              component: 'input',
              props: {
                value: cellPaddingNumber,
                type: 'number',
                minValue: 0
              },
              onInput: v => set('cellPadding', `${v}px`)
            },
            {
              component: 'step',
              props: {
                value: cellPaddingNumber
              },
              onIncrease: () =>
                set(
                  'cellPadding',
                  `${parseFloat(cellPaddingNumber || 0) + 1}px`
                ),
              onDecrease: () =>
                set(
                  'cellPadding',
                  `${parseFloat(cellPaddingNumber || 0) - 1}px`
                )
            }
          ]
        },
        {
          label: this.$t('_common:designLabels.background'),
          inputs: [
            {
              component: 'color',
              props: {
                value: headerBackground || '',
                showReset: true,
                position: 'is-top-right'
              },
              onInput: v => set('headerBackground', v)
            }
          ]
        }
      ]
    },
    setTextToEditor(text) {
      this.editor.chain().focus().insertContent(text).run()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';
.menu {
  background: hsl(0, 0%, 18%);
  border-radius: calc(0.5 * var(--rem));
  padding: 0 8px;
  position: fixed;
  left: 50%;
  bottom: calc(1 * var(--rem));
  display: flex;
  transform: translate3d(-50%, 0, 0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  align-items: center;
  z-index: 9999;

  :deep(.menu-button) {
    position: relative;
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: rgba($white, 0.7);
    padding: 4px 5px;
    border-radius: 3px;
    cursor: pointer;
    height: 42px;
    &:hover {
      color: $white;
    }
    &:last-child {
      margin-right: 0;
    }
    &.menu-button.is-active {
      color: hsl(190, 100%, 50%);
    }
    & .menu-button.is-disabled {
      color: rgba($white, 0.3);
      cursor: disabled;
    }
  }
  &-divider {
    background-color: rgba($white, 0.18);
    width: 1px;
    height: 30px;
    margin: 0 0.5em;
  }
}
.slide-vertical-enter-from,
.slide-vertical-leave-to {
  opacity: 0;
  transform: translate3d(-50%, 15px, 0);
}
.slide-vertical-enter-active,
.slide-vertical-leave-active {
  transition-duration: 150ms;
}
</style>
