import features from '@cling/services/features'

export default {
  install(app, { store } = {}) {
    features.init({ store })

    app.config.globalProperties.$featureUpgrade =
      features.checkFeatureUpgrade.bind(features)
  }
}
