<template>
  <div>
    <Sortable
      :model-value="modelValue"
      item-key="_id"
      :options="{
        animation: 200,
        group: 'value',
        filter: '.is-transparent-dark',
        ghostClass: 'sortable-ghost',
        ghost: 'sortable-ghost'
      }"
      :class="{
        'recently-added': recentlyAdded
      }"
      class="checklist-container"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #item="{ item, index: i }">
        <div class="checklist-row">
          <CCheckbox
            :model-value="item.isChecked"
            :type="tag || 'secondary'"
            size="medium"
            style="padding-top: 0.65em"
            @update:modelValue="
              item.text
                ? $emit(
                    'update:modelValue',
                    immutable(modelValue).set({ ...item, isChecked: $event }, i)
                  )
                : null
            "
            @pointerdown.stop
          />
          <CInput
            ref="input"
            :model-value="item.text"
            :custom-class="'checklist-input'"
            type="textarea"
            auto-resize
            :placeholder="$t('placeholder')"
            size="medium"
            expanded
            reset
            style="width: 100%"
            @update:modelValue="
              $emit(
                'update:modelValue',
                immutable(modelValue).set({ ...item, text: $event }, i)
              )
            "
            @keydown.enter.prevent
            @keyup.enter="
              addOnEnter
                ? item.text
                  ? onChecklistAdd()
                  : null
                : $event.target.blur()
            "
            @blur="
              addOnEnter && item.text
                ? onChecklistAdd
                : item.text
                  ? (addOnEnter = false)
                  : onRemove(i)
            "
          />
          <div class="checklist-control" style="padding-top: 3px">
            <CButton
              icon="x"
              circle
              type="none"
              pattern="tertiary"
              @click="onRemove(i)"
              @pointerdown.stop
            />
          </div>
        </div>
      </template>
    </Sortable>
    <CButton
      :icon-props="{
        strokeWidth: 3,
        class: `has-text-${tag || 'secondary'}`
      }"
      type="text"
      pattern="tertiary"
      icon-left="plus"
      class="is-left"
      style="margin: 0.5em 0 1em 0; width: 100%"
      @click="onChecklistAdd"
    >
      {{ $t('add') }}
    </CButton>
  </div>
</template>

<script>
import CCheckbox from '@cling/components/ui/Checkbox'
import Sortable from '@cling/components/ui/Sortable'
import { immutable } from '@cling/utils'

export default {
  i18nOptions: {
    namespaces: 'projectTaskChecklist',
    messages: {
      en: {
        add: 'Add checklist',
        placeholder: 'Write something'
      },
      sv: {
        add: 'Lägg till checklista',
        placeholder: 'Skriv något'
      }
    }
  },
  name: 'ProjectTaskChecklist',
  components: {
    Sortable,
    CCheckbox
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    tag: {
      type: String,
      default: null
    },
    getDefaultItem: {
      type: Function,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      recentlyAdded: false,
      addOnEnter: false
    }
  },
  methods: {
    immutable,
    toggleClass(value, timeout = 300) {
      this[value] = true
      setTimeout(() => {
        this[value] = false
      }, timeout)
    },
    onChecklistAdd() {
      this.toggleClass('recentlyAdded')
      if (
        this.modelValue.length === 0 ||
        this.modelValue[this.modelValue.length - 1].text
      ) {
        this.$emit(
          'update:modelValue',
          this.immutable(this.modelValue).add(this.getDefaultItem())
        )
      }
      this.$nextTick(() => {
        this.$refs.input.focus()
        this.addOnEnter = true
      })
    },
    onRemove(i) {
      this.toggleClass('recentlyAdded')
      this.$emit('update:modelValue', immutable(this.modelValue).remove(i))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.checklist {
  &-container {
    padding-top: 1em;
  }
  &-row {
    padding: 0 0.5em;
    display: flex;
    border-radius: 0.5em;
    align-items: flex-start;
    cursor: pointer;
    background-color: $white;
    .checklist-control {
      visibility: hidden;
      pointer-events: none;
    }
    &:hover {
      background-color: hsl(var(--gray-color-100) / 1);
      .checklist-control {
        visibility: initial;
        pointer-events: initial;
      }
    }
  }
  &-row :deep(.checklist-input) {
    user-select: none;
    min-height: initial !important;
    height: 1px;
    padding: 0.5em !important;
    cursor: pointer;
    &:focus {
      user-select: initial;
      cursor: text;
    }
  }
}

.checklist-container {
  :deep(.sortable-drag) {
    opacity: 1;
    box-shadow: none;
  }
  :deep(.no-move) {
    transition: none;
  }
  :deep(.sortable-chosen) {
    cursor: grabbing;
  }
  :deep(.is-dragged),
  :deep(.sortable-ghost) {
    border: 2px dashed hsl(0, 0%, 80%) !important;
    background-color: transparent !important;
    * {
      visibility: hidden;
    }
  }
}
</style>
