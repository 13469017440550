import config from '@cling/config'

import { reactive } from 'vue'

export default {
  install(app, { store } = {}) {
    const brandObj = reactive({ brand: config.brand })

    // Make available i store
    store.$brand = brandObj.brand // Note: Is set if changed below

    app.mixin({
      computed: {
        $brand: {
          get: () => brandObj.brand,
          set: brand => {
            brandObj.brand = brand
            store.$brand = brand // Update store property as well
          }
        }
      }
    })
  }
}
