<template>
  <a :href="href" @click="handleClick" v-bind="$attrs">
    <slot />
  </a>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

type To = {
  name: string
  params?: Record<string, string | number>
}

const { to } = defineProps<{ to: To }>()

const router = useRouter()

const href = computed(() => {
  return router.resolve({ name: to.name, params: to.params }).href
})

const handleClick = (e: MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
  router.push(to)
}
</script>
