import { mutationTypes } from './constants'
import {
  convertArrToObject,
  deepMergeReplace,
  getUniqueArrayElements
} from '../../utils'

const {
  SET_UNIT_TYPES,
  DELETE_UNIT_TYPE,
  SET_UNIT_TYPE_FETCHING,
  SET_UNIT_TYPE_POSTING
} = mutationTypes

export default {
  [SET_UNIT_TYPES](state, { data }) {
    if (typeof data !== 'object')
      throw Error('Data must be an unitType or array of unitTypes!')
    const items = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(items)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  [DELETE_UNIT_TYPE](state, id = '') {
    delete state.data[id]

    const index = state.ids.indexOf(id)
    if (index !== -1) state.ids.splice(index, 1)
  },

  [SET_UNIT_TYPE_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  [SET_UNIT_TYPE_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}
