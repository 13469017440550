import config from '@cling/config'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  sign: 'allekirjoita',
  accept: 'hyväksy',
  address: 'osoite',
  and: 'ja',
  answerFeedback: {
    intermediate: {
      title: 'Kiitos mielipiteesi jakamisesta.'
    },
    no: {
      start: {
        action: 'Lähetä',
        placeholder: 'Miten voisimme kehittyä?',
        text: 'Voisimmeko kehittää sovellustamme jotenkin?',
        title: 'Kiitos jakamisesta.'
      },
      success: {
        action: 'Lue lisää $t(brand.name)-sovelluksesta',
        secondary: 'Palaa takaisin asiakirjaan',
        text: 'Palautteen lähettäminen onnistui. Tiimimme tarkistaa palautteesi huolellisesti.',
        title: 'Kiitos.'
      }
    },
    start: {
      no: 'En',
      title: 'Piditkö $t(brand.name)-sovelluksesta?',
      yes: 'Kyllä'
    },
    yes: {
      action: 'Rekisteröidy ilmaiseksi',
      secondary: 'Lue lisää $t(brand.name)-sovelluksesta',
      text: 'Luo ja lähetä kauniita asiakirjoja $t(brand.name)-sovelluksella. Tutustu kaikkiin verkkopohjaisten ja sähköisesti allekirjoitettujen asiakirjojen lähettämisen hyötyihin.',
      title: 'Aloita $t(brand.name)-sovelluksen käyttö maksutta.'
    },
    custom: {
      title: 'Kiitos.',
      text: 'Palautteen lähettäminen onnistui. Tiimimme tarkistaa palautteesi huolellisesti.',
      action: 'Palaa takaisin asiakirjaan'
    }
  },
  answerModal: {
    answerSuccess: 'Vastauksesi lähetettiin onnistuneesti.',
    bankId: {
      component: {
        defaultError:
          'Tapahtui virhe. Varmista, että olet kirjoittanut henkilötunnuksesi oikein, ja yritä uudelleen.',
        signingInterrupted: 'Allekirjoittaminen keskeytyi, yritä uudelleen...',
        startOnThisDevice: 'Käynnistä BankID tällä laitteella',
        validations: {
          socialNo: {
            personalNumber: 'Virheellinen henkilötunnus',
            required: 'Kirjoita henkilötunnuksesi'
          }
        },
        viewText: {
          canceled: {
            text: 'Allekirjoittaminen peruuntui. Yritä uudelleen',
            title: 'Allekirjoittaminen keskeytyi'
          },
          error: {
            text: 'Jokin meni vikaan. Yritä uudelleen.',
            title: 'Tapahtui virhe'
          },
          form: {
            text: 'Aloita sopimuksen allekirjoittaminen kirjoittamalla henkilötunnuksesi.',
            title: 'Allekirjoita asiakirja BankID:llä'
          },
          new: {
            spinnerText: 'Skannaa laitteesi QR-koodi.',
            text: 'Avaa BankID laitteellasi ja allekirjoita sopimus.',
            title: 'Allekirjoita asiakirja'
          },
          signing: {
            spinnerText: 'Allekirjoita asiakirja.',
            text: 'Avaa BankID laitteellasi ja allekirjoita sopimus.',
            title: 'Allekirjoittaminen käynnissä'
          },
          waiting: {
            spinnerText: 'Odotetaan BankID:n avaamista.',
            text: 'Avaa BankID laitteellasi ja allekirjoita sopimus.',
            title: 'Allekirjoita asiakirja'
          }
        }
      },
      start: {
        action: 'Allekirjoita BankID:llä',
        text: 'Hyväksy ja allekirjoita asiakirja BankID:llä.',
        title: 'Allekirjoita BankID:llä'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    decline: {
      component: {
        label: 'Kommentti (valinnainen)'
      },
      start: {
        action: 'Hylkää',
        text: 'Jos haluat hylätä tämän asiakirjan, vahvista napsauttamalla alla olevaa painiketta.',
        title: 'Hylkää asiakirja'
      },
      success: {
        text: 'Kiitos vastauksesta.',
        textFallback: '$t(answerModal.decline.success.text)',
        title: 'Olet hylännyt asiakirjan.'
      }
    },
    signature: {
      component: {
        draw: {
          canvasLabel: 'Piirrä allekirjoitus',
          tab: 'Draw'
        },
        subTitle: 'Valitse allekirjoitustapa',
        terms:
          'Allekirjoittamalla asiakirjan hyväksyn, että sähköinen asiakirja edustaa käsin kirjoitettua allekirjoitustani ja on yhtä pätevä.',
        text: {
          canvasLabel: 'Esikatsele',
          nameLabel: 'Allekirjoittaja',
          namePlaceholder: 'Kirjoita nimesi',
          tab: 'Kirjoita'
        }
      },
      start: {
        action: 'Allekirjoita ja hyväksy',
        text: '',
        title: 'Hyväksy asiakirja'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    versionError: {
      accept: { title: 'Ei voitu allekirjoittaa asiakirjaa' },
      decline: { title: 'Ei voitu hylätä asiakirjaa' },
      text: 'Asiakirja on muokattu, kun katselit sitä. Lataa uudelleen ja tarkista uusi asiakirja vastataksesi.',
      btn: 'Lataa uudelleen'
    },
    successText:
      'Kun kaikki asiaankuuluvat osapuolet ovat allekirjoittaneet asiakirjan, saat asiakirjan sinetöidyn kopion sähköpostitse osoitteeseen {{email}}.',
    successTextFallback:
      'un kaikki asiaankuuluvat osapuolet ovat allekirjoittaneet asiakirjan, voit palata tälle verkkosivulle ja ladata asiakirjan sinetöidyn kopion.',
    successTextFallbackSingle: 'Asiakirja on nyt allekirjoitettu ja valmis.',
    successTextSingle:
      'Vahvistusviesti ja asiakirjan sinetöity kopio on lähetetty sähköpostitse osoitteeseen {{email}}.',
    successTitle: 'Olet allekirjoittanut asiakirjan.'
  },
  approved: 'hyväksytty',
  approx: 'noin',
  cancel: 'Peruuta',
  chapters: {
    answerButtons: {
      approveDescription:
        'Olemme ilmoittaneet lähettäjälle {{- sender}}, että asiakirja on hyväksytty.',
      approveTitle: 'Asiakirja on hyväksytty!',
      denyDescription:
        'Olemme ilmoittaneet lähettäjälle {{- sender}}, että et ole kiinnostunut.',
      denyTitle: 'Asiakirja hylättiin',
      expiredTitle: 'Asiakirja on vanhentunut',
      expiredDescription:
        'Asiakirjan vanhentumispäivä on mennyt. Dokumentin allekirjoittaminen ei ole enää mahdollista.',
      editPropDes: 'Muokkaa omaisuuden nimitystä',
      enterPropDes: 'Anna omaisuuden nimitys',
      inPersonSignatureHint:
        'Sinut on pyydetty allekirjoittamaan asiakirja henkilökohtaisesti. Ota yhteyttä, jos haluat sen sijaan allekirjoittaa digitaalisen kopion',
      waitingDescription:
        'Odotetaan, että {{remaining}} muuta antaa vastauksen.',
      youHave: 'Sinulla on '
    },
    articleItem: {
      eligibleForDeduction: 'Oikeutettu {{type}}-vähennykseen'
    },
    articleList: {
      changeShowVatTooltip: 'Vaihda ja näytä {{type}}',
      title: 'Palvelut / Tuotteet'
    },
    packageGroup: {
      errorCallout: 'Valitse vähintään yksi vaihtoehto',
      selectableOptions: 'Valittavissa olevat vaihtoehdot'
    },
    packageItem: {
      add: 'Lisää vaihtoehto',
      select: 'Valitse vaihtoehto',
      selected: 'Valittu'
    },
    parties: {
      allRiskAndLiability: 'On kattava riski- ja vastuuvakuutus',
      fTax: 'Verovelvollinen'
    },
    pricingSummary: {
      blocks: {
        approx: 'Arvioitu hinta',
        discount: 'Alennus',
        deductionSubValue: 'Kokonaishinta ilman alennuksia {{price}}',
        maxPrice: 'Enimmäishinta',
        maxPriceVat: 'Enimmäishinta {{vat}}',
        net: 'Netto',
        price: 'Hinta',
        rounding: 'Pyöristys',
        subTitleArticles: 'Tuotteet/palvelut kiinteillä kustannuksilla',
        summary: 'Yhteenveto',
        total: 'Yhteensä',
        typeDeduction: '{{type}}-vähennyksen jälkeen'
      },
      placeholder: {
        description:
          'Valitse itsellesi parhaiten sopivat vaihtoehdot napsauttamalla ',
        descriptionBold: 'Valitse-painiketta',
        title: 'Valitse vaihtoehto'
      },
      terms: {
        houseWork:
          '{{type}}-työn kokonaishinta vähennyksen jälkeen edellyttää, että suoritettu työ oikeuttaa verovähennykseen, että ostajalla on käytettävissä riittävästi vähennystä ja ettei vähennystä ole käytetty muihin {{type}}-töihin. Ostaja on vastuussa näiden tietojen hallinnasta. \n',
        houseWorkApprox:
          'Hinta on arvio. Huomaa, että hinta voi muuttua jopa 15 %. Jos palveluihin sisältyy maa-/purkutöitä tai vastaavia töitä. Huomaa, että hinta saattaa muuttua jopa 25 %.',
        reverseVat:
          'Huomaa, että rakennustöihin sovelletaan käänteistä verovelvollisuutta.'
      }
    },
    termsDisclaimer: {
      description: 'Hyväksymällä tämän asiakirjan hyväksyt ',
      termsAndConditions: 'ehdot',
      title: 'Ehdot'
    }
  },
  close: 'Sulje',
  company: 'yritys',
  deduction: {
    after: 'vähennyksen jälkeen',
    afterType: 'jälkeen {{type}}-vähennys',
    deduction: 'vähennys',
    greenRot15: 'ROT-vähennys',
    greenRot15_percentage: 'ROT-vähennys 15 %',
    greenRot20: 'ROT-vähennys',
    greenRot20_percentage: 'ROT-vähennys 20 %',
    greenRot50: 'ROT-vähennys',
    greenRot50_percentage: 'ROT-vähennys 50 %',
    manualType: 'Mukautettu {{type}}-vähennys',
    rot: 'ROT',
    rot_percentage: 'ROT',
    rut: 'RUT',
    rut_percentage: 'RUT',
    type: '{{type}}-vähennys'
  },
  denied: 'hylätty',
  deny: 'Hylkää',
  denyModal: {
    denyButton: 'Hylkää asiakirja',
    helpfulInformation:
      'Ilmoitamme lähettäjälle {{-senderName}}, {{-senderCompany}}, että olet hylännyt asiakirjan.',
    otherPlaceholder: 'Valinnainen. Kerro, miksi hylkäät asiakirjan.',
    subTitle: 'Vahvista napsauttamalla Hylkää-painiketta.',
    successTitle: 'Olet hylännyt asiakirjan',
    thanksAnswer: 'Kiitos vastauksestasi!',
    title: 'Hylkää asiakirja'
  },
  detail_plural: 'tiedot',
  document: 'Asiakirja',
  document_plural: 'Asiakirjat',
  documentDetails: {
    subTitle: 'Tietoa asiakirjasta.'
  },
  documentVoided: {
    title: 'Asiakirja ei ole enää saatavilla.',
    description: 'Lähettäjä on päättänyt mitätöidä asiakirjan.'
  },
  documentTemplateView: {
    emailTemplate: {
      expires: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.expires.title) $t(documentTemplateView.emailTemplate.expires.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      first: {
        content:
          'Olet saanut asiakirjan yritykseltä {{company.name}}. Lue lisää ja anna vastauksesi napsauttamalla linkkiä.',
        subject: '$t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.first.title) $t(documentTemplateView.emailTemplate.first.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.greetingName)'
      },
      reminder: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.reminder.title) $t(documentTemplateView.emailTemplate.reminder.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      viewed: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.viewed.title) $t(documentTemplateView.emailTemplate.viewed.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      }
    },
    greetingName: 'Hei{{clientFirstName}}!',
    readMore: 'Lue lisää:',
    readMoreAndReply: 'Lue lisää ja vastaa:',
    reminder: 'Muistutus!',
    sharedSubject:
      'Asiakirja yritykseltä {{company.name}} - {{document.data.name}}',
    smsContent:
      '{{company.name}} on lähettänyt sinulle asiakirjan, johon voit vastata.',
    smsTemplate: {
      expires: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      first: {
        text: '$t(documentTemplateView.greetingName)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      reminder: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      viewed: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      }
    }
  },
  email: 'sähköposti',
  enterYour: 'Kirjoita {{thing}}',
  event: {
    accepted: 'Asiakirjan on allekirjoittanut {{by}}.',
    denied: 'Asiakirjan on hylännyt {{by}}.',
    event_plural: 'tapahtumat',
    expired: 'Asiakirja on vanhentunut.',
    requestChange: 'Asiakirjan tilaksi on merkitty manuaalisesti {{status}}.',
    sent: 'Asiakirja lähetetty henkilölle {{to}}.',
    statusChanged: 'Asiakirjan tilaksi merkitty {{status}}.',
    viewed: 'On nähty',
    viewedName: '{{name}} tarkasteli asiakirjaa.'
  },
  goBack: 'Palaa takaisin',
  less: 'vähemmän',
  login: 'Kirjaudu sisään',
  logout: 'Kirjaudu ulos',
  myPages: {
    docCallout: {
      form: {
        text: 'Asiakirjakirjastossa voit tarkastella kaikkia saamiasi ja sähköpostiisi yhdistettyjä asiakirjoja.',
        title: 'Asiakirjakirjasto'
      },
      loginLink: 'kirjautumislinkki',
      goToMyPagesTitle: 'Siirry asiakirjasivulle'
    },
    documentItem: {
      approved: 'Hyväksytty',
      expired: 'Vanhentunut'
    },
    documentsPlaceholder: 'Et ole vielä saanut asiakirjoja.',
    pageDescription: 'Täältä löydät kaikki saamasi asiakirjat.',
    requestForm: {
      alreadySentDescription:
        'Kirjautumislinkki on jo lähetetty osoitteeseen {{email}}. Sen saapuminen saattaa kestää noin minuutin.',
      alreadySentTitle: 'Kirjautumislinkki on jo lähetetty',
      emailFormat: 'Kelvollinen muoto: nimi@esimerkki.com',
      emailRequired: 'Kirjoita sähköpostiosoitteesi',
      sendLink: 'Lähetä kirjautumislinkki',
      sentDescription:
        'Olemme lähettäneet linkin osoitteeseen {{email}}. Kirjaudu sisään linkin avulla.',
      sentDescriptionSub:
        'Turvallisuussyistä voit kirjautua sisään vain kirjautumislinkin kautta.',
      sentTitle: 'Kirjautumislinkki lähetetty sähköpostiisi'
    },
    title: 'Omat sivut'
  },
  name: 'nimi',
  orgNoShort: 'Y-tunnus',
  participant_plural: 'osallistujat',
  sender: 'lähettäjä',
  phone: 'puhelin',
  price: {
    approx: 'Arvioitu hinta',
    discount: 'Alennus',
    fixed: 'Kiinteä hinta',
    markup: 'Hinnankorotus',
    maxPrice: 'enimmäishinta',
    net: 'Nettosumma',
    openAcc: 'Avaa tili',
    openAccMaxPrice: 'Avaa tili enimmäishinnalla',
    openAccPrice: 'Avaa tili',
    price: 'hinta',
    price_plural: 'hinnat',
    pricing: 'hinnoittelu',
    proposal: 'Paketit',
    total: 'Kokonaishinta'
  },
  print: 'Tulosta{{thing,inlineSpace}}',
  download: 'Lataa{{thing,inlineSpace}}',
  reference: 'viite',
  replyBy: 'Vastaa',
  see: 'näytä',
  select: 'Valitse{{thing,inlineSpace}}',
  send: 'lähetä{{thing,inlineSpace}}',
  series: {
    ata: 'Liittymissopimus',
    contract: 'Sopimus',
    default: '$t(document)',
    document: 'Asiakirja',
    proposal: 'Ehdotus'
  },
  show: 'Näytä{{thing,inlineSpace}}',
  socialNo: 'sosiaaliturvatunnus',
  status: {
    accepted: 'Hyväksytty',
    active: 'Aktiivinen',
    archived: 'Arkistoitu',
    expired: 'Vanhentunut',
    voided: 'Mitätöity',
    completed: 'Valmis',
    denied: 'Hylätty',
    draft: 'Luonnos',
    new: 'Luonnos',
    semiSigned: 'Osittain allekirjoitettu',
    sent: 'Lähetetty',
    signed: 'Allekirjoitettu'
  },
  summaryBottom: {
    chooseFrom: 'Valitse valittavissa olevista vaihtoehdoista',
    forDetails: 'tiedot',
    noOfOptions: '{{count}}',
    option: 'vaihtoehto',
    option_plural: 'vaihtoehdot',
    reverseVat: 'ALV {{- amount}}. Sovelletaan käänteistä verovelvollisuutta'
  },
  theDocument: 'asiakirja',
  theSender: 'lähettäjä',
  times: 'kerta',
  times_plural: 'kertaa',
  total: 'yhteensä',
  tryAgain: 'Yritä uudelleen',
  unitType: {
    day: 'päivä',
    hour: 'h',
    kg: 'kg',
    km: 'km',
    kWh: 'kWh',
    litre: 'litra',
    m: 'm',
    m2: 'm²',
    m3: 'm³',
    mile: 'maili',
    minute: 'min',
    month: 'kuukausi',
    second: 's',
    ton: 'tonni',
    unit: 'st',
    unitType: 'yksikkötyyppi',
    unitType_plural: 'yksikkötyypit',
    week: 'viikko',
    week_plural: 'viikkoa',
    year: 'vuosi'
  },
  vat: {
    exVat: 'poislukien {{thing}}',
    incVat: 'sis. {{thing}}',
    rate: 'ALV-prosentti',
    reverseVat: 'Käänteinen verovelvollisuus',
    reverseVatApplies: 'Sovelletaan käänteistä verovelvollisuutta',
    sharedTitle: 'alv',
    tax: 'alv',
    vat: 'alv',
    vatIdentificationNumberShort: 'ALV-tunnus'
  }
}
