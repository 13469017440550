export function moveArrayElement(
  arr: unknown[],
  from: number,
  to: number
): unknown[] {
  const results = arr.slice()
  const cutOut = results.splice(from, 1)[0] // cut the element at index 'from'
  results.splice(to, 0, cutOut) // insert it at index 'to'
  return results
}
