import features from '@cling/services/features'

export default {
  install(app, { store } = {}) {
    features.init({ store })

    app.config.globalProperties.$features = features
    app.config.globalProperties.$feature = features.checkFeature.bind(features)
  }
}
