import Animation from '@cling/components/ui/Animation'
import CButton from '@cling/components/ui/Button'
import CIcon from '@cling/components/ui/Icon'
import CInput from '@cling/components/ui/Input'
import CPhoneFormat from '@cling/components/ui/PhoneFormat'
import i18n from '@cling/language'
import pinia from '@cling/stores'

import { createApp } from 'vue'

import App from '@/App.vue'
import contentPlaceholdersPlugin from '@/components/contentPlaceholder'
import { fabState } from '@/components/HelpFab'
import ThinLink from '@/components/ThinLink.vue'
import extensions from '@/extensions'
import { createRouter } from '@/router'
import appIntegrations from '@/services/integrations'
import store from '@/store/index'

import '@/assets/styles/normalize.scss'
import '@/assets/styles/oldBase.scss'
import '@/assets/styles/theme/index.sass'
import '@/assets/styles/tailwind.css'

import '@cling/styles/animations.scss'
import '@cling/styles/base.css' // Needs to be imported after tailwind
import '@cling/styles/modal.scss'
import '@cling/styles/tooltip.scss'

// Add custom String util
String.prototype.capitalize = function (this: string): string {
  return this.charAt(0).toUpperCase() + this.slice(1)
}
const app = createApp(App)

const router = createRouter(store)

app.component(CIcon.name!, CIcon)
app.component(CButton.name!, CButton)
app.component(CInput.name!, CInput)
app.component(CPhoneFormat.name, CPhoneFormat)
app.component('ThinLink', ThinLink)
app.component('Animation', Animation)

app.config.compilerOptions.isCustomElement = tag =>
  tag.startsWith('zapier-app-directory')

app.use(router)
app.use(store)
app.use(pinia)
app.use(i18n)
app.use(extensions, { store })
app.use(appIntegrations, { router, store })
app.use(contentPlaceholdersPlugin)

app.config.globalProperties.$router = router
app.config.globalProperties.$fab = fabState

app.mount('#app')

window.App = app

export default app
