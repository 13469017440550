import ContentPlaceholders from './components/ContentPlaceholder.vue'
import ContentPlaceholdersHeading from './components/ContentPlaceholdersHeading.vue'
import ContentPlaceholdersImg from './components/ContentPlaceholdersImg.vue'
import ContentPlaceholdersText from './components/ContentPlaceholdersText.vue'

export function install(app) {
  app.component('ContentPlaceholders', ContentPlaceholders)
  app.component('ContentPlaceholdersHeading', ContentPlaceholdersHeading)
  app.component('ContentPlaceholdersImg', ContentPlaceholdersImg)
  app.component('ContentPlaceholdersText', ContentPlaceholdersText)
}

export {
  ContentPlaceholders,
  ContentPlaceholdersHeading,
  ContentPlaceholdersImg,
  ContentPlaceholdersText
}

const plugin = {
  install
}

export default plugin
