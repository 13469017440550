import { defineStore } from 'pinia'
import type { RouteLocationNormalized } from 'vue-router'

export type Modal = RouteLocationNormalized

export const useApplicationModalsStore = defineStore('applicationModals', {
  state: () => ({
    modals: [] as Modal[],
    canCloseModal: true
  }),
  actions: {
    setModals(modals: Modal[]) {
      this.modals = modals
    },
    pushModal(modal: Modal) {
      this.setModals([...this.modals, modal])
    },
    popModal() {
      this.setModals(this.modals.slice(0, -1))
    },
    setCanCloseModal(value: boolean) {
      this.canCloseModal = value
    }
  }
})

export default useApplicationModalsStore
