import permissions from '@cling/services/permissions'

export default {
  install(app, { store } = {}) {
    permissions.init({ store })

    app.config.globalProperties.$permissions = permissions
    app.config.globalProperties.$can =
      permissions.checkPermission.bind(permissions)
  }
}
