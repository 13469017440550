<script>
import { h as createElement } from 'vue'

import icons from './icons'
import Icon from './main'

export default {
  name: 'CIcon',
  props: {
    animation: {
      type: String,
      default: undefined
    },
    animationSpeed: {
      type: String,
      default: undefined
    },
    fill: {
      type: String,
      default: 'none'
    },
    size: {
      type: [Number, String],
      default: 22
    },
    stroke: {
      type: String,
      default: 'currentColor'
    },
    strokeLinecap: {
      type: String,
      default: 'round'
    },
    strokeLinejoin: {
      type: String,
      default: 'round'
    },
    strokeWidth: {
      type: [Number, String],
      default: 2
    },
    tag: {
      type: String,
      default: 'i'
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        if (!icons[type]) {
          throw new Error(`"${type}" is not an available icon type.`)
        }
        return true
      }
    }
  },
  render() {
    const { animation, animationSpeed, size, type } = this
    const icon = new Icon(type, icons[type])
    return createElement(
      this.tag,
      {
        'data-name': type,
        'data-tags': icon ? icon.tags : '',
        'data-type': type,
        class: {
          feather: true,
          [`feather--${type}`]: type,
          [`feather--${animation}`]: animation,
          [`feather--${animationSpeed}`]: animationSpeed,
          ...this.$attrs.class
        },
        style: this.$attrs.style
      },
      icon
        ? createElement('svg', {
            ...icon.attrs,
            fill: this.fill || 'currentColor',
            height: size,
            stroke: this.stroke || 'currentColor',
            'stroke-linecap': this.strokeLinecap,
            'stroke-linejoin': this.strokeLinejoin,
            'stroke-width': this.strokeWidth,
            width: size,
            style: size ? { width: `${size}px`, height: `${size}px` } : {},
            class: [icon.attrs?.class || '', 'feather__content'],
            innerHTML: icon.contents
          })
        : null
    )
  }
}
</script>

<style lang="scss" scoped>
@keyframes feather--spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.feather {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  &--spin {
    animation: feather--spin 2s linear infinite;
  }
  &--pulse {
    animation: feather--spin 2s infinite steps(8);
  }
  &--slow {
    animation-duration: 3s;
  }
  &--fast {
    animation-duration: 1s;
  }
  &__content {
    display: block;
    height: inherit;
    width: inherit;
  }
}
</style>
