import config from '@cling/config'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  yes: 'ja',
  no: 'nej',
  thanks: 'tack',
  times: 'gång',
  times_plural: 'gånger',
  second: 'sekund',
  second_plural: 'sekunder',
  minute: 'minut',
  minute_plural: 'minuter',
  hour: 'timme',
  hour_plural: 'timmar',
  day: 'dag',
  day_plural: 'dagar',
  week: 'vecka',
  week_plural: 'veckor',
  weeks: 'vecka', // obs, still needed for timeUnits
  weeks_plural: 'veckor', // obs, still needed for timeUnits
  month: 'månad',
  month_plural: 'månader',
  months: 'månad', // obs, still needed for timeUnits
  months_plural: 'månader', // obs, still needed for timeUnits
  quarter: 'kvartal',
  quarter_plural: 'kvartal',
  year: 'år',
  year_plural: 'år',
  years: 'år', // obs, still needed for timeUnits
  years_plural: 'år', // obs, still needed for timeUnits
  date: 'datum',
  date_plural: 'datum',
  time: 'tid',
  calendar: 'kalender',
  calendar_plural: 'kalendrar',
  weekend: 'helg',
  weekend_plural: 'helger',
  today: 'idag',
  project: 'projekt',
  project_plural: 'projekt',
  projectNo: 'projekt-nr',
  projectNumber: 'projektnummer',
  projectName: 'projektnamn',
  client: 'kund',
  client_plural: 'kunder',
  member: 'medlem',
  member_plural: 'medlemmar',
  user: 'användare',
  user_plural: 'användare',
  article: 'artikel',
  article_plural: 'artiklar',
  artNo: 'art-nr',
  articleNo: 'artikel-nr',
  articleNumber: 'artikelnummer',
  package: 'paket',
  package_plural: 'paket',
  group: 'group',
  group_plural: 'grupper',
  unit: 'enhet',
  unit_plural: 'enheter',
  task: 'task',
  task_plural: 'tasks',
  template: 'mall',
  template_plural: 'mallar',
  snippet: 'textmall',
  snippet_plural: 'textmallar',
  invoice: 'faktura',
  invoice_plural: 'fakturor',
  invoiceDo: 'fakturera',
  invoiced: 'fakturerat',
  card: 'kort',
  card_plural: 'kort',
  attest: 'attestera',
  attested: 'attesterad',
  document: 'Dokument',
  document_plural: 'dokument',
  theDocument: 'dokumentet',
  documentName: 'Dokumentnamn',
  offer: 'offert',
  proposal: 'offert',
  proposal_plural: 'offerter',
  sampleProposal: 'testoffert',
  contract: 'avtal',
  contract_plural: 'avtal',
  ata: 'tilläggsavtal', // may be updated dynamically
  addonDocument: 'Tilläggsavtal',
  ataConstruction: 'ÄTA',
  offerThe: 'Offerten',
  contractThe: 'Avtalet',
  ataThe: 'Tilläggsavtalet',
  ataConstructionThe: 'ÄTA:n',
  shorten: 'Förkortas',
  extend: 'Förlängs',
  sort: 'sortering',
  exVat: 'ex. {{-thing}}',
  incVat: 'inkl. {{-thing}}',
  accept: 'acceptera',
  approve: 'Godkänn',
  approved: 'godkänt',
  deny: 'Neka',
  denied: 'nekat',
  sign: 'Signera',
  signed: 'signerade',
  pay: 'Betala',
  void: 'Återkalla',
  discount: 'rabatt',
  print: 'Skriv ut{{thing,inlineSpace}}',
  info: 'info',
  free: 'Gratis',
  premium: 'Premium',
  yearly: 'årsvis',
  monthly: 'månadsvis',
  approx: 'ca',
  approximately: 'Ungefär',
  replyBy: 'Svara senast',
  respondWithin: 'Svara inom',
  belongsTo: 'tillhör',
  name: 'namn',
  nameMissing: 'Saknar namn',
  untitled: 'Titel saknas',
  firstName: 'förnamn',
  lastName: 'efternamn',
  companyName: 'företagsnamn',
  company: 'företag',
  theCompany: 'företaget',
  individual: 'privatperson',
  sender: 'utställare',
  theSender: 'utställaren',
  contractor: 'utförare',
  receiver: 'beställare',
  theReceiver: 'beställaren',
  theContractor: 'utföraren',
  person: 'person',
  person_plural: 'personer',
  contact: 'kontakt',
  contact_plural: 'kontaker',
  recipient: 'mottagare',
  recipient_plural: 'mottagare',
  participant: 'deltagare',
  participant_plural: 'deltagare',
  signature: 'signatur',
  signature_plural: 'signaturer',
  signingParticipant: 'Signerande part',
  signingParticipant_plural: 'Signerande parter',
  email: 'e-post',
  email_plural: 'e-poster',
  sms: 'SMS',
  phone: 'telefon',
  phone_plural: 'telefoner',
  cellphone: 'mobil',
  cellphone_plural: 'mobiler',
  address: 'adress',
  address_plural: 'adresser',
  addressWork: 'arbetsadress',
  image: 'bild',
  image_plural: 'bilder',
  file: 'fil',
  file_plural: 'filer',
  photo: 'foto',
  photo_plural: 'foton',
  video: 'video',
  video_plural: 'videor',
  link: 'länk',
  background: 'bakgrund',
  backgroundColor: 'bakgrundsfärg',
  logo: 'logotyp',
  size: 'storlek',
  album: 'album',
  album_plural: 'album',
  presentation: 'presentation',
  presentation_plural: 'presentationer',
  theme: 'tema',
  theme_plural: 'teman',
  appearance: 'utseende',
  appearance_plural: 'utseenden',
  customize: 'Redigera',
  language: 'språk',
  language_plural: 'språk',
  password: 'lösenord',
  password_plural: 'lösenord',
  text: 'text',
  text_plural: 'texter',
  field: 'fält',
  field_plural: 'fält',
  list: 'lista',
  list_plural: 'listor',
  textAlign: 'textjustering',
  textColor: 'textfärg',
  city: 'stad',
  city_plural: 'städer',
  position: 'position',
  position_plural: 'positioner',
  phoneNumber: 'telefonnummer',
  cellphoneNumber: 'mobilnummer',
  zip: 'postnummer',
  zipCode: 'postnummer',
  orgNo: 'organisationsnummer',
  orgNoShort: 'org-nr',
  socialNo: 'personnummer',
  socialNoShort: 'person-nr',
  aboutUs: 'Om oss',
  new: 'Ny{{thing,inlineSpace}}',
  new_tWord: 'Nytt{{thing,inlineSpace}}',
  visit: 'Besök{{thing,inlineSpace}}',
  done: 'klar',
  finished: 'klara',
  verify: 'verifiera{{thing,inlineSpace}}',
  verified: 'verifierad',
  plan: 'Planera',
  planned: 'planerade',
  save: 'Spara{{thing,inlineSpace}}',
  saveAs: 'Spara som{{thing,inlineSpace}}',
  saving: 'sparar',
  savedThing: '{{thing,capitalize}} sparad',
  goTo: 'Gå till',
  previous: 'föregående',
  next: 'nästa',
  continue: 'Fortsätt',
  cancel: 'Avbryt',
  preview: 'Förhandsgranska{{thing,inlineSpace}}',
  view: 'Visa{{thing,inlineSpace}}',
  viewed: 'visad',
  viewedXTimes: 'Visad {{n}} gånger',
  total: 'total',
  edit: 'Redigera{{thing,inlineSpace}}',
  enterThing: 'Ange{{thing,inlineSpace}}',
  change: 'Ändra{{thing,inlineSpace}}',
  move: 'Flytta{{thing,inlineSpace}}',
  remove: 'Ta bort{{thing,inlineSpace}}',
  removedThing: '{{thing,capitalize}} togs bort',
  up: 'upp',
  down: 'ned',
  left: 'vänster',
  right: 'höger',
  align: 'Justera{{thing,inlineSpace}}',
  center: 'centrera{{thing,inlineSpace}}',
  work: 'arbete',
  use: 'Använd{{thing,inlineSpace}}',
  used: 'använd',
  using: 'används',
  buy: 'Köp{{thing,inlineSpace}}',
  purchase: 'Köp{{thing,inlineSpace}}',
  purchased: 'Köpt{{thing,inlineSpace}}',
  handle: 'Hantera',
  adjust: 'Justera',
  missing: 'Saknar{{thing,inlineSpace}}',
  start: 'Start',
  stop: 'Stopp',
  reset: 'Återställ',
  end: 'slut',
  overview: 'översikt',
  signup: 'Öppna konto',
  open: 'Öppna',
  openNewWindow: 'Öppna i nytt fönster',
  openNewTab: 'Öppna i ny flik',
  close: 'Stäng',
  goBack: 'Tillbaka',
  undo: 'Ångra',
  share: 'Dela{{thing,inlineSpace}}',
  search: 'Sök',
  result: 'resultat',
  result_plural: 'resultat',
  noResults: 'Inga resultat',
  chat: 'chatt',
  optional: 'frivillig',
  select: 'Välj{{thing,inlineSpace}}',
  selected: 'vald',
  add: 'Lägg till{{thing,inlineSpace}}',
  insert: 'Infoga{{thing,inlineSpace}}',
  create: 'Skapa{{thing,inlineSpace}}',
  created: 'Skapad',
  createdThing: '{{thing,capitalize}} skapad',
  see: 'se',
  show: 'Visa{{thing,inlineSpace}}',
  hide: 'Göm', // ? Dölj
  empty: 'Tom{{thing,inlineSpace}}',
  more: 'mer',
  less: 'mindre',
  and: 'och',
  or: 'eller',
  with: 'med',
  to: 'till',
  from: 'från',
  via: 'via',
  again: 'igen',
  other: 'annat',
  none: 'ingen',
  authenticate: 'Autentisera',
  login: 'Logga in',
  logout: 'Logga ut',
  loggingOut: 'Loggar ut',
  browse: 'bläddra',
  download: 'Ladda ned{{thing,inlineSpace}}',
  downloading: 'laddar ner',
  upload: 'Ladda upp{{thing,inlineSpace}}',
  uploading: 'laddar upp',
  update: 'Uppdatera{{thing,inlineSpace}}',
  updated: 'uppdaterades',
  updatedThing: '{{thing,capitalize}} uppdaterades',
  invite: 'Bjud in{{thing,inlineSpace}}',
  invitation: 'inbjudan',
  send: 'Skicka{{thing,inlineSpace}}',
  sent: 'Skickat{{thing,inlineSpace}}',
  sentThing: '{{thing,capitalize}} skickad',
  sentThingTo: '{{thing,capitalize}} skickad till {{recipient}}',
  sentByName: 'Skickat av {{name}}',
  reviewSend: 'Granska & Skicka',
  delivery: 'utskick',
  scheduled: 'schemalagd',
  scheduledDelivery: 'Schemalagt utskick',
  reference: 'referens',
  reference_plural: 'referenser',
  certificate: 'certifikat',
  certificate_plural: 'certifikat',
  attachment: 'bilaga',
  attachment_plural: 'bilagor',
  transaction: 'transaktion',
  transaction_plural: 'transaktioner',
  receipt: 'kvitto',
  receipt_plural: 'kvitton',
  verification: 'verifikat',
  verification_plural: 'verifikat',
  integration: 'integration',
  integration_plural: 'integrationer',
  checklist: 'checklista',
  checklist_plural: 'checklistor',
  description: 'beskrivning',
  description_plural: 'beskrivningar',
  detail: 'detalj',
  detail_plural: 'detaljer',
  step: 'steg',
  step_plural: 'steg',
  guide: 'guide',
  guide_plural: 'guider',
  color: 'färg',
  color_plural: 'färger',
  gradient: 'gradient',
  gradient_plural: 'gradienter',
  logotype: 'logotyp',
  logotype_plural: 'logotyper',
  avatar: 'profilbild',
  avatar_plural: 'profilbilder',
  note: 'anteckning',
  note_plural: 'anteckningar',
  website: 'hemsida',
  website_plural: 'hemsidor',
  message: 'meddelande',
  message_plural: 'meddelanden',
  review: 'omdöme',
  review_plural: 'omdömen',
  greeting: 'hälsning',
  greeting_plural: 'hälsningar',
  setting: 'inställning',
  setting_plural: 'inställningar',
  settings: 'inställningar', // bonus intuitive key
  option: 'alternativ',
  option_plural: 'alternativ',
  value: 'värde',
  value_plural: 'värden',
  amount: 'antal',
  quantity: 'antal', // ? kvantitet
  nothing: 'ingenting',
  reminder: 'påminnelse',
  reminder_plural: 'påminnelser',
  schedule: 'Boka{{thing,inlineSpace}}',
  demo: 'demo',
  version: 'version',
  version_plural: 'versioner',
  filter: 'filter',
  filter_plural: 'filter',
  blur: 'suddighet',
  archive: 'arkiv',
  statistics: 'statistik',
  bookkeeping: 'bokföring',
  winProfile: 'WinProfile',
  pieceSuffix: ' st',
  disabled: 'avstängd',
  block: 'block',
  block_plural: 'block',
  copy: 'Kopiera{{thing,inlineSpace}}',
  copyMessage: 'Kopierat till urklipp',
  category: 'kategori',
  setup: 'ställ in',
  install: 'Installera',
  installing: 'Installerar',
  uninstall: 'Avinstallera',
  uninstalling: 'Avinstallerar',
  explore: 'Upptäck{{thing,inlineSpace}}',
  table: 'tabell',
  reload: 'Ladda om',
  rename: 'Döp om',
  library: 'Bibliotek',
  statistics_short: 'Statistik',
  browser: 'Webbläsare',
  device: 'Enhet',
  price: {
    priceType: 'pristyp',
    pricing: 'prisform',
    price: 'pris',
    price_plural: 'priser',
    proposal: 'Prisförslag',
    fixed: 'Fast pris',
    total: 'Totalpris',
    approx: 'Ungefärligt pris',
    openAcc: 'Löpande räkning',
    openAccPrice: 'Löpande räkning',
    openAccMaxPrice: 'Löpande räkning med maxpris',
    markup: 'Påslag',
    maxPrice: 'maxpris',
    summary: 'sammanställning',
    net: 'Netto'
  },
  currency: {
    currency: 'valuta',
    SEK: 'kr'
  },
  numberPrefix: {
    thousand: 't',
    million: 'm',
    billion: 'md'
  },
  deduction: {
    deduction: 'avdrag',
    type: '{{type}}-avdrag',
    manualType: 'Manuellt {{type}}-avdrag',
    after: 'after avdrag',
    afterType: 'efter {{type}}-avdrag',
    rot: 'ROT',
    rut: 'RUT',
    greenRot15: 'Grön ROT',
    greenRot20: 'Grön ROT',
    greenRot50: 'Grön ROT',
    // percentage context
    rot_percentage: 'ROT',
    rut_percentage: 'RUT',
    greenRot15_percentage: 'Grön ROT 15%',
    greenRot20_percentage: 'Grön ROT 20%',
    greenRot50_percentage: 'Grön ROT 50%'
  },
  series: {
    default: 'Dokument',
    document: 'Dokument',
    proposal: 'Offert',
    contract: 'Avtal',
    ata: 'Tilläggsavtal'
  },
  documentRole: {
    title: 'Dokumentroll',
    signee: {
      label: 'Undertecknare',
      description: 'Behöver signera'
    },
    recipient: {
      label: 'Mottagare',
      description: 'Får en kopia'
    },
    sender: {
      label: 'Avsändare',
      description: 'Avsändare'
    },
    approver: {
      label: 'Granskare',
      description: 'Behöver godkänna'
    }
  },
  signMethod: {
    title: 'Signeringsmetod',
    bankId: 'BankID',
    signature: 'Signatur',
    button: 'Knapp',
    login: 'Inlogg',
    inPerson: 'Fysisk underskrift'
  },
  status: {
    status: 'Status',
    // Multi status
    sent: 'Skickad',
    completed: 'Klar',
    // Invoice statuses
    draft: 'Utkast',
    attested: 'Attesterad',
    reminderSent: 'Påminnelse',
    salesRequest: 'Fakturaköp',
    sold: 'Såld',
    expired: 'Förfallen',
    voided: 'Återkallad',
    overdue: 'Förfallen',
    paying: 'Betalas ut',
    // Doc statuses
    new: 'Utkast',
    scheduled: 'Schemalagd',
    denied: 'Nekad',
    accepted: 'Signerad',
    signed: 'Signerad',
    semiSigned: 'Delsignerad',
    active: 'Pågående',
    archived: 'Arkiverad'
  },
  invoiceType: {
    invoice: 'Faktura',
    credit: 'Kreditfaktura',
    interest: 'Räntefaktura'
  },
  terms: {
    conditionTerm: 'villkor',
    general: 'Allmänna bestämmelser',
    termsAndConditions: 'allmänna villkor',
    theTermsAndConditions: 'allmänna villkoren',
    privacyPolicy: 'integritetsmeddelande'
  },
  invoiceMethod: 'Fakturametoden',
  cashMethod: 'Kontantmetoden',
  bankgiro: 'Bankgiro',
  plusgiro: 'Plusgiro',
  domesticBankAcc: 'Bankkonto',
  clearingNo: 'Clearing-nr',
  vat: {
    sharedTitle: 'moms',
    vat: 'moms',
    tax: 'moms',
    exVat: 'ex. {{thing}}',
    incVat: 'inkl. {{thing}}',
    reverseVat: 'Omvänd skattskyldighet',
    reverseVatApplies: 'Omvänd skattskyldighet gäller',
    vatIdentificationNumberShort: 'momsreg-nr',
    rate: 'momssats'
  },
  priceLabel: {
    unit: 'Pris',
    hour: 'Timpris',
    m: 'Meterpris',
    m2: 'Kvadratmeterpris',
    m3: 'Kubikmeterpris',
    kg: 'Pris per kg',
    litre: 'Literpris',
    day: 'Pris per dygn',
    km: 'Pris per km',
    mile: 'Pris per mil',
    month: 'Pris per mån',
    week: 'Pris per vecka',
    ton: 'Pris per ton',
    year: 'Pris per år',
    kWh: 'Pris per kWh',
    markup: 'Påslag'
  },
  unitType: {
    unitType: 'enhet',
    unitType_plural: 'enheter',
    second: 's',
    minute: 'min',
    unit: 'st',
    hour: 'h',
    m: 'm',
    m2: 'm²',
    m3: 'm³',
    kg: 'kg',
    litre: 'liter',
    day: 'dygn',
    km: 'km',
    mile: 'mil',
    month: 'mån',
    week: 'vecka',
    week_plural: 'veckor',
    ton: 'ton',
    year: 'år',
    kWh: 'kWh'
  },
  permission: {
    admin: 'Administratör',
    creator: 'Skapare',
    member: 'Medlem'
  },
  propertyDesignation: {
    propertyDesignation: 'Fastighetsbeteckning',
    propertyId: 'Beteckning',
    condominiumId: 'Lgh-nr',
    condominiumOrgNo: 'Brf org-nr'
  },
  notification: {
    notification: 'Notifikation',
    notification_plural: 'Notifikationer',
    invitedProject: 'Inbjuden till projekt',
    accepted: 'Dokument signerat',
    semiSigned: 'Dokument delsignerat',
    denied: 'Dokument nekat',
    mailDropped: 'E-post kunde inte skickas',
    viewed: 'Dokument visat'
  },
  event: {
    event: 'händelse',
    event_plural: 'händelser',
    mailDropped: 'Leveransfel e-post',
    smsDropped: 'Leveransfel SMS',
    viewed: 'Visad',
    requestContract: 'Kund begärde avtal',
    upgradeContract: 'Uppgradera till avtal',
    project: {
      created: 'Projekt skapat'
    },
    delivery: {
      sent: 'som e-post till',
      sentSms: 'som sms till'
    }
  },
  rejectReasons: {
    category: {
      unclear: 'Otydlig beskrivning',
      price: 'Pris',
      badges: 'Referenser & certifikat',
      misc: 'Annat'
    },
    unprofessional: 'Oseriöst intryck.',
    'unclear-included': 'Det framgick inte vad som ingår.',
    'unclear-task-descriptions': 'Arbetsmomenten var inte utförliga.',
    'unclear-language-complicated':
      'Språket på offerten var otydlig / komplicerad.',
    'work-expensive': 'Arbetspriset var för högt.',
    'material-expensive': 'Materialpriset var för högt.',
    'markup-expensive': 'För stort påslag.',
    'price-too-low': 'Priset känns för lågt för att vara sant.',
    'missing-ref': 'Saknar bra referenser.',
    'missing-cert': 'Saknar rätt certifikat / behörigheter.',
    'missing-f-skatt-insurance': 'Saknar F-skatt / ansvarsförsäkring.',
    'chose-other': 'Valt en annan utförare.',
    'bad-at-language': 'Personalen pratar inte ett språk jag förstår.',
    'expected-other': 'Inte vad jag förväntat mig.'
  },
  services: {
    construction: 'Bygg',
    design: 'Design',
    rut: 'RUT',
    photo: 'Foto',
    event: 'Event',
    tech: 'Tech'
  },
  region: {
    sweden: {
      enskildfirma: 'Enskild firma',
      handelsbolag: 'Handelsbolag',
      aktiebolag: 'Aktiebolag',
      economicAssociation: 'Ekonomisk förening'
    }
  },
  accountType: {
    free: 'Free',
    start: 'Start',
    base: 'Base',
    pro: 'Pro',
    premium: 'Pro',
    trial: 'Pro (prova på)'
  },
  documentMentions: {
    // Client
    clientName: 'Kundens namn',
    clientEmail: 'Kundens e-post',
    clientPhone: 'Kundens telefon',
    clientCompany: 'Kundens företag',
    clientAddress: 'Kundens adress',
    clientZip: 'Kundens postnummer',
    clientCity: 'Kundens stad',
    clientCountry: 'Kundens land',
    clientSocialNo: 'Kundens personnummer',
    clientOrgNo: 'Kundens organisationsnummer',
    // Sender
    senderName: 'Avsändarens namn',
    senderEmail: 'Avsändarens e-post',
    senderPhone: 'Avsändarens telefon',
    senderCompany: 'Avsändarens företag',
    senderAddress: 'Avsändarens adress',
    senderZip: 'Avsändarens postnummer',
    senderCity: 'Avsändarens stad',
    senderCountry: 'Avsändarens land',
    // Document
    documentName: 'Dokumentets namn',
    expiresAt: 'Dokumentets utgångsdatum',
    sentAt: 'Dokumentets sändningsdatum'
  },
  paymentInterval: {
    month: 'månadsvis',
    year: 'årsvis'
  },
  designLabels: {
    typography: 'Typografi',
    font: 'Typsnitt',
    title: 'Titel',
    h1: 'Rubrik 1',
    h2: 'Rubrik 2',
    h3: 'Rubrik 3',
    blockquote: 'Citat',
    paragraph: 'Text',
    numberedList: 'Numrerad lista',
    bulletList: 'Punktlista',
    line: 'Horisontell linje',
    style: 'Stil',
    size: 'Storlek',
    lineHeight: 'Radavstånd',
    letterSpacing: 'Knipning',
    marginBottom: 'Bottenmarginal',
    letterCase: 'Versalstil',
    letterCaseTypes: {
      initial: 'Som skrivet',
      capitalized: 'Rubrikversaler',
      uppercase: 'Versaler'
    },
    color: 'Färg',
    background: 'Bakgrund',
    contentWidth: 'Innehållsbredd',
    padding: 'Vaddering',
    border: 'Ram',
    borderWidth: 'Tjocklek',
    text: 'Text',
    section: 'Sektion',
    section_plural: 'Sektioner'
  }
}
