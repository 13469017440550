<template>
  <span
    :class="{
      'is-modal': isModal,
      'is-tab': isTab,
      'is-grid': !isTab,
      'tasks-open': showTasks
    }"
    :style="!isModal && { padding: 0, height: '100%' }"
    class="grid-container font-inter"
  >
    <Promised :promise="promise" :pending-delay="0">
      <template #combined="{ error }">
        <div
          v-if="error"
          class="c-flex-row c-align-middle c-align-center"
          style="height: 100%; font-size: 21px; font-weight: 700"
        >
          {{ $t('account.project.error') }}
        </div>
        <template v-else>
          <ListModalButtons
            v-if="isModal"
            :can-forward="!!storedList[currentIndexInProjectList + 1]"
            :can-backward="!!storedList[currentIndexInProjectList - 1]"
            @close="onClose"
            @go="onGo($event)"
          />
          <header class="top-nav">
            <div class="c-flex-row c-align-middle">
              <div
                v-if="$feature('task') && !isTab"
                class="tasks-button-wrapper"
              >
                <CButton
                  :icon-props="{
                    fill: showTasks ? undefined : 'currentColor',
                    size: showTasks ? 18 : 20,
                    strokeWidth: 3
                  }"
                  :icon="showTasks ? 'chevron-left' : 'square-filled-check'"
                  circle
                  type="secondary"
                  size="small"
                  @click="showTasks = !showTasks"
                />
                <div
                  v-if="!showTasks && tasksCount"
                  class="tasks-count-badge fixed"
                >
                  {{ tasksCount > 9 ? '9+' : tasksCount }}
                </div>
              </div>
              <div style="margin-right: 1rem; width: 100%">
                <CSkeleton
                  v-if="isLoading && !project"
                  width="10em"
                  class="project-name placeholder"
                />
                <CInput
                  v-else-if="project"
                  :model-value="project.name"
                  :disabled="!$can('projects', 'modify', projectId)"
                  :static="!$can('projects', 'modify', projectId)"
                  :autosize="{ minRows: 1, maxRows: 3 }"
                  :spellcheck="false"
                  :placeholder="$t('_common:projectName').capitalize()"
                  class="project-name"
                  @keydown.enter="$event.target.blur()"
                  @update:modelValue="onProjectNameInput"
                  @blur="updateProjectName"
                />
                <div
                  v-if="project"
                  style="
                    font-size: 13px;
                    font-weight: 400;
                    color: hsl(0, 0%, 40%);
                    line-height: 1;
                  "
                >
                  #{{ project.projectNumber }}
                </div>
              </div>
            </div>
            <ProjectMemberList
              v-if="project"
              :creator="project.creator || {}"
              :members="project.members"
              :project-id="projectId"
              :compact="mq === 'sm'"
            />
          </header>
          <CTabs
            v-if="isTab"
            v-model="currentTab"
            :size="mq === 'sm' ? 'normal' : 'medium'"
            position="center"
            class="blockx-width"
            style="padding-top: 1em; font-size: 16px"
          >
            <CTabItem
              :label="$t('overview').capitalize()"
              value="overview"
              style="padding-top: 2em"
            >
              <div
                class="blockx-width side-padding"
                style="padding-bottom: 5em"
              >
                <ProjectClients
                  :loading="isLoading"
                  :document="mainDocument"
                  class="blockx"
                />
                <ProjectCashSummary
                  :prices="prices"
                  :loading="isLoading"
                  class="blockx"
                />
                <ProjectStatusDropdown
                  :project="project"
                  :loading="isLoading"
                  :on-delete-project="onClose"
                />
                <div class="c-flex-row blockx" style="padding-top: 1em">
                  <ProjectCreateDocumentDropdown
                    :documents="mainDocument ? [mainDocument] : []"
                    :project-id="projectId"
                    :compact="true"
                    style="width: 50%"
                  />
                  <ProjectSecondaryDropdown
                    :project-id="projectId"
                    :documents="documents"
                    :compact="true"
                    style="width: 50%; padding-left: 1em"
                  />
                </div>
                <CalloutDeniedReasons
                  v-if="
                    rejectedDocument &&
                    $can('documents', 'modify', rejectedDocument.id)
                  "
                  :document="rejectedDocument"
                  class="blockx"
                />
                <CalloutScheduledFirstDeliveries
                  v-if="scheduledDocuments"
                  :documents="scheduledDocuments"
                  class="blockx"
                />
                <div style="margin-bottom: 3.5rem">
                  <div class="blockx-title" style="margin-bottom: 1rem">
                    {{ $t('document_plural').capitalize() }}
                  </div>
                  <DocumentInvoiceList
                    :loading="isLoading"
                    :documents="documents"
                  />
                </div>
                <AutoSaveNotes
                  :key="projectId"
                  :value="get(project, 'note.text', '')"
                  :post="postNote"
                  :external-updated-at="get(project, 'note.updatedAt', null)"
                  :disabled="!project || undefined"
                  :loading="isLoading && !get(project, 'note.text')"
                  class="notes-selector"
                >
                  <template #default="{ updatedAgo }">
                    <div class="blockx-title">
                      {{ $t('note_plural').capitalize() }}
                      <div class="blockx-title-hint">{{ updatedAgo }}</div>
                    </div>
                  </template>
                </AutoSaveNotes>
                <ProjectFileList
                  :files="files"
                  :project-id="projectId"
                  style="margin-top: 1.5rem"
                  class="blockx"
                />
              </div>
            </CTabItem>
            <CTabItem
              :label="$t('event.event_plural').capitalize()"
              value="events"
              style="padding-top: 2em"
            >
              <ProjectEvents
                :project="project"
                :loading="isLoading"
                class="side-padding"
              />
            </CTabItem>
            <CTabItem
              v-if="$feature('task')"
              :label="$t('task_plural').capitalize()"
              value="tasks"
            >
              <template #header>
                {{ $t('task_plural').capitalize() }}
                <span
                  v-if="!showTasks && tasksCount"
                  class="tasks-count-badge tab"
                >
                  {{ tasksCount > 9 ? '9+' : tasksCount }}
                </span>
              </template>
              <ProjectTaskList v-bind="taskListProps" />
            </CTabItem>
          </CTabs>
          <template v-else>
            <aside v-if="showTasks && $feature('task')" class="sidebar-left">
              <ProjectTaskList v-bind="taskListProps" />
            </aside>
            <main class="column-main">
              <Simplebar
                style="padding-top: 2rem"
                class="column-main-container scroller"
              >
                <div class="blockx-width side-padding" style="padding-top: 1em">
                  <ProjectCashSummary
                    :prices="prices"
                    :loading="isLoading"
                    class="blockx"
                  />
                  <ProjectClients
                    :loading="isLoading"
                    :document="mainDocument"
                    class="blockx"
                  />
                  <CalloutDeniedReasons
                    v-if="
                      rejectedDocument &&
                      $can('documents', 'modify', rejectedDocument.id)
                    "
                    :document="rejectedDocument"
                    class="blockx"
                  />
                  <CalloutScheduledFirstDeliveries
                    v-if="scheduledDocuments"
                    :documents="scheduledDocuments"
                    class="blockx"
                  />
                  <div class="blockx" style="margin-bottom: 3.5rem">
                    <div class="blockx-title" style="margin-bottom: 1rem">
                      {{ $t('document_plural').capitalize() }}
                    </div>
                    <DocumentInvoiceList
                      :loading="isLoading"
                      :documents="documents"
                    />
                  </div>
                  <div class="blockx">
                    <AutoSaveNotes
                      :key="projectId"
                      :value="get(project, 'note.text', '')"
                      :post="postNote"
                      :external-updated-at="
                        get(project, 'note.updatedAt', null)
                      "
                      :disabled="!project || undefined"
                      :loading="isLoading && !get(project, 'note.text')"
                      class="notes-selector"
                    >
                      <template #default="{ updatedAgo }">
                        <div class="blockx-title">
                          {{ $t('note_plural').capitalize() }}
                          <div class="blockx-title-hint">{{ updatedAgo }}</div>
                        </div>
                      </template>
                    </AutoSaveNotes>
                    <ProjectFileList
                      :files="files"
                      :project-id="projectId"
                      style="margin-top: 1.5rem"
                    />
                  </div>
                </div>
              </Simplebar>
            </main>
            <aside class="sidebar-right">
              <Simplebar class="scroller" style="padding: 2em 2em">
                <ProjectStatusDropdown
                  :project="project"
                  :loading="isLoading"
                  :on-delete-project="onClose"
                  style="margin-bottom: 2em"
                />
                <ProjectCreateDocumentDropdown
                  :documents="mainDocument ? [mainDocument] : []"
                  :project-id="projectId"
                  style="margin-bottom: 10px"
                />
                <ProjectSecondaryDropdown
                  :project-id="projectId"
                  :documents="documents"
                />
                <div
                  style="
                    font-size: 16px;
                    margin-bottom: 1rem;
                    margin-top: 2em;
                    padding-top: 1em;
                    border-top: 1px solid hsl(0, 0%, 87%);
                  "
                >
                  {{ $t('event.event_plural').capitalize() }}
                </div>
                <ProjectEvents :project="project" :loading="isLoading" />
              </Simplebar>
            </aside>
          </template>
        </template>
      </template>
    </Promised>
  </span>
</template>

<script>
import { postProjectNote, putProjectNote } from '@cling/api'
import ListModalButtons from '@cling/components/ListModalButtons.vue'
import CSkeleton from '@cling/components/ui/Skeleton'
import CTabItem from '@cling/components/ui/TabItem'
import CTabs from '@cling/components/ui/Tabs'
import windowSize from '@cling/mixins/windowSize'
import useTaskStore from '@cling/stores/tasks'

import debounce from 'lodash/debounce'
import get from 'lodash/get'
import Simplebar from 'simplebar-vue'
import 'simplebar/dist/simplebar.min.css'

import { defineAsyncComponent } from 'vue'
import { Promised } from 'vue-promised'
import { mapActions, mapGetters } from 'vuex'

import ProjectCashSummary from './components/ProjectCashSummary.vue'
import ProjectClients from './components/ProjectClients.vue'
import ProjectCreateDocumentDropdown from './components/ProjectCreateDocumentDropdown.vue'
import ProjectEvents from './components/ProjectEvents.vue'
import ProjectFileList from './components/ProjectFileList.vue'
import ProjectMemberList from './components/ProjectMemberList.vue'
import ProjectSecondaryDropdown from './components/ProjectSecondaryDropdown.vue'
import ProjectStatusDropdown from './components/ProjectStatusDropdown.vue'
import ProjectTaskList from './components/ProjectTaskList.vue'

import AutoSaveNotes from '@/components/account/AutoSaveNotes.vue'
import DocumentInvoiceList from '@/components/account/document/DocumentInvoiceList.vue'
import { global } from '@/store/action-types'

export default {
  i18nOptions: {
    namespaces: 'views'
  },
  name: 'Project',
  components: {
    Simplebar,
    Promised,
    ProjectStatusDropdown,
    ProjectCreateDocumentDropdown,
    ProjectCashSummary,
    ProjectFileList,
    ProjectMemberList,
    DocumentInvoiceList,
    ProjectEvents,
    ProjectClients,
    AutoSaveNotes,
    ListModalButtons,
    ProjectTaskList,
    ProjectSecondaryDropdown,
    CSkeleton,
    CTabItem,
    CTabs,
    CalloutDeniedReasons: defineAsyncComponent(
      () => import('@/components/account/document/CalloutDeniedReasons.vue')
    ),
    CalloutScheduledFirstDeliveries: defineAsyncComponent(
      () => import('./components/CalloutScheduledFirstDeliveries.vue')
    )
  },
  emits: ['id-change', 'close'],
  mixins: [windowSize],
  props: {
    id: {
      // Is string from route param
      // Is number from prop
      type: [Number, String],
      default: null
    },
    isModal: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const taskStore = useTaskStore()
    return { taskStore }
  },
  data() {
    return {
      promise: null,
      currentTab: 'overview',
      storedList: [],
      get,
      showTasks: false,
      newProjectName: ''
    }
  },
  computed: {
    ...mapGetters({
      projectById: 'projects/byId',
      isLoadingProject: 'projects/isFetching',
      isLoadingDocuments: 'documents2/isFetching',
      getCompanySetting: 'settings/getCompanySetting'
    }),
    isLoading() {
      return this.isLoadingProject || this.isLoadingDocuments
    },
    projectId() {
      return parseInt(this.id, 10)
    },
    currentIndexInProjectList() {
      return this.storedList.indexOf(this.projectId)
    },
    isTab() {
      return ['sm', 'md', 'lg'].includes(this.mq)
    },
    project() {
      return this.projectById(this.projectId)
    },
    files() {
      return get(this, 'project.files', [])
    },
    prices() {
      return get(this, 'project.prices')
    },
    documents() {
      return get(this, 'project.documents', [])
    },
    mainDocument() {
      return get(this, 'project.mainDocument', null)
    },
    addonTemplateId() {
      return this.getCompanySetting('addonTemplateId')
    },
    rejectedDocument() {
      return this.documents.find(
        ({ status, rejectReasons }) =>
          status === 'denied' && (rejectReasons || []).length
      )
    },
    scheduledDocuments() {
      return this.documents.reduce(
        (res, curr) =>
          get(curr, 'scheduledFirstDelivery.status', null) === 'active'
            ? [...(res || []), curr]
            : res,
        null
      )
    },
    tasksCount() {
      const list = this.taskStore.getTasksByProjectId(this.projectId)
      return list.filter(task => !task.isDone).length
    },
    taskListProps() {
      return {
        projectId: this.projectId,
        class: { fadeBounceInLeftSmall: this.showTasks },
        style: {
          animationDuration: '250ms',
          animationTimingFunction: 'ease-out'
        }
      }
    }
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        this.storedList = this.list ? this.list : []
        if (val) {
          this.fetchData(val)
          this.setAsRead({ id: val })
        }
      }
    }
  },
  mounted() {
    window.addEventListener('popstate', this.onClose)
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.onClose)
  },
  methods: {
    ...mapActions({
      loadProject: global.DO_LOAD_PROJECT,
      loadDocument: global.LOAD_DOCUMENT2,
      updateProject: global.UPDATE_PROJECT,
      setAsRead: global.SET_READ_FOR_PROJECT
    }),
    fetchData(id) {
      // Reset promise on new id
      this.promise = null
      this.promise = this.loadProject({ id })
        .then(() => this.loadDocuments())
        .catch(err => {
          throw err
        })
    },
    loadDocuments() {
      return Promise.all(
        get(this, 'project.documentIds', []).map(id =>
          this.loadDocument({ id })
        )
      )
    },
    async updateProjectName() {
      if (!this.projectId) return
      const name = this.newProjectName.slice(0, 255)
      if (!name) return
      await this.updateProject({ id: this.projectId, body: { name } })
    },
    onProjectNameInput: debounce(function a(name) {
      this.newProjectName = name
      this.updateProjectName()
    }, 5000),
    onClose() {
      this.$emit('close')
      if (!this.isModal) this.$router.push({ name: 'account' })
    },
    onGo(deltaDirection) {
      const newIndex = this.currentIndexInProjectList + deltaDirection
      if (!this.storedList[newIndex]) return
      this.$emit('id-change', this.storedList[newIndex])
    },
    async postNote(text = '') {
      const note = get(this, 'project.note')
      try {
        if (!note || !note.id) {
          await postProjectNote({ text, ProjectId: this.projectId })
          await this.loadProject({ id: this.projectId })
        } else
          await putProjectNote(note.id, {
            ...note,
            text,
            ProjectId: this.projectId
          })
      } catch (err) {
        this.$error(err, {
          object: 'projectNote',
          fallbackCode: 'projectNote.post/put',
          showMessage: false,
          actionPayload: { ...note, text, ProjectId: this.projectId }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

$blockWidth: 600px;

.grid-container {
  height: 100%;
  cursor: default;
  &.is-grid {
    display: grid;
    grid-template-columns: minmax(0, 300px) minmax(0, 1fr) minmax(0, 300px);
    grid-template-rows: 4em auto;
    grid-template-areas:
      'header header header'
      'main main sidebar-right';
    &.tasks-open {
      grid-template-areas:
        'header header header'
        'sidebar-left main sidebar-right';
    }
    @media (max-width: 1000px) {
      grid-template-columns: minmax(0, 1fr);
      grid-template-areas:
        'header'
        'main';
    }
  }
  &.is-modal {
    width: 1200px;
    max-width: 90%;
    height: 1200px;
    max-height: 95%;
    border-radius: 10px;
    background-color: white;
    position: relative;
  }
  &.is-modal.is-tab {
    width: $blockWidth;
  }
}
header.top-nav {
  grid-area: header;
  padding: 5px 2em 8px 1.5em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.sidebar-left {
  grid-area: sidebar-left;
  border-right: 1px solid #eee;
  .is-modal & {
    border-radius: 10px 0 0 10px;
  }
}
main,
aside {
  /* height: 100%; Do not set height. Bugs in Safari */
  overflow: hidden;
}
main.column-main {
  grid-area: main;
  & .column-main-container {
    padding: 1rem 0 5rem 0;
  }
}

.sidebar-right {
  grid-area: sidebar-right;
  border-left: 1px solid #eee;
  .is-modal & {
    border-radius: 0 0 10px 0;
  }
}

.scroller {
  overflow: auto;
  height: 100%;
}

.blockx-width {
  max-width: $blockWidth;
  margin: 0 auto;
}
.side-padding {
  padding-left: 2rem;
  padding-right: 2rem;
  .is-modal & {
    padding-left: 3em;
    padding-right: 3em;
  }
  .is-tab & {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.blockx {
  margin-bottom: 1.5rem;
  &-title {
    font-weight: 700;
    font-size: 16px;
    color: $black;
    margin-bottom: 0.5em;
  }
  &-title-hint {
    color: $gray600;
    font-size: 12px;
    font-weight: 400;
    float: right;
  }
}

.grid-container :deep(.simplebar-track.simplebar-vertical) {
  background-color: transparent;
  .simplebar-scrollbar::before {
    background-color: hsl(233, 15%, 64%);
  }
}

.tasks {
  &-button-wrapper {
    position: relative;
    margin-right: 1em;
  }
  &-count-badge {
    background-color: hsl(20, 100%, 52%);
    font-weight: 600;
    color: $white;
    min-width: 1.55em;
    line-height: 1.55;
    padding: 0 4px;
    border-radius: 2em;
    text-align: center;
    pointer-events: none;
    &.fixed {
      font-size: 12px;
      position: absolute;
      right: -0.5em;
      top: -0.3em;
    }
    &.tab {
      font-size: 13px;
      margin-left: 0.5em;
    }
  }
}

.project-name {
  & :deep(input),
  &.placeholder {
    font-size: 18px;
    font-weight: 500;
    color: $black;
    min-width: 0px;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    padding: 0;
    display: flex;
    height: 30px;
    max-width: 80%;
    &:focus {
      border: none;
      background-color: $gray150;
    }
    &:hover {
      background-color: $gray150;
    }
  }
}
</style>
