/* eslint-disable no-console */
import {
  LanguageDetector,
  capitalize,
  initOptions,
  l10nInstance,
  addCustomFormatting,
  withCount
} from '@cling/language/index'
import VueI18Next from '@cling/language/vueI18next'
import { flattenObject } from '@cling/utils'

import i18next from 'i18next'

import languages, { docLanguages as docLangs } from './languages'

export const docLanguages = docLangs

const documentInstance = i18next.createInstance()

const doesExist = value => {
  if (value) return true // Return true for objects
  return typeof value === 'string'
}

documentInstance
  .use(LanguageDetector)
  .use(capitalize)
  .use(withCount)
  .init({
    ...initOptions(documentInstance),
    resources: languages,
    supportedLngs: docLanguages,
    fallbackLng: process.env.NODE_ENV === 'development' ? undefined : 'en',
    ns: ['document'],
    defaultNS: 'document',
    fallbackNS: 'document'
  })
  .then(() => addCustomFormatting(documentInstance))

function validateMissingTranslations() {
  const masterKeys = flattenObject(languages.en)

  for (const lang of docLanguages) {
    const compareKeys = flattenObject(languages[lang])

    const result = []

    for (const key of Object.keys(compareKeys)) {
      if (!doesExist(masterKeys[key]))
        result.push(`Invalid translation key (does not exist in EN): ${key}`)
    }

    for (const key of Object.keys(masterKeys)) {
      if (!doesExist(compareKeys[key]))
        result.push(`Missing translation key: ${key}`)
    }

    if (result.length) {
      console.group(`Missing translations for language: ${lang}`)
      result.forEach(m => console.error(m))
      console.groupEnd(`Missing translations for language: ${lang}`)
    }
  }
}

if (process.env.NODE_ENV === 'development') {
  validateMissingTranslations()
}

const documentLang = new VueI18Next(documentInstance, l10nInstance)

export default documentLang
