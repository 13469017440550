<template>
  <component ref="el" :is="tag">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { useAnimate, type UseAnimateOptions } from '@vueuse/core'
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    tag?: string
    options?: UseAnimateOptions
  }>(),
  {
    tag: 'div'
  }
)

const defaultOptions: UseAnimateOptions = {
  duration: 150,
  easing: 'cubic-bezier(0.175, 0.885, 0.32, 1)',
  direction: 'normal',
  fill: 'forwards',
  iterations: 1,
  immediate: true
}

const el = ref<HTMLElement | null>(null)

const keyframes = [
  { height: '0px', width: '0px' },
  { height: 'auto', width: 'auto' }
]

useAnimate(el, keyframes, { ...defaultOptions, ...props.options })
</script>
