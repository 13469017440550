<template>
  <CDropdown
    v-bind="{
      position: 'is-bottom-left',
      ...$attrs,
      ...$props
    }"
    ref="dropdown"
    v-model="selectedValue"
    :size="$attrs.size"
    :disabled="$attrs.disabled"
    :menu-styling="{
      minWidth: '250px',
      maxWidth: '300px'
    }"
    :max-height="height"
    :scrollable="true"
    class="control"
  >
    <template #trigger>
      <slot :selected-family="selectedValue.family">
        <CButton
          :style="{ fontFamily: selectedValue.family }"
          class="is-left"
          style="width: 100%; text-align: left; min-width: 7em"
        >
          {{ selectedValue.family }}
        </CButton>
      </slot>
    </template>
    <template #header>
      <CInput
        v-model="visualQuery"
        :placeholder="$t('_common:search')"
        filled
        style="margin-bottom: 0.25em"
      />
    </template>
    <VirtualFontsList v-if="fontsList.length" :items="fontsList" />
    <div
      v-else
      style="
        height: 250px;
        width: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      :style="{ height }"
    >
      {{ $t('_common:noResults') }}
    </div>
  </CDropdown>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CInput from '@cling/components/ui/Input'

import debounce from 'lodash/debounce'

import FontDropdownItem from './FontDropdownItem.vue'
import { Fonts, getFormattedFontOptions } from './googleFonts'
import VirtualFontsList from './VirtualFontsList.vue'

export default {
  name: 'FontPickerDropdown',
  components: {
    CInput,
    CDropdown,
    VirtualFontsList
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    height: {
      type: String,
      default: '250px'
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      FontDropdownItem,
      visualQuery: '', // string visual to user
      activeQuery: '' // query used for filtering results, hidden behind debounce
    }
  },
  computed: {
    selectedValue: {
      get() {
        return {
          family: 'Inter',
          weight: '400',
          ...this.modelValue
        }
      },
      set(v) {
        const { family, variants = [] } = v || {}
        let { weight = '400' } = this.modelValue || {}

        const options = getFormattedFontOptions(variants)

        const weights = options.map(o => o.value)

        // Find option for current weight, eqeq for safeguard against unwanted string|number conversions
        if (weights.length && !weights.includes(`${weight}`)) {
          // force string compare
          weight = weights.reduce((p, c) =>
            Math.abs(c - weight) < Math.abs(p - weight) ? c : p
          )
        }
        // If weight variant doesn't exist for picked font family, find the closest

        this.$emit('update:modelValue', {
          family,
          weight,
          options
        })
      }
    },
    fontsList() {
      const list = Fonts.list || []
      if (this.activeQuery) {
        const reg = new RegExp(this.activeQuery, 'i')
        return list.filter(itm => reg.test(itm.family))
      }
      return list
    }
  },
  watch: {
    visualQuery: debounce(function func(v) {
      this.activeQuery = v
    }, 300)
  },
  created() {
    Fonts.load()
  }
}
</script>

<style lang="scss" scoped></style>
