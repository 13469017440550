<template>
  <div>
    <CDropdown
      v-if="showFab"
      position="is-top-left"
      class="font-inter"
      style="position: fixed; bottom: 1rem; right: 1rem; z-index: 1010"
    >
      <template #trigger>
        <div
          class="flex h-11 w-11 cursor-pointer items-center justify-center rounded-[50%] border-[1px] border-solid border-gray-300 bg-white shadow-lg hover:bg-gray-100 active:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="24"
            height="24"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 4a5 5 0 0 0-5 5a1 1 0 0 0 2 0c0-1.658 1.342-3 3-3s3 1.342 3 3c0 .816-.199 1.294-.438 1.629c-.262.365-.625.638-1.128.985l-.116.078c-.447.306-1.023.699-1.469 1.247c-.527.648-.849 1.467-.849 2.561v.5a1 1 0 1 0 2 0v-.5c0-.656.178-1.024.4-1.299c.257-.314.603-.552 1.114-.903l.053-.037c.496-.34 1.133-.786 1.62-1.468C16.7 11.081 17 10.183 17 9a5 5 0 0 0-5-5Zm0 17.25a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5Z"
            ></path>
          </svg>
          <div
            v-if="hasMessages"
            style="
              position: absolute;
              right: -2px;
              top: -2px;
              width: 14px;
              height: 14px;
              border-radius: 14px;
              background-color: hsl(0, 90%, 60%);
            "
          />
        </div>
      </template>
      <CDropdownItem icon-left="chat" @click="$supportChat.show">
        {{ $t('chat') }}
        <span
          v-if="hasMessages"
          style="
            background-color: hsl(0, 90%, 60%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin: 1px 0 0 6px;
          "
        />
      </CDropdownItem>
      <CDropdownItem icon-left="star" @click="$modal.show('feedback')">
        {{ $t('feedback') }}
      </CDropdownItem>
      <CDropdownItem separator />
      <div style="padding: 0.5em 1rem; font-size: 13px; color: hsl(0, 0%, 45%)">
        Version {{ appVersion }}
      </div>
    </CDropdown>
    <FeedbackModal v-if="chatEnabled" name="feedback" />
  </div>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import { viewContext } from '@cling/globalState'
import windowSize from '@cling/mixins/windowSize'

import { reactive } from 'vue'

import FeedbackModal from '@/components/FeedbackModal.vue'

export const fabState = reactive({
  show: true
})

export default {
  components: {
    FeedbackModal,
    // Make components available on the 404 page
    CDropdown,
    CDropdownItem
  },
  mixins: [windowSize],
  data() {
    return {
      hasInitiated: false,
      hasMessages: false,
      appVersion: __APP_VERSION__
    }
  },
  i18nOptions: {
    namespaces: 'helpFab',
    messages: {
      sv: {
        chat: 'Chatta med oss',
        feedback: 'Lämna feedback',
        contact: 'Kontakta oss'
      },
      en: {
        chat: 'Chat with us',
        feedback: 'Send feedback',
        contact: 'Contact us'
      }
    }
  },
  computed: {
    showFab() {
      if (viewContext.value === 'extension') return false
      if (this.mq === 'sm') return false
      return fabState.show
    },
    chatEnabled() {
      return this.$supportChat?.isEnabled
    }
  },
  watch: {
    chatEnabled: {
      immediate: true,
      handler(v) {
        if (v && !this.hasInitiated) this.init()
      }
    }
  },
  methods: {
    init() {
      this.hasInitiated = true

      // Post install callback
      const callback = () => {
        if (window.fcWidget) {
          window.fcWidget.on('unreadCount:notify', ({ count }) => {
            this.hasMessages = !!count
          })
        }
      }

      // Install
      this.$supportChat.install(callback)

      this.$supportChat.hideIcon(true)
    }
  }
}
</script>
