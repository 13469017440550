<template>
  <ManualPopover
    :placement="showPicker ? 'right' : isTouchDevice ? 'bottom' : 'top'"
    popper-class="color-menu-popover texteditor-tooltip-dropdown-inner is-color-menu"
    :distance="-4"
    :dispose-timeout="0"
    @apply-hide="closePicker"
  >
    <button
      :data-texteditor-tooltip="$t('_common:color').capitalize()"
      class="menu-button"
    >
      <svg
        :fill="editor.getAttributes('textStyle').color"
        viewBox="0 0 24 24"
        width="20"
        height="20"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z" />
      </svg>
    </button>
    <template #popper>
      <div class="flex bg-white text-[15px] text-white/70" @click.stop>
        <div v-if="showPicker">
          <ColorPicker
            ref="colorPicker"
            :model-value="internalColor"
            class="!p-0 !shadow-none"
            @update:modelValue="setColor($event)"
          />
        </div>
        <template v-else>
          <div
            v-for="(color, i) in [
              'auto',
              '#0a5cff',
              '#3022f1',
              '#915cee',
              '#f65a5a',
              '#ff7b22',
              '#fcc136',
              '#4caf50',
              '#5d5d5b',
              '#111111'
            ]"
            :key="i"
            :class="{
              'is-active':
                currentColor === color || (!currentColor && color === 'auto')
            }"
            :style="{
              backgroundColor: color === 'auto' ? 'hsl(0, 0%, 85%)' : color
            }"
            class="color-option-button"
            @mousedown.prevent
            @click.prevent.stop="setColor(color === 'auto' ? '' : color)"
            v-bind="{
              ...(color === 'auto'
                ? { 'data-texteditor-tooltip': `Auto ${$t('_common:color')}` }
                : {})
            }"
          >
            <CIcon
              v-if="color === 'auto'"
              type="star"
              size="14"
              fill="hsl(0, 0%, 30%)"
              stroke="hsl(0, 0%, 30%)"
            />
          </div>
          <div
            class="color-option-button"
            @mousedown.prevent
            @click.prevent.stop="openPicker"
          >
            <svg width="100%" height="100%" viewBox="0 0 100 100">
              <defs>
                <filter
                  id="blur"
                  color-interpolation-filters="linear"
                  x="-50%"
                  y="-50%"
                  width="200%"
                  height="200%"
                >
                  <feGaussianBlur in="SourceGraphic" stdDeviation="9" />
                </filter>
                <mask id="circle">
                  <circle cx="50" cy="50" r="50" fill="white" />
                </mask>
              </defs>
              <g mask="url(#circle)" filter="url(#blur)">
                <rect x="-10" width="110" height="110" fill="blue" />
                <rect x="50" width="60" height="110" fill="yellow" />
                <polygon points="50,50, 60,110, 40,110" fill="#0f8" />
                <polygon points="0,0, 100,0, 100,20, 50,50, 0,20" fill="red" />
                <polygon points="0,10, 50,50, 0,30" fill="#f0f" />
                <polygon points="100,10, 100,30, 50,50" fill="#f80" />
              </g>
            </svg>
          </div>
        </template>
      </div>
    </template>
  </ManualPopover>
</template>

<script>
import ColorPicker from '@cling/components/ui/ColorPicker'
import ManualPopover from '@cling/components/ui/ManualPopover'
import { isTouchDevice } from '@cling/components/ui/utils'
import { hslObjectToString } from '@cling/utils/color'

export default {
  name: 'TextEditorColorMenu',
  components: {
    ColorPicker,
    ManualPopover
  },
  emits: ['update:show-picker'],
  props: {
    editor: {
      type: Object,
      required: true
    },
    showPicker: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      internalColor: 'hsl(205, 90%, 45%)',
      isTouchDevice
    }
  },
  computed: {
    currentColor() {
      return this.editor.getAttributes('textStyle').color
    }
  },
  methods: {
    openPicker() {
      this.$emit('update:show-picker', true)
    },
    closePicker() {
      this.$emit('update:show-picker', false)
    },
    setColor(value) {
      const color =
        typeof value === 'object' ? hslObjectToString(value.hsl) : value
      this.internalColor = color
      const cmd = color ? 'setColor' : 'unsetColor'
      this.editor.chain().focus()[cmd](color).run()
    }
  }
}
</script>

<style lang="scss" scoped>
.color-option-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 3px;
  cursor: pointer;
  transition: 80ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    box-shadow:
      0 0 0 0px hsl(0, 0%, 100%),
      0 0 0 2px hsla(0, 0%, 20%, 0.2);
  }
  &.is-active {
    box-shadow:
      0 0 0 2px hsl(0, 0%, 100%),
      0 0 0 4px hsla(0, 0%, 20%, 0.4);
  }
}
</style>

<style lang="scss">
.color-menu-popover {
  & .v-popper__inner {
    background-color: white !important;
  }
}
</style>
