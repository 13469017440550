import _isTouchDevice from 'is-touch-device'
import { Comment, Fragment, Static, Text } from 'vue'

export const isTouchDevice = _isTouchDevice()

/**
 * +/- function to native math sign
 */
function signPoly(value) {
  if (value < 0) return -1
  return value > 0 ? 1 : 0
}
export const sign = Math.sign || signPoly

/**
 * Get value of an object property/path even if it's nested
 */
export function getValueByPath(obj, path) {
  const value = path.split('.').reduce((o, i) => (o ? o[i] : null), obj)
  return value
}

/**
 * Extension of indexOf method by equality function if specified
 */
export function indexOf(array, obj, fn) {
  if (!array) return -1

  if (!fn || typeof fn !== 'function') return array.indexOf(obj)

  for (let i = 0; i < array.length; i++) {
    if (fn(array[i], obj)) {
      return i
    }
  }

  return -1
}

/**
 * Merge function to replace Object.assign with deep merging possibility
 */
const isObject = item => typeof item === 'object' && !Array.isArray(item)
const mergeFn = (target, source, deep = false) => {
  if (deep || !Object.assign) {
    const isDeep = prop =>
      isObject(source[prop]) &&
      target !== null &&
      Object.prototype.hasOwnProperty.call(target, prop) &&
      isObject(target[prop])
    const replaced = Object.getOwnPropertyNames(source)
      .map(prop => ({
        [prop]: isDeep(prop)
          ? mergeFn(target[prop], source[prop], deep)
          : source[prop]
      }))
      .reduce((a, b) => ({ ...a, ...b }), {})

    return {
      ...target,
      ...replaced
    }
  }
  return Object.assign(target, source)
}
export const merge = mergeFn

/**
 * Mobile detection
 * https://www.abeautifulsite.net/detecting-mobile-devices-with-javascript
 */
export const isMobile = {
  Android() {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/Android/i)
    )
  },
  BlackBerry() {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/BlackBerry/i)
    )
  },
  iOS() {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    )
  },
  Opera() {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/Opera Mini/i)
    )
  },
  Windows() {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/IEMobile/i)
    )
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    )
  }
}

export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
    el.parentNode.removeChild(el)
  }
}

export function createAbsoluteElement(el) {
  const rootNode = el.getRootNode?.()
  const isShadowParent = rootNode?.toString() === '[object ShadowRoot]'
  const appendTo = isShadowParent ? rootNode : document.body

  const root = document.createElement('div')
  root.style.position = 'absolute'
  root.style.left = '0px'
  root.style.top = '0px'
  const wrapper = document.createElement('div')
  root.appendChild(wrapper)
  wrapper.appendChild(el)
  appendTo.appendChild(root)

  return root
}

/**
 * Escape regex characters
 * http://stackoverflow.com/a/6969486
 */
export function escapeRegExpChars(value) {
  if (!value) return value

  // eslint-disable-next-line
  return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
}

export function multiColumnSort(inputArray, sortingPriority) {
  // clone it to prevent the any watchers from triggering every sorting iteration
  const array = JSON.parse(JSON.stringify(inputArray))
  const fieldSorter = fields => (a, b) =>
    fields
      .map(o => {
        const { field, order, customSort } = o
        if (typeof customSort === 'function') {
          return customSort(a, b, order !== 'desc')
        } else {
          const aValue = getValueByPath(a, field)
          const bValue = getValueByPath(b, field)
          const ord = aValue > bValue ? 1 : aValue < bValue ? -1 : 0
          return order === 'desc' ? -ord : ord
        }
      })
      .reduce((p, n) => p || n, 0)

  return array.sort(fieldSorter(sortingPriority))
}

export function createNewEvent(eventName) {
  let event
  if (typeof Event === 'function') {
    event = new Event(eventName)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
  }
  return event
}

/**
 * Asserts a value is beetween min and max
 * @param val
 * @param min
 * @param max
 * @returns {number}
 */
export function bound(val, min, max) {
  return Math.max(min, Math.min(max, val))
}

export function isVueComponent(c) {
  return c && c.$ != null && c.$.vnode != null
}

export function isTag(vnode) {
  return vnode.type !== Comment && vnode.type !== Text && vnode.type !== Static
}

export function isFragment(vnode) {
  return vnode.type === Fragment
}

export function hasFlag(val, flag) {
  return (val & flag) === flag
}
