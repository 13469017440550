<template>
  <div class="b-tabs" :class="mainClasses">
    <nav class="tabs" :class="navClasses" @keydown="manageTablistKeydown">
      <slot name="start" />
      <ul
        :aria-orientation="vertical ? 'vertical' : 'horizontal'"
        role="tablist"
      >
        <li
          v-for="childItem in items"
          :key="childItem.uniqueValue"
          v-show="childItem.visible"
          :class="[
            childItem.headerClass,
            {
              'is-active': childItem.isActive,
              'is-disabled': childItem.disabled
            }
          ]"
          role="tab"
          :aria-controls="`${childItem.uniqueValue}-content`"
          :aria-selected="`${childItem.isActive}`"
        >
          <CSlotComponent
            :ref="`tabLink${childItem.index}`"
            v-if="childItem.$slots.header"
            :component="childItem"
            name="header"
            tag="a"
            :id="`${childItem.uniqueValue}-label`"
            :tabindex="childItem.isActive ? 0 : -1"
            @focus="currentFocus = childItem.index"
            @click="childClick(childItem)"
            @keydown="manageTabKeydown($event, childItem)"
          />
          <a
            :ref="`tabLink${childItem.index}`"
            v-else
            :id="`${childItem.uniqueValue}-label`"
            :tabindex="childItem.isActive ? 0 : -1"
            @focus="currentFocus = childItem.index"
            @click="childClick(childItem)"
            @keydown="manageTabKeydown($event, childItem)"
          >
            <CIcon v-if="childItem.icon" :type="childItem.icon" :size="size" />
            <span>{{ childItem.label }}</span>
          </a>
        </li>
      </ul>
      <slot name="end" />
    </nav>
    <section
      class="tab-content"
      :class="{ 'is-transitioning': isTransitioning }"
    >
      <slot />
    </section>
  </div>
</template>

<script>
import CIcon from '@cling/components/ui/Icon'
import CSlotComponent from '@cling/components/ui/utils/CSlotComponent'
import { bound } from '@cling/components/ui/utils/helpers'
import ProviderParentMixin, {
  Sorted
} from '@cling/components/ui/utils/ProviderParentMixin'

export default {
  name: 'BTabs',
  mixins: [ProviderParentMixin('tab', Sorted)],
  components: {
    CIcon,
    CSlotComponent
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: undefined
    },
    size: String,
    animated: {
      type: Boolean,
      default: true
    },
    animation: String,
    animateInitially: Boolean,
    vertical: {
      type: Boolean,
      default: false
    },
    position: String,
    destroyOnHide: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    type: {
      type: [String, Object],
      default: () => {
        return null
      }
    },
    multiline: Boolean
  },
  emits: ['update:modelValue'],
  data() {
    return {
      currentFocus: null,
      activeId: this.modelValue, // Internal state
      defaultSlots: [],
      contentHeight: 0,
      isTransitioning: false
    }
  },
  computed: {
    activeItem() {
      return this.activeId === undefined
        ? this.items[0]
        : this.activeId === null
          ? null
          : this.childItems.find(i => i.uniqueValue === this.activeId)
    },
    items() {
      return this.sortedItems
    },
    mainClasses() {
      return {
        'is-fullwidth': this.expanded,
        'is-vertical': this.vertical,
        'is-multiline': this.multiline,
        [`is-${this.position}`]: this.position && this.vertical
      }
    },
    navClasses() {
      return [
        this.type,
        this.size,
        {
          [`is-${this.position}`]: this.position && !this.vertical,
          'is-fullwidth': this.expanded,
          'is-toggle': this.type === 'is-toggle-rounded'
        }
      ]
    }
  },
  watch: {
    /**
     * When v-model is changed set the new active tab.
     */
    modelValue(value) {
      if (typeof value === 'number') {
        // Backward compatibility: converts the index value to an id
        value = bound(value, 0, this.items.length - 1)
        this.activeId = this.items[value].uniqueValue
      } else {
        this.activeId = value
      }
    },
    /**
     * Sync internal state with external state
     */
    activeId(val, oldValue) {
      const oldTab =
        oldValue !== undefined && oldValue !== null
          ? this.childItems.find(i => i.uniqueValue === oldValue)
          : null

      if (oldTab && this.activeItem) {
        oldTab.deactivate(this.activeItem.index)
        this.activeItem.activate(oldTab.index)
      }

      val = this.activeItem
        ? typeof this.modelValue === 'number'
          ? this.items.indexOf(this.activeItem)
          : this.activeItem.uniqueValue
        : undefined

      if (val !== this.modelValue) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  mounted() {
    if (typeof this.modelValue === 'number') {
      // Backward compatibility: converts the index value to an id
      const value = bound(this.modelValue, 0, this.items.length - 1)
      this.activeId = this.items[value].uniqueValue
    } else {
      this.activeId = this.modelValue
    }
  },
  methods: {
    /**
     * Child click listener, emit input event and change active child.
     */
    childClick(child) {
      this.activeId = child.uniqueValue
    },
    getNextItemIdx(fromIdx, skipDisabled = false) {
      let nextItemIdx = null
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        if (
          fromIdx < item.index &&
          item.visible &&
          (!skipDisabled || (skipDisabled && !item.disabled))
        ) {
          nextItemIdx = item.index
          break
        }
      }
      return nextItemIdx
    },
    getPrevItemIdx(fromIdx, skipDisabled = false) {
      let prevItemIdx = null
      for (let i = this.items.length - 1; i >= 0; i--) {
        const item = this.items[i]
        if (
          item.index < fromIdx &&
          item.visible &&
          (!skipDisabled || (skipDisabled && !item.disabled))
        ) {
          prevItemIdx = item.index
          break
        }
      }
      return prevItemIdx
    },
    giveFocusToTab(tab) {
      if (Array.isArray(tab)) {
        // Vue ≥ v3.0 < v3.2.25, refs in v-for are stored as a single object,
        // but ≥ v3.2.25, refs in v-for are stored in an array (same as Vue 2)
        tab = tab[0]
        if (tab == null) {
          return
        }
      }
      if (tab.$el && tab.$el.focus) {
        tab.$el.focus()
      } else if (tab.focus) {
        tab.focus()
      }
    },
    manageTablistKeydown(event) {
      // https://developer.mozilla.org/fr/docs/Web/API/KeyboardEvent/key/Key_Values#Navigation_keys
      const { key } = event
      switch (key) {
        case this.vertical ? 'ArrowUp' : 'ArrowLeft':
        case this.vertical ? 'Up' : 'Left': {
          let prevIdx = this.getPrevItemIdx(this.currentFocus, true)
          if (prevIdx === null) {
            // We try to give focus back to the last visible element
            prevIdx = this.getPrevItemIdx(Infinity, true)
          }
          const prevItem = this.items.find(i => i.index === prevIdx)
          if (
            prevItem &&
            this.$refs[`tabLink${prevIdx}`] &&
            !prevItem.disabled
          ) {
            this.giveFocusToTab(this.$refs[`tabLink${prevIdx}`])
          }
          event.preventDefault()
          break
        }
        case this.vertical ? 'ArrowDown' : 'ArrowRight':
        case this.vertical ? 'Down' : 'Right': {
          let nextIdx = this.getNextItemIdx(this.currentFocus, true)
          if (nextIdx === null) {
            // We try to give focus back to the first visible element
            nextIdx = this.getNextItemIdx(-1, true)
          }
          const nextItem = this.items.find(i => i.index === nextIdx)
          if (
            nextItem &&
            this.$refs[`tabLink${nextIdx}`] &&
            !nextItem.disabled
          ) {
            this.giveFocusToTab(this.$refs[`tabLink${nextIdx}`])
          }
          event.preventDefault()
          break
        }
      }
    },
    manageTabKeydown(event, childItem) {
      // https://developer.mozilla.org/fr/docs/Web/API/KeyboardEvent/key/Key_Values#Navigation_keys
      const { key } = event
      switch (key) {
        case ' ':
        case 'Space':
        case 'Spacebar':
        case 'Enter': {
          this.childClick(childItem)
          event.preventDefault()
          break
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@cling/styles/theme/utilities/_all.sass';

.c-tabs {
  .tabs {
    margin-bottom: 0;
    flex-shrink: 0;
    li {
      &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
  .tab-content {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    .tab-item {
      flex-shrink: 0;
      flex-basis: auto;
    }
    &.is-transitioning {
      overflow: hidden;
    }
  }
  &:not(:last-child) {
    margin-bottom: calc(1.5 * var(--rem));
  }
  &.is-fullwidth {
    width: 100%;
  }
  &.is-vertical {
    display: flex;
    flex-direction: row;

    > .tabs {
      ul {
        flex-direction: column;
        border-bottom-color: transparent;
        li {
          width: 100%;
          a {
            justify-content: left;
          }
        }
      }
      &.is-fullwidth {
        li {
          a {
            height: 100%;
          }
        }
      }
    }
    > .tab-content {
      flex-grow: 1;
    }

    &.is-right {
      flex-direction: row-reverse;

      > .tabs {
        ul {
          a {
            flex-direction: row-reverse;
            .icon:first-child {
              margin-right: 0;
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
  &.is-multiline {
    > .tabs {
      ul {
        flex-wrap: wrap;
        flex-shrink: 1;
      }
    }
  }
}
</style>
