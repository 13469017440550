import FloatingVue from 'floating-vue'
import { type App } from 'vue'

export * from 'floating-vue'

export default {
  install: (app: App, options: any) => {
    app.use(FloatingVue, {
      placement: 'top-start',
      delay: 300,
      distance: 16,
      disposeTimeout: 100,
      themes: {
        tooltip: {
          showTriggers: ['hover', 'click'],
          hideTriggers: (triggers: string[]) => [...triggers, 'click'],
          ...options
        },
        dropdown: {
          distance: 5,
          ...options
        }
      },
      ...options
    })
  }
}
