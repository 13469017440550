import config from '@cling/config'
import { viewContext } from '@cling/globalState'
import logger from '@cling/services/logger'
import { showMessage } from '@cling/services/messages'
import { global as globalActionTypes } from '@cling/store/action-types'

import { vOnClickOutside } from '@vueuse/components'
import PortalVue from 'portal-vue'

import brand from '@/extensions/brand'
import errorHandler from '@/extensions/errorHandler'
import eventTracker from '@/extensions/eventTracker'
import features from '@/extensions/features'
import featuresUpgrade from '@/extensions/featuresUpgrade'
import permissions from '@/extensions/permissions'
import piwik from '@/extensions/piwik'
import socket from '@/extensions/socket'
import supportChat from '@/extensions/supportChat'
import theme from '@/extensions/theme'
import thirdParty from '@/extensions/third-party'
import utm from '@/extensions/utm'
import viewSettings from '@/extensions/viewSettings'

function install(app, { store }) {
  app.use(PortalVue)
  app.use(permissions, { store })
  app.use(features, { store })
  app.use(featuresUpgrade, { store })
  app.use(errorHandler, {
    store,
    logger,
    showMessage,
    onNetworkError: () =>
      store.dispatch(globalActionTypes.SHOW_ERROR, { type: 'NetworkError' }),
    clearNetworkError: () => store.dispatch(globalActionTypes.RESET_ERROR)
  })
  app.use(supportChat, { store })
  app.directive('click-outside', vOnClickOutside)
  app.use(thirdParty)
  app.use(eventTracker, { store })
  app.use(brand, { store })
  app.use(theme, { store })
  app.use(viewSettings, { store })
  app.use(utm, { store })
  app.use(piwik, { store })
  app.use(socket)

  // SETTING GLOBAL VIEW
  viewContext.value = 'app'

  app.config.globalProperties.$constants = {
    actions: globalActionTypes
  }

  // Development Settings
  if (config.debug) {
    app.config.productionTip = false
    app.config.devtools = true
    app.config.performance = true
  }
}

export default {
  install
}
