import { reactive } from 'vue'

const windowSize = reactive({
  width: window.innerWidth || 800,
  height: window.innerHeight || 600,
  delay: 50,
  initialized: false,
  _timer: null,

  update() {
    this.width = window.innerWidth
    this.height = window.innerHeight
  },

  _handleResize() {
    clearTimeout(this._timer)
    this._timer = setTimeout(() => {
      this.update()
    }, this.delay)
  },

  init() {
    if (typeof window === 'undefined' || this.initialized) return
    this.update()
    window.addEventListener('resize', this._handleResize.bind(this), {
      passive: true
    })
    this.initialized = true
  },

  destroy() {
    if (!this.initialized) return
    window.removeEventListener('resize', this._handleResize.bind(this))
    this.initialized = false
  }
})

windowSize.init()

export default {
  computed: {
    windowWidth() {
      return windowSize.width
    },

    windowHeight() {
      return windowSize.height
    },

    mq() {
      const { width } = windowSize
      const breakpoints = {
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536,
        '3xl': Infinity
      }

      let res = 'lg'

      Object.keys(breakpoints)
        .reverse()
        .forEach(key => {
          if (width <= breakpoints[key]) {
            res = key
          }
        })

      return res
    }
  }
}
