import { extensionApi } from '@cling/api'
import { viewContext } from '@cling/globalState'
import { getDefaultClientObject } from '@cling/models/document/migrate'
import { global } from '@cling/store/action-types'
import { createApp } from 'vue'

const service = 'pipedrive'

const app = createApp()

export const postCloseModal = () =>
  window.parent?.postMessage({ message: 'closeModal' }, '*')

// Add Pipedrive Reference to document form structure
// Suggests document clients
const createServiceReference = async externalRef => {
  const _formData = { externalReferences: [{ ...externalRef, service }] }

  const { data } = await extensionApi.suggestClients(service, externalRef)

  if (Array.isArray(data) && data.length) {
    _formData.clients = data.map(c => ({
      ...getDefaultClientObject(),
      ...c
    }))
  }

  return _formData
}

// Interceptor for LOAD_DOCUMENT2_FORM action
// Takes local copy of pipedrive reference
// and supplies it to externalReferences for NEW documents
const addPipedriveDocRef = (action, state, { _formData }) => {
  if (!_formData || action.payload?.id) return
  action.payload._formData = { ...action.payload._formData, ..._formData }
}

const duplicateKeepClients = action => {
  if (!action.payload?.keepClients) action.payload.keepClients = true
}

const onDocSave = action => {
  if (!action.payload?.doSend) postCloseModal()
}

// Pipedrive specific action modifiers
const beforeModifiers = {
  [global.LOAD_DOCUMENT2_FORM]: addPipedriveDocRef,
  [global.LOAD_DUPLICATE_DOCUMENT2_FORM]: duplicateKeepClients
}

const afterModifiers = {
  [global.SAVE_DYNDOC_FORM]: onDocSave,
  [global.SAVE_TEMPLATE_FORM]: postCloseModal
}

const actionHandler = (modifiers, options) => (action, state) =>
  modifiers[action.type]?.(action, state, options)

export default {
  install(Vue, { router, store }) {
    // Prevent iframed views via pipedrive to avoid device mode restrictions on tokens
    viewContext.value = null

    let _installed

    store.dispatch(global.UPDATE_VIEW_SETTINGS, {
      view: 'app',
      settings: { isFullPageWindow: false }
    })

    const setupPipedriveContext = async ({ type, typeId } = {}) => {
      _installed = true

      let _formData
      if (type && typeId) {
        _formData = await createServiceReference({ type, typeId })
      }

      store.subscribeAction({
        before: actionHandler(beforeModifiers, { _formData }),
        after: actionHandler(afterModifiers)
      })
    }

    router.beforeEach(async (to, from, next) => {
      if (!_installed) await setupPipedriveContext(to.query)

      if (to.params.context === 'cancel') postCloseModal()

      return next()
    })
  }
}
