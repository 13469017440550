<!-- Virtual list extended in wrapper designed for fonts dropdown
Individual component in order to leverage component/template boundaries -->

<!-- Advantage: Independent changes in parent template won't re-render virtual list unnecessarily -->

<template>
  <div v-bind="containerProps" :style="listStyle">
    <div v-bind="wrapperProps">
      <FontDropdownItem
        v-for="item in list"
        :key="item.index"
        :source="item.data"
      >
        <template v-if="$slots.footer" #footer>
          <slot name="footer"></slot>
        </template>
      </FontDropdownItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVirtualList } from '@vueuse/core'
import { computed, ref, useAttrs, watch } from 'vue'

import FontDropdownItem from './FontDropdownItem.vue'

type Props = {
  items: Array<{ family: string }>
}

const props = defineProps<Props>()

const items = ref([...props.items])

watch(
  () => props.items,
  newItems => {
    items.value = [...newItems]
  }
)

const attrs = useAttrs()

const staticStyle = computed(() => attrs.style)

const listStyle = computed(() => ({
  'overflow-y': 'auto',
  height: '250px',
  width: '240px',
  ...staticStyle
}))

const { list, containerProps, wrapperProps } = useVirtualList(items, {
  itemHeight: 35
})
</script>
