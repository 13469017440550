<template>
  <router-view :class="{ 'app-content': !nativeScroll }" />
  <ModalView />
  <UserMessages />
  <UpdatesAvailable />
  <QueryVideo />
  <portal-target name="modal" multiple />
</template>
<script>
import fortnoxTheme from '@cling/mixins/fortnoxTheme'
import { getDeviceType } from '@cling/utils'
import webStorage from '@cling/utils/webStorage'

import { defineAsyncComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import ModalView from '@/components/ModalView.vue'
import QueryVideo from '@/components/QueryVideo.vue'
import UpdatesAvailable from '@/components/UpdatesAvailable.vue'

export default {
  name: 'App',
  components: {
    UserMessages: defineAsyncComponent(
      () => import('@cling/components/ui/UserMessages')
    ),
    ModalView,
    UpdatesAvailable,
    QueryVideo
  },
  mixins: [fortnoxTheme],
  computed: {
    ...mapGetters({
      modals: 'application/modals'
    }),
    ...mapState('route', {
      nativeScroll: state => state.meta?.body && state.meta.body?.nativeScroll
    })
  },
  watch: {
    nativeScroll: {
      immediate: true,
      handler(val) {
        const el = document.documentElement.classList
        if (val) el.add('native-scroll')
        else el.remove('native-scroll')
      }
    }
  },
  created() {
    // If IOS standalone app and lastPath is stored, redirect user
    const lastPath = webStorage.getItem('lastPath')
    const isStandalone = window.navigator && window.navigator.standalone
    if (
      getDeviceType() === 'ios' &&
      isStandalone &&
      lastPath &&
      this.$route.path !== lastPath
    ) {
      this.$router.push({ path: lastPath })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-content {
  flex: auto 1 1;
  height: 100%;
  overflow: hidden;
}
</style>
<style lang="scss">
@import '@cling/styles/main.scss';

// Used by router views to allow scroll
#app .body-scroll {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  min-height: 0;
}
</style>
