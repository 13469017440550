<template>
  <div style="padding: 3rem; background-color: white; border-radius: 1rem">
    <div
      v-if="isLoading"
      class="c-flex-row c-align-middle c-align-center"
      style="min-height: 266px"
    >
      <Spinner />
    </div>
    <form v-else @submit.prevent="submit">
      <div
        class="text-center"
        style="
          font-size: calc(1rem + 4px);
          font-weight: 900;
          margin-bottom: 1.5rem;
        "
      >
        {{ $t('title') }}
      </div>
      <div style="margin-bottom: 1.5rem">
        <CField>
          <CRadioButton
            v-model="propertyType"
            native-value="realEstate"
            type="secondary"
            size="normal"
          >
            {{ $t('realEstateRadio') }}
          </CRadioButton>
          <CRadioButton
            v-model="propertyType"
            native-value="condominium"
            type="secondary"
            size="normal"
          >
            {{ $t('condominiumRadio') }}
          </CRadioButton>
        </CField>
      </div>
      <template v-if="propertyType === 'realEstate'">
        <CField
          :validator="v$.propertyId"
          :messages="messages.realEstateId"
          :label="$t('_common:propertyDesignation.propertyDesignation')"
        >
          <CInput
            v-model="realEstateId"
            :placeholder="$t('_common:propertyDesignation.propertyDesignation')"
            @blur="v$.realEstateId.$touch()"
          />
        </CField>
      </template>
      <template v-if="propertyType === 'condominium'">
        <CField
          :validator="v$.condominiumId"
          :messages="messages.condominiumId"
          :label="$t('condominiumId.label')"
        >
          <CInput
            v-model="condominiumId"
            :placeholder="$t('condominiumId.placeholder')"
            @blur="v$.condominiumId.$touch()"
          />
        </CField>
        <CField
          :validator="v$.condominiumOrgNo"
          :messages="messages.condominiumOrgNo"
          :label="$t('condominiumOrgNo.label')"
        >
          <CInput
            v-model="condominiumOrgNo"
            :placeholder="$t('condominiumOrgNo.placeholder')"
            @blur="v$.condominiumOrgNo.$touch()"
          />
        </CField>
      </template>
      <CButton
        type="primary"
        expanded
        rounder
        size="medium"
        class="mt-6"
        @click="submit"
      >
        {{ $t('_common:save') }}
      </CButton>
    </form>
  </div>
</template>

<script>
import CField from '@cling/components/ui/FormField'
import CRadioButton from '@cling/components/ui/RadioButton'
import Spinner from '@cling/components/ui/Spinner'
import useVuelidate from '@vuelidate/core'
import {
  maxLength,
  minLength,
  numeric,
  requiredIf
} from '@vuelidate/validators'
import get from 'lodash/get'

import { mapActions, mapGetters } from 'vuex'

import { global } from '@/store/action-types'

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.propertyDesignationModalEdit'
  },
  name: 'PropertyDesignationModalEdit',
  components: {
    CField,
    CRadioButton,
    Spinner
  },
  emits: ['close'],
  props: {
    documentId: {
      type: String,
      required: true
    },
    propertyDesignation: {
      type: Object,
      default: () => null
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      propertyType: 'realEstate',
      condominiumOrgNo: '',
      realEstateId: '',
      condominiumId: ''
    }
  },
  validations: {
    realEstateId: {
      required: requiredIf(function ifCondition() {
        return this.propertyType === 'realEstate'
      })
    },
    condominiumId: {
      required: requiredIf(function ifCondition() {
        return this.propertyType === 'condominium' && this.condominiumOrgNo
      }),
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4)
    },
    condominiumOrgNo: {
      required: requiredIf(function ifCondition() {
        return this.propertyType === 'condominium' && this.condominiumId
      })
    }
  },
  computed: {
    ...mapGetters({
      docById: 'documents2/byId',
      isLoading: 'documents2/isFetching'
    }),
    document() {
      return this.docById(this.documentId) || null
    },
    messages() {
      return {
        realEstateId: {
          required: this.$t('validations.realEstateId.required')
        },
        condominiumId: {
          required: this.$t('validations.condominiumId.required'),
          numeric: this.$t('validations.condominiumId.numeric'),
          minLength: this.$t('validations.condominiumId.minLength'),
          maxLength: this.$t('validations.condominiumId.maxLength')
        },
        condominiumOrgNo: {
          required: this.$t('validations.condominiumOrgNo.required')
        }
      }
    },
    propertyId() {
      if (!this.propertyType) return ''
      return this.propertyType === 'realEstate'
        ? this.realEstateId
        : this.condominiumId
    }
  },
  async created() {
    // Assign pre existing data to internal
    const { propertyType, propertyId, condominiumOrgNo } = {
      ...this.propertyDesignation,
      ...get(this.document, 'clients[0].region.propertyDesignation', {})
    }

    if (propertyType) {
      this.propertyType = propertyType
      if (propertyType === 'realEstate') this.realEstateId = propertyId
      else {
        this.condominiumId = propertyId
        this.condominiumOrgNo = condominiumOrgNo
      }
    }
  },
  methods: {
    ...mapActions({
      updateDocument: global.UPDATE_DOCUMENT2
    }),
    async submit() {
      this.v$.$touch()
      if (this.v$.$invalid) return

      await this.updateDocument({
        id: this.documentId,
        body: {
          'clients.0.region.propertyDesignation': {
            propertyType: this.propertyType,
            propertyId: this.propertyId,
            ...(this.condominiumOrgNo && {
              condominiumOrgNo: this.condominiumOrgNo
            })
          }
        },
        params: { type: 'paths' }
      })

      this.$emit('close')
    }
  }
}
</script>
