import { updateField } from 'vuex-map-fields'

import { mutationTypes } from './constants'
import defaultState from './state'
import userMutations from './user/mutations'

export default {
  ...userMutations,
  updateField, // TODO: Remove?
  [mutationTypes.SET_VIEW_SETTINGS](state, { view, settings }) {
    if (!state.viewSettings[view]) {
      state.viewSettings[view] = {}
    }
    Object.keys(settings).forEach(key => {
      state.viewSettings[view][key] = settings[key]
    })
  },

  [mutationTypes.RESET_VIEW_SETTINGS](state, view) {
    state.viewSettings[view] = JSON.parse(defaultState.viewSettings[view])
  },

  [mutationTypes.SET_ERROR](state, error) {
    state.error = error
  },

  [mutationTypes.RESET_ERROR](state) {
    state.error = null
  },

  [mutationTypes.SET_SOCKET_ENABLED](state, enabled) {
    state.socketEnabled = enabled
  },

  [mutationTypes.SET_SOCKET_CONNECTED](state, isConnected) {
    state.isSocketConnected = isConnected
  }
}
