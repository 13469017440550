import lang from '@cling/language'
import { h, defineComponent } from 'vue'
import { RouterView } from 'vue-router'

const ClientForm = () => import('@/views/account/clients/ClientForm.vue')

const ClientContent = defineComponent({
  render() {
    return h('div', [h(RouterView)])
  }
})

export default [
  {
    path: 'client',
    components: {
      content: ClientContent
    },
    children: [
      {
        name: 'clients',
        path: 'list',
        component: () => import('@/views/account/clients/ClientsList.vue'),
        meta: {
          navbar: {
            type: 'default',
            title: lang.t('client_plural', { postProcess: 'capitalize' }),
            classList: 'flat'
          },
          body: {
            background: 'white'
          }
        }
      },
      {
        path: 'new',
        name: 'clientFormNew',
        component: ClientForm,
        meta: {
          navbar: {
            title: lang.t('router:clients.new'),
            type: 'simple'
          },
          body: {
            background: 'white',
            fullSize: true
          }
        }
      },
      // IMPORTANT NOTE: Needs to below as [:id] acts like a wildcard
      {
        name: 'client',
        path: ':id',
        component: () => import('@/views/account/clients/Client.vue'),
        props: route => ({
          id: Number(route.params.id)
        }),
        meta: {
          navbar: {
            type: 'default',
            title: lang.t('client_plural', { postProcess: 'capitalize' }),
            classList: 'flat'
          }
        }
      },
      {
        path: ':id/edit',
        name: 'clientFormEdit',
        component: ClientForm,
        meta: {
          navbar: {
            title: lang.t('router:clients.edit'),
            type: 'simple'
          },
          body: {
            background: 'white',
            fullSize: true
          }
        }
      }
    ]
  }
]
