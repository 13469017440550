<template>
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <slot :width="width" :height="height" />
</template>

<script setup>
import { useCurrentElement } from '@vueuse/core'
import elementResizeDetectorMaker from 'element-resize-detector'
import { ref, onMounted, onUnmounted, watch, provide } from 'vue'

if (!window.elementResizeDetectorMaker) {
  window.elementResizeDetectorMaker = elementResizeDetectorMaker({
    strategy: 'scroll'
  })
}

const props = defineProps({ provideDevice: Boolean })

defineOptions({ inheritAttrs: false }) // Prevents Vue from passing extraneous attributes to <slot>

const emit = defineEmits(['width', 'height', 'resize'])

const getDeviceType = width => {
  if (width <= 640) return 'mobile'
  if (width <= 1024) return 'tablet'
  return 'desktop'
}

const width = ref(null)
const height = ref(null)
const containerType = ref({ device: 'desktop' })
const currentEl = useCurrentElement()

if (props.provideDevice) {
  provide('containerType', containerType)
  watch(width, v => {
    containerType.value.device = getDeviceType(v)
  })
}

onMounted(() => {
  const el = currentEl.value?.nextElementSibling
  if (!el) return

  const handleResize = () => {
    width.value = el.offsetWidth
    height.value = el.offsetHeight
    emit('width', el.offsetWidth)
    emit('height', el.offsetHeight)
    emit('resize')
  }

  window.elementResizeDetectorMaker.listenTo(el, handleResize)

  width.value = el.offsetWidth
  height.value = el.offsetHeight

  onUnmounted(() => {
    window.elementResizeDetectorMaker.uninstall(el)
  })
})
</script>
