<template>
  <div
    ref="dropdown"
    :class="rootClasses"
    class="dropdown dropdown-menu-animation"
  >
    <div
      v-if="!inline"
      ref="trigger"
      role="button"
      class="dropdown-trigger"
      aria-haspopup="true"
      @click="onClick"
      @mouseenter="onHover"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
    >
      <slot :active="isActive" name="trigger" />
    </div>
    <transition name="fade-in">
      <div
        v-if="isMobileModal"
        v-show="isActive"
        ref="mobileBackground"
        :aria-hidden="!isActive"
        class="fixed inset-0 z-40 bg-black/40"
      />
    </transition>
    <transition :name="isMobileModal ? 'slide-in-bottom' : animation">
      <div
        v-show="(!disabled && (isActive || isHoverable)) || inline"
        ref="dropdownMenu"
        v-trap-focus="trapFocus"
        :style="{
          ...style,
          ...menuStyling
        }"
        :aria-hidden="!isActive"
        class="dropdown-menu"
      >
        <!-- Note: Template wrapper is used to improve performance and still have support for appendToBody -->
        <template v-if="isActive">
          <slot name="header" />
          <div
            :role="ariaRole"
            :style="contentStyle"
            :class="`${uniquePopoverClass}`"
            class="dropdown-content"
          >
            <slot v-if="isMobileModal && mobileLabel" name="mobileLabel">
              <div class="dropdown-content-label">{{ mobileLabel }}</div>
            </slot>
            <slot />
          </div>
          <slot name="footer" />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  removeElement,
  createAbsoluteElement
} from '@cling/components/ui/utils/helpers'
import trapFocus from '@cling/components/ui/utils/trapFocus'
import windowSize from '@cling/mixins/windowSize'

const DEFAULT_CLOSE_OPTIONS = ['escape', 'outside']

export const DROPDOWN_INJECTION_KEY = Symbol('cdropdown')

export default {
  name: 'CDropdown',
  directives: {
    trapFocus
  },
  mixins: [windowSize],
  provide() {
    return {
      [DROPDOWN_INJECTION_KEY]: this
    }
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object, Array, Function],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: [String, Number],
      default: 200
    },
    position: {
      type: String,
      default: 'is-bottom-left',
      validator(value) {
        return (
          [
            'is-top-right',
            'is-top-left',
            'is-bottom-left',
            'is-bottom-right'
          ].indexOf(value) > -1
        )
      }
    },
    triggers: {
      type: Array,
      default: () => ['click']
    },
    mobileModal: {
      type: Boolean,
      default: () => true
    },
    ariaRole: {
      type: String,
      validator(value) {
        return ['menu', 'list', 'dialog'].indexOf(value) > -1
      },
      default: null
    },
    animation: {
      type: String,
      default: 'fade-bounce'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    trapFocus: {
      type: Boolean,
      default: () => false // config.defaultTrapFocus,
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: [Array, Boolean],
      default: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    appendToBodyCopyParent: {
      type: Boolean,
      default: false
    },
    menuStyling: {
      type: Object,
      default: () => ({})
    },
    mobileLabel: {
      type: String,
      default: ''
    },
    paddingless: {
      type: Boolean,
      default: false
    }
  },
  emits: ['active-change', 'change', 'update:modelValue'],
  data() {
    return {
      selected: this.modelValue,
      style: {},
      isActive: false,
      isHoverable: this.hoverable,
      _isDropdown: true, // Used internally by DropdownItem
      maybeTap: false,
      isTouchEnabled: false,
      _bodyEl: undefined, // Used to append to body
      timeOutID: null,
      timeOutID2: null,
      uniquePopoverClass: `dropdown-popover-${this.$.uid}` // Used internally by DropdownItem
    }
  },
  computed: {
    rootClasses() {
      return [
        this.position,
        {
          'is-disabled': this.disabled,
          'is-hoverable': this.hoverable,
          'is-inline': this.inline,
          'is-active': this.isActive || this.inline,
          'is-mobile-modal': this.isMobileModal,
          'is-expanded': this.expanded,
          'is-touch-enabled': this.isTouchEnabled
        }
      ]
    },
    isMobileModal() {
      return this.mobileModal && this.mq === 'sm' // this.mobileModal && !this.inline && !this.hoverable;
    },
    cancelOptions() {
      return typeof this.canClose === 'boolean'
        ? this.canClose
          ? DEFAULT_CLOSE_OPTIONS
          : []
        : this.canClose
    },
    contentStyle() {
      return {
        maxHeight: this.scrollable
          ? this.maxHeight === undefined
            ? null
            : isNaN(this.maxHeight)
              ? this.maxHeight
              : `${this.maxHeight}px`
          : null,
        overflow: this.scrollable ? 'auto' : null,
        padding: this.paddingless ? '0' : null
      }
    }
  },
  watch: {
    /**
     * When v-model is changed set the new selected item.
     */
    modelValue(value) {
      this.selected = value
    },
    /**
     * Emit event when isActive value is changed.
     *
     * Also resets `isTouchEnabled` when it turns inactive.
     */
    isActive(value) {
      this.$emit('active-change', value)
      if (!value) {
        // delays to reset the touch enabled flag until the dropdown
        // menu disappears to avoid glitches
        // also takes care of chattering, e.g., repeated quick taps,
        // otherwise the flag may become inconsistent with the actual
        // state of the dropdown menu
        this.timeOutID = setTimeout(() => {
          if (!this.isActive) {
            this.isTouchEnabled = false
          }
        }, 250)
      }
      if (this.appendToBody) {
        this.$nextTick(() => {
          this.updateAppendToBody()
        })
      }
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.getRootElm().addEventListener('click', this.clickedOutside)
      document.addEventListener('keyup', this.keyPress)
    }
    if (this.appendToBody) {
      this.$data._bodyEl = createAbsoluteElement(this.$refs.dropdownMenu)
      this.$data._bodyEl.style.zIndex =
        Number(
          window.getComputedStyle(this.$el).getPropertyValue('--app-z-index')
        ) + 9999
      if (this.isMobileModal)
        this.$data._bodyEl.appendChild(this.$refs.mobileBackground)
      this.updateAppendToBody()
    }
  },
  beforeUnmount() {
    if (typeof window !== 'undefined') {
      this.getRootElm().removeEventListener('click', this.clickedOutside)
      document.removeEventListener('keyup', this.keyPress)
    }
    if (this.appendToBody) {
      removeElement(this.$data._bodyEl)
    }
    clearTimeout(this.timeOutID)
    clearTimeout(this.timeOutID2)
  },
  methods: {
    /**
     * Click listener from DropdownItem.
     *   1. Set new selected item.
     *   2. Emit input event to update the user v-model.
     *   3. Close the dropdown.
     */
    selectItem(value, options) {
      if (this.multiple) {
        if (this.selected) {
          const index = this.selected.indexOf(value)
          if (index === -1) {
            this.selected.push(value)
          } else {
            this.selected.splice(index, 1)
          }
        } else {
          this.selected = [value]
        }
        this.$emit('change', this.selected)
      } else if (this.selected !== value) {
        this.selected = value
        this.$emit('change', this.selected)
      }
      this.$emit('update:modelValue', this.selected)
      if (!this.multiple) {
        this.isActive = options.closeOnClick ? !this.closeOnClick : true
        if (this.hoverable && this.closeOnClick) {
          this.isHoverable = false
        }
      }
    },
    /**
     * White-listed items to not close when clicked.
     */
    isInWhiteList(el) {
      if (el === this.$refs.dropdownMenu) return true
      if (el === this.$refs.trigger) return true
      // All chidren from dropdown
      if (this.$refs.dropdownMenu != null) {
        const children = this.$refs.dropdownMenu.querySelectorAll('*')
        for (const child of children) {
          if (el === child) {
            return true
          }
        }
      }
      // All children from trigger
      if (this.$refs.trigger != null) {
        const children = this.$refs.trigger.querySelectorAll('*')
        for (const child of children) {
          if (el === child) {
            return true
          }
        }
      }
      return false
    },
    /**
     * Close dropdown if clicked outside.
     */
    clickedOutside(event) {
      if (this.cancelOptions.indexOf('outside') < 0) return
      if (this.inline) return

      // event.path?.[0] is used to detect the click path when the app is mounted inside a shadow element
      const target = event.path?.[0] || event.target

      if (!this.isInWhiteList(target)) this.isActive = false
    },
    /**
     * Keypress event that is bound to the document
     */
    keyPress(event) {
      // Esc key
      if (this.isActive && event.keyCode === 27) {
        if (this.cancelOptions.indexOf('escape') < 0) return
        this.isActive = false
      }
    },
    onClick() {
      // hover precedes
      if (this.triggers.indexOf('hover') !== -1) return
      if (this.triggers.indexOf('click') < 0) return
      this.toggle()
    },
    /**
     * Toggle dropdown if it's not disabled.
     */
    toggle() {
      if (this.disabled) return
      if (!this.isActive) {
        // if not active, toggle after clickOutside event
        // this fixes toggling programmatic
        this.timeOutID2 = setTimeout(() => {
          const value = !this.isActive
          this.isActive = value
        })
      } else {
        this.isActive = !this.isActive
      }
    },
    onHover() {
      if (!this.hoverable) return
      if (this.triggers.indexOf('hover') < 0) return
      // touch precedes
      if (this.isTouchEnabled) return
      this.isHoverable = true
    },
    // takes care of touch-enabled devices
    // - does nothing if hover trigger is disabled
    // - suppresses hover trigger by setting isTouchEnabled
    // - handles only a tap; i.e., touchstart on the trigger immediately
    //   folowed by touchend
    onTouchStart() {
      this.maybeTap = true
    },
    onTouchMove() {
      this.maybeTap = false
    },
    onTouchEnd(e) {
      if (this.triggers.indexOf('hover') === -1) return
      if (!this.maybeTap) return
      // tap on dropdown contents may happen without preventDefault
      e.preventDefault()
      this.maybeTap = false
      this.isTouchEnabled = true
      this.toggle()
    },
    updateAppendToBody() {
      const dropdownMenu = this.$refs.dropdownMenu
      const trigger = this.$refs.trigger
      if (dropdownMenu && trigger) {
        // update wrapper dropdown
        const dropdown = this.$data._bodyEl.children[0]
        dropdown.classList.forEach(item => dropdown.classList.remove(item))
        dropdown.classList.add('dropdown')
        dropdown.classList.add('dropdown-menu-animation')
        // TODO: not sure of intention of this
        if (this.$vnode && this.$vnode.data && this.$vnode.data.staticClass) {
          dropdown.classList.add(this.$vnode.data.staticClass)
        }
        this.rootClasses.forEach(item => {
          // skip position prop
          if (item && typeof item === 'object') {
            for (const key in item) {
              if (item[key]) {
                dropdown.classList.add(key)
              }
            }
          }
        })
        if (this.appendToBodyCopyParent) {
          const parentNode = this.$refs.dropdown.parentNode
          const parent = this.$data._bodyEl
          parent.classList.forEach(item => parent.classList.remove(item))
          parentNode.classList.forEach(item => {
            parent.classList.add(item)
          })
        }
        const rect = trigger.getBoundingClientRect()
        let top = rect.top + window.scrollY
        let left = rect.left + window.scrollX
        if (!this.position || this.position.indexOf('bottom') >= 0) {
          top += trigger.clientHeight
        } else {
          top -= dropdownMenu.clientHeight
        }
        if (this.position && this.position.indexOf('left') >= 0) {
          left -= dropdownMenu.clientWidth - trigger.clientWidth
        }
        this.style = {
          position: 'absolute',
          top: `${top}px`,
          left: `${left}px`,
          zIndex: '1001'
        }
      }
    },
    getRootElm() {
      const rootNode = this.$el.getRootNode?.()
      const isShadowParent = rootNode?.toString() === '[object ShadowRoot]'
      return isShadowParent ? rootNode : document
    }
  }
}
</script>
<style lang="scss">
@import '@cling/styles/theme/utilities/_all.sass';

$dropdown-mobile-breakpoint: $desktop !default;
$dropdown-background-color: rgba($black, 0.5) !default;
$dropdown-disabled-opacity: 0.5 !default;

.dropdown {
  & + .dropdown {
    margin-left: 0.5em;
  }
  &.dropdown-menu-animation {
    .dropdown-menu {
      display: block;
    }
  }
  .dropdown-menu {
    color: $black;
    .dropdown-item {
      &.is-disabled {
        cursor: not-allowed;
        &:hover {
          background: inherit;
          color: inherit;
        }
      }
    }
    .has-link a {
      @extend .dropdown-item;
      padding-right: calc(3 * var(--rem));
      white-space: nowrap;
    }
  }
  &.is-hoverable:not(.is-active) {
    .dropdown-menu {
      display: none;
    }
  }
  &.is-hoverable:not(.is-touch-enabled) {
    &:hover {
      .dropdown-menu {
        display: inherit;
      }
    }
  }
  &.is-expanded {
    width: 100%;
    .dropdown-trigger {
      width: 100%;
    }
    .dropdown-menu {
      min-width: 100%;
    }
    &.is-mobile-modal {
      .dropdown-menu {
        max-width: 100%;
      }
    }
  }
  &:not(.is-disabled) {
    .dropdown-menu {
      .dropdown-item {
        &.is-disabled {
          opacity: $dropdown-disabled-opacity;
        }
      }
    }
  }
  .navbar-item {
    height: 100%;
  }
  &.is-disabled {
    opacity: $dropdown-disabled-opacity;
    cursor: not-allowed;
    .dropdown-trigger {
      pointer-events: none;
    }
  }
  &.is-inline {
    .dropdown-menu {
      position: static;
      display: inline-block;
      padding: 0;
    }
  }
  &.is-top-right {
    .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  &.is-top-left {
    .dropdown-menu {
      top: auto;
      bottom: 100%;
      right: 0;
      left: auto;
    }
  }
  &.is-bottom-left {
    .dropdown-menu {
      right: 0;
      left: auto;
    }
  }
  &.is-mobile-modal {
    & > .dropdown-menu {
      position: fixed !important;
      width: calc(100vw - 40px);
      max-width: 460px;
      max-height: calc(100vh - 120px);
      /* top: 25% !important; */
      top: initial !important;
      left: 50% !important;
      /* bottom: auto !important; */
      bottom: 2em !important;
      right: auto !important;
      /* transform: translate3d(-50%, -25%, 0); */
      transform: translate3d(-50%, 0, 0);
      white-space: normal;
      overflow-y: auto;
      z-index: 50 !important;
      > .dropdown-content {
        > .dropdown-item,
        > .has-link a {
          padding: calc(1 * var(--rem));
          font-size: 18px;
          font-weight: 500;
        }
        .dropdown-content-label {
          padding: calc(1 * var(--rem));
          color: $grey-dark;
        }
      }
    }
  }
}

.fade-bounce-enter-active {
  animation: fadeBounceInDown 150ms ease none;
  transform-origin: top;
  .is-top-left &,
  .is-top-right & {
    transform-origin: bottom;
  }
}
.fade-bounce-leave-active {
  animation: fadeBounceInDown 80ms ease reverse;
  transform-origin: top;
  .is-top-left &,
  .is-top-right & {
    transform-origin: bottom;
  }
}

.slide-in-bottom {
  &-leave-from,
  &-enter-to {
    transform: translate3d(-50%, 0, 0) !important;
  }

  &-enter-from,
  &-leave-to {
    transform: translate3d(-50%, 100%, 0) !important;
  }

  &-enter-active,
  &-leave-active {
    transition: all 150ms ease-out !important;
  }
}
</style>
