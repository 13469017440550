import { deepMergeReplace, getUniqueArrayElements } from '@cling/store/utils'

import { mutationTypes } from './constants'

const {
  SET_TEMPLATE_MESSAGES,
  DELETE_TEMPLATE_MESSAGE,
  CLEAR_TEMPLATE_MESSAGES,
  SET_TEMPLATE_MESSAGES_FETCHING,
  SET_TEMPLATE_MESSAGES_POSTING
} = mutationTypes

export default {
  // Set template messages
  // Should only be called after any normalization has been executed from action
  // templateMessages: Array with objects
  // doClearOld: Optional bool if old data should be removed before set
  [SET_TEMPLATE_MESSAGES](state, { data }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a company user or an array of company users!')
    }
    const items = Array.isArray(data) ? data : [data]
    const ids = []
    const itemObj = {}

    items.forEach(itm => {
      itemObj[itm.id] = itm
      ids.push(itm.id)
    })

    deepMergeReplace(state.data, itemObj)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  // Delete one
  [DELETE_TEMPLATE_MESSAGE](state, id) {
    delete state.data[id]
    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_TEMPLATE_MESSAGES
   * Clear all template messages
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_TEMPLATE_MESSAGES](state) {
    state.data = {}
    state.ids = []
  },

  /**
   * @name SET_TEMPLATE_MESSAGES_FETCHING
   * Set fetching status of template messages
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_TEMPLATE_MESSAGES_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_TEMPLATE_MESSAGES_POSTING
   * Set posting status of template messages
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_TEMPLATE_MESSAGES_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}
