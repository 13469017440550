import emitter from 'tiny-emitter/instance'
import { createVNode, render } from 'vue'

import ModalsContainer from './components/ModalsContainer.vue'

const PluginCore = (app, options = {}) => {
  const subscription = {
    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args)
  }

  const context = {
    __modalContainer: null,
    componentName: options.componentName || 'Modal'
  }
  subscription.$on('set-modal-container', container => {
    context.__modalContainer = container
  })

  if (options?.onToggle && typeof options.onToggle === 'function')
    subscription.$on('toggle', options.onToggle)
  if (options?.onRemove && typeof options.onRemove === 'function')
    subscription.$on('remove', options.onRemove)

  const showStaticModal = (name, params) => {
    subscription.$emit('toggle', name, true, params)
  }

  const showDynamicModal = (
    component,
    componentProps,
    modalProps = {},
    modalEvents
  ) => {
    const container = context.__modalContainer
    const defaults = options.dynamicDefaults || {}

    container?.add(
      component,
      componentProps,
      { ...defaults, ...modalProps },
      modalEvents
    )
  }

  /**
   * Creates a container for modals in the root Vue component.
   *
   * @param {Vue} parent
   * @param {Vue} app
   */
  const setDynamicModalContainer = app => {
    const containerElement = options?.rootNode || document.body

    const vnode = createVNode(ModalsContainer)
    vnode.appContext = app._context
    render(vnode, containerElement)
  }

  const show = (...args) => {
    const [modal] = args

    switch (typeof modal) {
      case 'string':
        showStaticModal(...args)
        break

      case 'object':
      case 'function':
        showDynamicModal(...args)
        break

      default:
        // eslint-disable-next-line no-console
        console.warn(
          '[vue-js-modal] $modal() received an unsupported argument as a first argument.',
          modal
        )
    }
  }

  const hide = (name, params) => {
    subscription.$emit('toggle', name, false, params)
  }

  const hideAll = () => {
    subscription.$emit('hide-all')
  }

  const toggle = (name, params) => {
    subscription.$emit('toggle', name, undefined, params)
  }

  const getOpenInstances = () => {
    return context.__modalContainer?.modals || []
  }

  return {
    context,
    subscription,
    show,
    hide,
    hideAll,
    toggle,
    getOpenInstances,
    setDynamicModalContainer
  }
}

export default PluginCore
