import config from '@cling/config'
import lang from '@cling/language'
import { capitalize } from '@cling/utils'
import webStorage from '@cling/utils/webStorage'
import { RouterView } from 'vue-router'

import account from '@/router/account'
import shortLink from '@/router/shortLink'
import { appAuthGuards } from '@/services/integrations'

const ForgottenPassword = () => import('@/views/ForgottenPassword.vue')
const Login = () => import('@/views/Login.vue')
const Logout = () => import('@/views/Logout.vue')
const NewPassword = () => import('@/views/NewPassword.vue')
const NotFound = () => import('@/views/NotFound.vue')
const Register = () => import('@/views/Register.vue')
const RegisterDemo = () => import('@/views/RegisterDemo.vue')
const RegisterDeal = () => import('@/views/RegisterDeal.vue')
const RegisterExisting = () => import('@/views/RegisterExisting.vue')
const Unsubscribe = () => import('@/views/Unsubscribe.vue')

function redirectAuthToAccount(to, from, next) {
  // If user is already logged in, send to account
  const token = webStorage.getItem('token')
  if (token) {
    return next({ name: 'account' })
  }
  return next()
}

function logout(to, from, next) {
  webStorage.removeItem('token')
  return next()
}

const routes = [
  { path: '/', redirect: { name: 'Login' } },
  {
    path: '/sh/:publicId',
    ...shortLink // Handle shortLink routes
  },
  {
    path: '/a',
    ...account
  },
  { path: '/konto', redirect: '/a' }, // Supporting old bookmarks + email redirects etc
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: redirectAuthToAccount,
    alias: '/loggain'
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    alias: '/loggaut'
  },
  {
    path: '/register/user/:token',
    name: 'registerExisting',
    component: RegisterExisting,
    alias: '/registrera/anvandare/:token'
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: redirectAuthToAccount,
    alias: '/registrera'
  },
  {
    path: '/registreraDemo',
    name: 'registerDemo',
    component: RegisterDemo,
    beforeEnter: logout
  },
  {
    path: '/registreraDeal',
    name: 'registerDeal',
    component: RegisterDeal,
    beforeEnter: logout
  },
  {
    path: '/reset-password/new/:token',
    name: 'newPassword',
    component: NewPassword,
    beforeEnter: redirectAuthToAccount,
    alias: '/glomt-losenord/nytt/:token'
  },
  {
    path: '/reset-password',
    name: 'forgottenPassword',
    component: ForgottenPassword,
    beforeEnter: redirectAuthToAccount,
    alias: '/glomt-losenord'
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: Unsubscribe,
    alias: '/avregistrera'
  },
  {
    path: '/createDocument',
    name: 'DocumentFormPublic',
    component: () =>
      import('@/views/account/create/document/DocumentFormPublic.vue'),
    props: { content: true },
    beforeEnter: (to, from, next) => {
      if (webStorage.getItem('token')) {
        return to && to.query && to.query.publicDocumentId
          ? next({ name: 'documentForm', query: to.query })
          : next({ name: 'account' })
      }
      return next()
    },
    meta: {
      title: lang.t('router:documentFormPublic'),
      navbar: {
        title: lang.t('router:documentFormPublic')
      }
    }
  },
  {
    path: '/gallery',
    component: () => import('@/views/templateGallery/index.vue'),
    props: {
      model: true,
      content: true
    },
    meta: {
      body: {
        nativeScroll: true
      }
    }
  },
  {
    path: '/template/:id',
    name: 'template',
    props: true,
    component: () => import('@/views/publicTemplate/Index.vue')
  },
  {
    path: '/document/:id',
    alias: ['/dokument/:id', '/kund/:id'], // Legacy routes for old documents
    component: () => import('@/views/publicDocument/Index.vue'),
    name: 'publicDocument',
    props: true,
    children: [
      {
        path: 'property-designation',
        alias: 'fastighetsbeteckning',
        name: 'docPropertyDesignation'
      }
    ]
  },
  {
    path: '/document/:id/pdf',
    name: 'publicDocumentPdf',
    component: () => import('@/views/publicDocument/Pdf.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/views/Verify.vue'),
    meta: {
      title: lang.t('router:verify')
    }
  },
  // Install flow external Apps (separate from chrome extension)
  {
    path: '/auth/:app?',
    component: RouterView,
    meta: { fullscreen: true },
    beforeEnter: appAuthGuards.beforeAll,
    children: [
      {
        path: '',
        name: 'AppAuth',
        component: () =>
          import(
            /* webpackChunkName: "integrations" */ '@/views/appAuth/SignOn.vue'
          ),
        props: true,
        meta: { type: 'login' },
        beforeEnter: appAuthGuards.beforeAuth
      },
      {
        path: 'user',
        name: 'AppUserLogin',
        component: () =>
          import(
            /* webpackChunkName: "integrations" */ '@/views/appAuth/SignOn.vue'
          ),
        props: route => ({ app: route.params.app, mode: 'login' }),
        meta: { type: 'login' }
      },
      {
        path: 'callback',
        name: 'AppInstall',
        component: () =>
          import(
            /* webpackChunkName: "integrations" */ '@/views/appAuth/Install.vue'
          ),
        props: true,
        beforeEnter: appAuthGuards.beforeInstall
      }
    ]
  },
  {
    path: '/partner/connect',
    name: 'AuthPartnerConnect',
    component: () => import('@/views/partner/PartnerConnect.vue')
  },
  {
    path: '/me',
    name: 'clientView',
    component: () => import('@/views/client/Index.vue')
  },
  {
    path: '/me/login',
    name: 'clientLogin',
    component: () => import('@/views/client/Login.vue')
  },
  {
    path: '/purchase/success',
    name: 'purchaseSuccess',
    component: () => import('@/views/PurchaseSuccess.vue')
  },
  { path: '/404', name: 'notFound', component: NotFound },
  ...(config.environment === 'development'
    ? [
        {
          path: '/components',
          name: 'components',
          component: () => import('@/views/dev/Components.vue')
        },
        {
          path: '/textEditor',
          name: 'textEditor',
          component: () => import('@/views/dev/TextEditors.vue')
        }
      ]
    : []),
  { path: '/:pathMatch(.*)*', redirect: '/404' } // Redirect unavailable pages to 404
]

export default routes
