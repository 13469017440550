<template>
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <slot
    :open-modal="index => FilePreviewModal(index)"
    :is-image-or-pdf="str => isImage(str) || isPdf(str)"
  />
</template>

<!-- eslint-disable no-prototype-builtins -->
<script>
import FilePreviewModal from '@cling/components/FilePreviewModal.vue'

export default {
  name: 'FilePreviewWrapper',
  props: {
    list: {
      type: Array,
      default: () => [],
      validator: arr => {
        if (!arr.length) return true
        if (!arr[0].hasOwnProperty('url')) {
          return false
        }
        if (!arr[0].hasOwnProperty('name')) {
          return false
        }
        if (!arr[0].hasOwnProperty('mime')) {
          return false
        }
        return true
      }
    }
  },
  computed: {
    fileList() {
      return this.list.reduce(
        (r, x) => (this.isImage(x.mime) || this.isPdf(x.mime) ? [...r, x] : r),
        []
      )
    }
  },
  methods: {
    isImage(str) {
      const lwrStr = str.toLowerCase(str)
      return /image\/(gif|jpg|jpeg|png|bmp|svg|svg\+xml|tiff)$/.test(lwrStr)
    },
    isPdf(str) {
      return str === 'application/pdf'
    },
    FilePreviewModal(index) {
      this.$modal.show(
        FilePreviewModal,
        {
          index: this.fileList.indexOf(this.list[index]), // Translate src index to img list index
          list: this.fileList
        },
        {
          adaptive: true,
          height: '100%',
          width: '100%',
          classes: '', // Don't remove. In place to not apply default modal class
          transition: ''
        }
      )
    }
  }
}
</script>
