<template>
  <div class="modal-overlay font-inter" @click.self="$emit('close')">
    <div :class="{ checked: form.isDone }" class="modal-container">
      <div class="modal-main">
        <div class="top-row">
          <div class="flex w-full justify-end" style="padding-bottom: 1.5em">
            <div style="margin-right: auto; display: flex; align-items: center">
              <CButton
                :icon-props="{ size: 20, strokeWidth: 3 }"
                :class="
                  form.isDone && [
                    'is-done',
                    'task-bg',
                    `is-${form.isDone && task.tag ? task.theme : 'secondary'}`
                  ]
                "
                :style="
                  !form.isDone && {
                    boxShadow: 'inset 0 0 0 2px hsl(0, 0%, 85%)',
                    color: 'hsl(0, 0%, 65%)'
                  }
                "
                style="margin-right: 0.75em"
                icon="check"
                circle
                @click="form.isDone = !form.isDone"
              />
              <span v-if="form.isDone">{{ $t('done').capitalize() }}!</span>
            </div>
            <CDropdown
              ref="tagDropdown"
              :menu-styling="{
                minWidth: 'initial'
              }"
              position="is-bottom-left"
            >
              <template #trigger>
                <CButton
                  :icon-props="{
                    size: task.theme ? 30 : 24,
                    fill: task.theme ? 'currentColor' : 'none'
                  }"
                  :icon="task.theme ? 'circle' : 'colored-dots'"
                  :class="[`has-text-${task.theme}`]"
                  circle
                  type="none"
                  pattern="tertiary"
                  style="margin-left: 0.25em; animation-duration: 200ms"
                />
              </template>
              <CDropdownItem
                :focusable="false"
                paddingless
                custom
                class="task-tag-wrapper"
              >
                <div
                  v-for="(tagOption, i) in tagOptions"
                  :key="i"
                  :class="[
                    `has-background-${
                      tagOption
                        ? tagOption !== 'black'
                          ? tagOption
                          : 'text'
                        : 'grey-lighter'
                    }`
                  ]"
                  :style="
                    tagOption === task.tag && {
                      boxShadow:
                        '0 0 0 3px hsl(0, 0%, 100%), 0 0 0 5px hsl(205, 100%, 50%)'
                    }
                  "
                  class="task-tag-button task-bg"
                  @click="
                    () => {
                      form.tag = tagOption !== 'none' ? tagOption : null
                      tagDropdown.toggle()
                    }
                  "
                />
              </CDropdownItem>
            </CDropdown>
            <CDropdown position="is-bottom-left">
              <template #trigger>
                <CButton
                  :icon-props="{ size: 24 }"
                  class="has-text-grey"
                  icon="more-vertical"
                  circle
                  type="none"
                  pattern="tertiary"
                />
              </template>
              <CDropdownItem type="danger" class="text-right" @click="onRemove">
                {{ $t('remove') }}
              </CDropdownItem>
            </CDropdown>
          </div>
          <CField>
            <CInput
              v-model="form.name"
              :placeholder="$t('account.project.taskModal.placeholder')"
              filled
              expanded
              size="large"
            />
          </CField>
          <div style="margin-top: 1em">
            <BaseTextEditor
              ref="description"
              v-model="form.description"
              :placeholder="$t('description').capitalize()"
              textarea-style
              size="medium"
              filled
            />
          </div>
          <ProjectTaskChecklist
            v-model="form.checklists"
            :get-default-item="getDefaultChecklistItem"
            :tag="task.theme"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import CField from '@cling/components/ui/FormField'
import BaseTextEditor from '@cling/components/ui/TextEditor'
import useTaskStore from '@cling/stores/tasks'
import debounce from 'lodash/debounce'
import { ref, reactive, computed, watch } from 'vue'

import ProjectTaskChecklist from './ProjectTaskChecklist.vue'

export default {
  i18nOptions: {
    namespaces: 'views'
  },
  name: 'ProjectTaskModal',
  components: {
    BaseTextEditor,
    ProjectTaskChecklist,
    CDropdown,
    CDropdownItem,
    CField
  },
  emits: ['close'],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const taskStore = useTaskStore()
    const task = computed(() => {
      const t = taskStore.getTaskById(props.id)
      return { ...t, theme: taskStore.getTheme(t) }
    })

    const form = reactive({
      name: task.value?.name || '',
      description: task.value?.description || '',
      isDone: task.value?.isDone || false,
      tag: task.value?.tag || null,
      checklists: task.value?.checklists || []
    })

    const tagOptions = computed(() => taskStore.tagOptions)
    const tagDropdown = ref(null)
    const getDefaultChecklistItem = taskStore.getChecklistItem

    const onSubmit = debounce(async () => {
      await taskStore.submit({ id: props.id, body: { ...task.value, ...form } })
    }, 500)

    const onRemove = async () => {
      await taskStore.remove(props.id)
      emit('close')
    }

    watch(
      () => form,
      () => onSubmit(),
      { deep: true }
    )

    return {
      task,
      form,
      tagOptions,
      tagDropdown,
      getDefaultChecklistItem,
      onRemove
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

$borderRadius: 1em;

.modal-overlay {
  background-color: rgba($black, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  padding: 5em 1em;
  overflow: auto;
}

.modal-container {
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  cursor: default;
  position: relative;
  width: 33em;
  background-color: white;
  font-size: 16px;
  border-radius: $borderRadius;
  overflow: hidden;
  .modal-main {
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    position: relative;
    .top-row {
      padding: 1em 2em 1.5em 2em;
      @media #{$mobile} {
        padding: 1em 1em 1.25em 1em;
      }
    }
  }
}

.task-tag {
  &-wrapper {
    width: 3 * (2em + 0.35em * 2);
    display: flex;
    flex-wrap: wrap;
  }
  &-button {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin: 0.35em;
    cursor: pointer;
    transition: 80ms;
    display: inline-block;
    &:hover {
      transform: scale(1.2);
    }
    &:active {
      transform: scale(1);
    }
  }
}

.task-bg.task-bg.task-bg {
  color: $white;
  border-color: transparent;
  &.none {
    background-color: hsl(0, 0%, 90%);
    color: $black;
    &.is-done {
      background-color: hsl(0, 0%, 40%);
      color: $white;
    }
  }
}
</style>
