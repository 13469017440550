import {
  convertArrToObject,
  deepMergeReplace,
  getUniqueArrayElements
} from '@cling/store/utils'

import { mutationTypes } from './constants'

const {
  UPDATE_MANY_FILES,
  DELETE_ONE_FILE,
  CLEAR_FILES,
  SET_FILES_FETCHING,
  SET_FILES_POSTING
} = mutationTypes

export default {
  // files: Array with objects
  // doClearOld: Optional bool if old data should be removed before set
  [UPDATE_MANY_FILES](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a company user or an array of company users!')
    }
    const files = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(files)

    const newPublicIds = ids.reduce((prevVal, newVal) => {
      if (!values[newVal] || !values[newVal].publicId) return prevVal

      return {
        ...prevVal,
        [values[newVal].publicId]: values[newVal].id
      }
    }, {})

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])

    state.filePublicIdToId = {
      ...state.filePublicIdToId,
      ...newPublicIds
    }
  },
  // Delete one
  [DELETE_ONE_FILE](state, id) {
    const file = state.data[id]
    if (file) {
      delete state.filePublicIdToId[state.data[id].publicId]
      delete state.data[id]
    }
    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_FILES
   * Clear all files
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_FILES](state) {
    state.data = {}
    state.ids = []
    state.filePublicIdToId = []
  },

  /**
   * @name SET_FILES_FETCHING
   * Set fetching status
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_FILES_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_FILES_POSTING
   * Set posting status
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_FILES_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}
