import lang from '@cling/language'
import { getRootContext } from '@cling/utils'
import Mention from '@tiptap/extension-mention'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import Suggestion from '@tiptap/suggestion'
import { VueRenderer } from '@tiptap/vue-3'
import tippy, { sticky } from 'tippy.js'

import MentionList from './components/MentionList.vue'

const MENTION_CLASS = 'mention'

const filterItems = (query, items) =>
  (items || []).filter(({ label = '' }) =>
    label.toLowerCase().includes(query.toLowerCase())
  )

function suggestions(getItems = null) {
  let items = []
  const _getItems = typeof getItems === 'function' ? getItems : () => []

  return {
    render: () => {
      let component
      let popup
      let touchStartListener

      return {
        onStart: props => {
          items = _getItems()
          props.items = filterItems(props.query, items)

          const { el, isShadow } = getRootContext(this.$el)
          const appendTo = isShadow ? el : el.body
          const rootContext = isShadow ? el : document

          component = new VueRenderer(MentionList, {
            props,
            editor: props.editor
          })

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start', // 'top-start'
            plugins: [sticky],
            onHidden: () => {
              // Clean up when the mention list is hidden
              component.destroy()
              if (touchStartListener) {
                rootContext.removeEventListener(
                  'touchstart',
                  touchStartListener
                )
              }
            }
          })

          // Hide the mention list when a touch starts outside of the list
          touchStartListener = event => {
            if (!popup[0].popper.contains(event.target)) {
              popup[0].hide()
            }
          }

          rootContext.addEventListener('touchstart', touchStartListener)
        },
        onUpdate(props) {
          props.items = filterItems(props.query, items)
          component.updateProps(props)
          popup[0].setProps({ getReferenceClientRect: props.clientRect })
        },
        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
            return true
          }

          return component.ref.onKeyDown(props)
        },
        onExit() {
          popup[0].destroy()
          component.destroy()
          if (touchStartListener) {
            const { el, isShadow } = getRootContext(this.$el)
            const rootContext = isShadow ? el : document
            rootContext.removeEventListener('touchstart', touchStartListener)
          }
        }
      }
    }
  }
}

export default function (getItems = null) {
  return Mention.extend({
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: el => el.getAttribute('data-mention-id'),
          renderHTML: attrs => ({ 'data-mention-id': attrs.id })
        },
        label: {
          default: null,
          parseHTML: el =>
            el.innerText.split(this.options.suggestion.char).join('')
        },
        tooltip: {
          default: null,
          parseHTML: el => el.getAttribute('data-tooltip'),
          renderHTML: attrs => ({ 'data-tooltip': attrs.tooltip })
        },
        filter: {
          default: null,
          parseHTML: el => el.getAttribute('data-filter'),
          renderHTML: attrs => ({ 'data-filter': attrs.filter })
        }
      }
    },

    parseHTML() {
      return [{ tag: 'span[data-mention-id]' }]
    },

    renderHTML({ HTMLAttributes }) {
      return [
        'span',
        {
          class: [
            MENTION_CLASS,
            ...(HTMLAttributes['data-tooltip'] ? [] : ['empty'])
          ].join(' '),
          ...HTMLAttributes,
          'data-tooltip':
            HTMLAttributes['data-tooltip'] ||
            lang.t('_common:missing', { thing: lang.t('_common:value') })
        },
        `${HTMLAttributes.label}`
      ]
    },

    addProseMirrorPlugins() {
      const _getItems = typeof getItems === 'function' ? getItems : () => []
      let items = _getItems()

      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion
        }),
        new Plugin({
          key: new PluginKey('mention-paste-handler'),
          props: {
            handlePaste(view, event) {
              if (view.props.editable && !view.props.editable(view.state)) {
                return false
              }

              const clipboard =
                event.clipboardData || event.originalEvent.clipboardData

              if (!clipboard) return false

              const text = clipboard.getData('text/plain')

              if (
                typeof text !== 'string' ||
                !text.startsWith('{{') ||
                !text.endsWith('}}')
              ) {
                return false
              }

              const path = text.substring(2, text.length - 2)
              items = _getItems() // Get latest document items

              const matchedItem = items.find(itm => itm.id === path)

              if (!matchedItem) return false

              const { state, dispatch } = view
              const node = state.schema.nodes.mention.create(matchedItem)
              const transaction = state.tr.replaceSelectionWith(node)
              dispatch(transaction)

              return true
            }
          }
        })
      ]
    }
  }).configure({
    HTMLAttributes: { class: MENTION_CLASS },
    suggestion: suggestions.call(this, getItems)
  })
}
