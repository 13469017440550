<script setup>
import { computed, onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue'

defineProps({
  distance: {
    type: Number,
    default: 6
  }
})

const open = ref(false)
const popover = useTemplateRef('popover')
const firstElementChild = computed(() => popover.value?.$el?.firstElementChild)

onMounted(() => {
  firstElementChild.value?.addEventListener('click', onShow)
})

const onShow = event => {
  event.preventDefault()
  open.value = !open.value
  if (open.value) {
    addClickOutsideListener()
  } else {
    removeClickOutsideListener()
  }
}

const hide = () => {
  open.value = false
  removeClickOutsideListener()
}

const onClickOutside = event => {
  const popperEl = popover.value?.$refs?.popper?.popperNode?.()
  if (!popperEl?.contains(event.target)) {
    open.value = false
    removeClickOutsideListener()
  }
}

const addClickOutsideListener = () => {
  setTimeout(() => {
    getRootNode()?.addEventListener('click', onClickOutside)
  }, 0)
}

const removeClickOutsideListener = () => {
  getRootNode().removeEventListener('click', onClickOutside)
}

const getRootNode = () => {
  const rootNode = popover.value?.$el.getRootNode?.()
  const isShadowParent = rootNode?.toString() === '[object ShadowRoot]'
  return isShadowParent ? rootNode : document
}

onBeforeUnmount(() => {
  removeClickOutsideListener()
  firstElementChild.value?.removeEventListener('click', onShow)
})
</script>

<template>
  <VDropdown
    :shown="open"
    ref="popover"
    :distance="distance"
    :triggers="[]"
    :auto-hide="false"
  >
    <slot />
    <template #popper>
      <slot name="popper" :hide="hide" />
    </template>
  </VDropdown>
</template>
