<template>
  <component
    v-bind="$attrs"
    :is="computedTag"
    :type="nativeType"
    :disabled="disabled || loading ? true : null"
    :to="to"
    class="c-button font-config button"
    :class="[
      `is-${size}`,
      computedPatternClass,
      computedTypeClass,
      tailwindClasses($attrs.class),
      {
        'is-rounded': rounded,
        'is-rounder': rounder,
        'is-loading': loading,
        'is-outlined': outlined,
        'is-fullwidth': expanded,
        'is-inverted': inverted,
        'is-focused': focused,
        'is-active': active,
        'is-hovered': hovered,
        'is-selected': selected,
        'is-raised': raised,
        'is-wide': wide,
        'is-taller': taller,
        'is-thinner': thinner,
        'is-circle': circle
      }
    ]"
  >
    <CIcon
      v-if="icon"
      v-bind="{ size: iconSize, ...iconProps }"
      :type="icon"
      class="icon"
    />
    <CIcon
      v-if="iconLeft"
      v-bind="{ size: iconSize, ...iconProps }"
      :type="iconLeft"
      class="icon"
    />
    <span v-if="label">{{ label }}</span>
    <span v-else-if="$slots.default">
      <slot />
    </span>
    <CIcon
      v-if="iconRight || dropdownIcon"
      v-bind="{ size: iconSize, ...iconProps }"
      :type="iconRight || 'chevron-up-down'"
      :class="{ 'icon-right-absolute': dropdownIcon }"
      class="icon"
    />
  </component>
</template>

<script>
import CIcon from '@cling/components/ui/Icon'
import { twMerge } from 'tailwind-merge'

const config = {
  defaultLinkTags: [
    'a',
    'button',
    'input',
    'label',
    'router-link',
    'nuxt-link',
    'n-link',
    'RouterLink',
    'NuxtLink',
    'NLink'
  ],
  defaultButtonRounded: false
}

export default {
  name: 'CButton',
  components: {
    CIcon
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: null,
      validator: value =>
        [
          'primary',
          'secondary',
          'tertiary',
          'success',
          'warning',
          'danger',
          'text',
          'none'
        ].indexOf(value) >= 0
    },
    size: {
      type: String,
      default: null,
      validator: value =>
        ['small', 'normal', 'default', 'medium', 'large'].indexOf(value) >= 0
    },
    pattern: {
      type: String,
      default: 'primary',
      validator: value =>
        ['primary', 'secondary', 'tertiary'].indexOf(value) >= 0
    },
    label: String,
    iconPack: String,
    iconLeft: String,
    iconRight: String,
    rounded: {
      type: Boolean,
      default: () => config.defaultButtonRounded
    },
    loading: Boolean,
    disabled: Boolean,
    outlined: Boolean,
    expanded: Boolean,
    inverted: Boolean,
    focused: Boolean,
    active: Boolean,
    hovered: Boolean,
    selected: Boolean,
    nativeType: {
      type: String,
      default: 'button',
      validator: value => ['button', 'submit', 'reset'].indexOf(value) >= 0
    },
    tag: {
      type: String,
      default: 'button',
      validator: value => config.defaultLinkTags.indexOf(value) >= 0
    },
    raised: Boolean,
    wide: Boolean,
    circle: Boolean,
    icon: String,
    iconProps: {
      type: Object,
      default: () => ({})
    },
    dropdownIcon: Boolean,
    taller: Boolean,
    thinner: Boolean,
    rounder: Boolean,
    to: {
      type: [Object, String],
      default: undefined,
      validator(val) {
        if (val === undefined) return true
        if (typeof val === 'string') return true
        if (val.name && typeof val.name === 'string') return true
        return false
      }
    }
  },
  computed: {
    computedTag() {
      const isDisabled =
        this.$attrs.disabled !== undefined && this.$attrs.disabled !== false
      if (this.to && !isDisabled) return 'router-link'
      if (isDisabled) return 'button'
      return this.tag
    },
    iconSize() {
      if (this.size === 'medium') {
        return 20
      } else if (this.size === 'large') {
        return 24
      } else if (this.size === 'small') {
        return 16
      }
      return 18
    },
    computedPatternClass() {
      if (['secondary', 'text', 'none'].includes(this.type)) {
        return ''
      }

      return `is-pattern-${this.pattern}`
    },
    computedTypeClass() {
      if (['secondary', 'text', 'none'].includes(this.type)) {
        return ''
      }
      return `is-${this.type}`
    }
  },
  methods: {
    tailwindClasses() {
      const classes = []

      if (this.type === 'secondary') {
        classes.push('bg-primary-500 border-primary-500 text-white') // Base
        classes.push(
          'disabled:bg-primary-500 disabled:border-primary-500 disabled:text-white disabled:opacity-50 disabled:shadow-none'
        ) // Base
        classes.push(
          'hover:bg-primary-600 hover:border-primary-600 hover:text-white'
        ) // Hover
        classes.push(
          'focus:bg-primary-700 focus:border-primary-700 focus:text-white focus:!ring focus:ring-primary-500 focus:ring-opacity-20 [&.is-focused]:text-white'
        ) // Focus

        if (this.pattern === 'secondary') {
          classes.push('bg-primary-100 border-primary-100 text-primary-600')
          classes.push(
            'disabled:bg-primary-100 disabled:border-primary-100 disabled:text-primary-600'
          )
          classes.push(
            'hover:bg-primary-200 hover:border-primary-200 hover:text-primary-700'
          )
          classes.push(
            'focus:bg-primary-300 focus:border-primary-300 focus:text-primary-700 [&.is-focused]:text-primary-700'
          )
        } else if (this.pattern === 'tertiary') {
          classes.push('bg-transparent border-transparent text-primary-500')
          classes.push(
            'disabled:bg-transparent disabled:border-transparent disabled:text-primary-500'
          )
          classes.push(
            'hover:bg-primary-50 hover:border-primary-50 hover:text-primary-500'
          )
          classes.push(
            'focus:bg-primary-100 focus:border-primary-100 focus:text-primary-500 focus:bg-primary-100 [&.is-focused]:text-primary-500'
          )
        }
        if (this.outlined) {
          classes.push('bg-transparent text-primary-500')
          classes.push('disabled:bg-transparent disabled:text-primary-500')
        }
      } else if (this.type === 'text') {
        classes.push('bg-gray-800 border-gray-800 text-white') // Base
        classes.push(
          'disabled:bg-gray-800 disabled:border-gray-800 disabled:text-white disabled:opacity-50 disabled:shadow-none'
        ) // Base
        classes.push('hover:bg-gray-700 hover:border-gray-700 hover:text-white') // Hover
        classes.push(
          'focus:bg-gray-900 focus:border-gray-900 focus:text-white focus:!ring focus:ring-gray-800 focus:ring-opacity-20 [&.is-focused]:text-white'
        ) // Focus

        if (this.pattern === 'secondary') {
          classes.push('bg-gray-400 border-gray-400 text-white')
          classes.push(
            'disabled:bg-gray-400 disabled:border-gray-400 disabled:text-white'
          )
          classes.push(
            'hover:bg-gray-500 hover:border-gray-500 hover:text-white'
          )
          classes.push(
            'focus:bg-gray-310 focus:border-gray-310 focus:text-white [&.is-focused]:text-white'
          )
        } else if (this.pattern === 'tertiary') {
          classes.push('bg-transparent border-transparent text-gray-800')
          classes.push(
            'disabled:bg-transparent disabled:border-transparent disabled:text-gray-800'
          )
          classes.push(
            'hover:bg-gray-100 hover:border-gray-50 hover:text-gray-800'
          )
          classes.push(
            'focus:bg-gray-100 focus:border-gray-100 focus:text-gray-800 focus:bg-gray-100 [&.is-focused]:text-gray-800'
          )
        }
        if (this.outlined) {
          classes.push('bg-transparent text-gray-800')
          classes.push('disabled:bg-transparent disabled:text-gray-800')
        }
      } else if (this.type === 'none') {
        classes.push('bg-gray-500 border-gray-500 text-white') // Base
        classes.push(
          'disabled:bg-gray-500 disabled:border-gray-500 disabled:text-white disabled:opacity-50 disabled:shadow-none'
        ) // Base
        classes.push('hover:bg-gray-600 hover:border-gray-600 hover:text-white') // Hover
        classes.push(
          'focus:bg-gray-700 focus:border-gray-700 focus:text-white focus:!ring focus:ring-gray-500 focus:ring-opacity-20 [&.is-focused]:text-white'
        ) // Focus

        if (this.pattern === 'secondary') {
          classes.push('bg-gray-100 border-gray-100 text-gray-600')
          classes.push(
            'disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-600'
          )
          classes.push(
            'hover:bg-gray-200 hover:border-gray-200 hover:text-gray-700'
          )
          classes.push(
            'focus:bg-gray-300 focus:border-gray-300 focus:text-gray-700 [&.is-focused]:text-gray-700'
          )
        } else if (this.pattern === 'tertiary') {
          classes.push('bg-transparent border-transparent text-gray-500')
          classes.push(
            'disabled:bg-transparent disabled:border-transparent disabled:text-gray-500'
          )
          classes.push(
            'hover:bg-gray-50 hover:border-gray-50 hover:text-gray-500'
          )
          classes.push(
            'focus:bg-gray-100 focus:border-gray-100 focus:text-gray-500 focus:bg-gray-100 [&.is-focused]:text-gray-500'
          )
        }
        if (this.outlined) {
          classes.push('bg-transparent text-gray-500')
          classes.push('disabled:bg-transparent disabled:text-gray-500')
        }
      } else if (this.type === null) {
        classes.push('bg-white border-white text-gray-800') // Base
        classes.push(
          'disabled:bg-white disabled:border-white disabled:text-gray-800 disabled:opacity-50 disabled:shadow-none'
        ) // Base
        classes.push(
          'hover:bg-gray-100 hover:border-gray-100 hover:text-gray-800'
        ) // Hover
        classes.push(
          'focus:bg-white focus:border-white focus:text-gray-800 focus:!ring focus:ring-primary-500 focus:ring-opacity-20 [&.is-focused]:text-gray-800'
        ) // Focus
      }

      return twMerge(...classes)
    }
  }
}
</script>
<style lang="scss">
.icon-right-absolute {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 !important;
  height: 16px;
  opacity: 0.65;
}
</style>
