<script lang="ts">
import WithDimensions from '@cling/components/WithDimensions.vue'
import type { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist'
import { ref, useTemplateRef, watch, onMounted, onBeforeUnmount } from 'vue'

import type { Scale } from './types'
</script>

<script setup lang="ts">
let PDFFindController: any
let PDFLinkService: any
let PDFPageView: any
let EventBus: any

let pdfViewer: any

const emit = defineEmits<{
  pagerendered: [e: any]
  'link-clicked': [params: any]
  'update:scale': [newScale: Scale]
  error: [err: any]
}>()

const {
  pdf,
  page = 1,
  scale = 'page-width',
  rotate = 0,
  resize = true,
  annotation = true,
  text = true
} = defineProps<{
  pdf: PDFDocumentProxy | null
  page: number
  scale?: Scale
  rotate?: number
  resize?: boolean
  annotation?: boolean
  text?: boolean
  containerClass?: string | string[]
}>()

const loading = ref(true)
const container = useTemplateRef<HTMLElement>('container')

watch(
  () => scale,
  val => drawScaled(val)
)

watch(
  () => rotate,
  newRotate => {
    if (!pdfViewer) return
    pdfViewer.update({ scale, rotation: newRotate })
    pdfViewer.draw()
  }
)

onMounted(() => {
  PDFFindController = window.pdfjsViewer.PDFFindController
  PDFLinkService = window.pdfjsViewer.PDFLinkService
  PDFPageView = window.pdfjsViewer.PDFPageView
  EventBus = window.pdfjsViewer.EventBus

  init()
})

onBeforeUnmount(() => {
  if (pdfViewer) {
    pdfViewer.destroy()
    pdfViewer = null
  }
})

function init() {
  const eventBus = new EventBus()

  // TODO: might be redundant, consider removing
  eventBus.on('pagerendered', (e: any) => emit('pagerendered', e))

  const pdfLinkService = new PDFLinkService({
    eventBus: eventBus,
    externalLinkTarget: 2
  })

  const pdfFindController = new PDFFindController({
    eventBus: eventBus,
    linkService: pdfLinkService
  })

  pdf
    ?.getPage(page)
    .then((pdfPage: PDFPageProxy) => {
      pdfViewer = new PDFPageView({
        container: container.value,
        id: page,
        scale: 1,
        defaultViewport: pdfPage.getViewport({ scale: 1 }),
        eventBus,
        textLayerMode: text ? 1 : 0,
        annotationMode: annotation ? 1 : 0
      })
      pdfViewer.setPdfPage(pdfPage)
      const viewer = {
        scrollPageIntoView: (params: any) => emit('link-clicked', params)
      }
      pdfLinkService.setDocument(pdf)
      pdfLinkService.setViewer(viewer)
      pdfFindController.setDocument(pdf)
      return drawScaled(scale)
    })
    .catch((err: any) => {
      emit('error', err)
      loading.value = false
    })
}

function calculateScale(width = -1, height = -1) {
  pdfViewer.update({ scale: 1, rotation: rotate })
  if (width === -1 && height === -1) {
    width = container.value!.offsetWidth
  }
  return width / pdfViewer.viewport.width
}

function calculateScaleHeight() {
  pdfViewer.update({ scale: 1, rotation: rotate })
  const height = container.value!.offsetHeight
  const parentEl = container.value!.parentElement?.parentElement
  return parentEl ? parentEl.offsetHeight / height : 1
}

async function drawScaled(newScale: any) {
  if (!pdfViewer) return
  if (newScale === 'page-width') {
    newScale = calculateScale()
    emit('update:scale', newScale)
  } else if (newScale === 'page-height') {
    newScale = calculateScaleHeight()
    emit('update:scale', newScale)
  }

  pdfViewer.update({ scale: newScale, rotation: rotate })
  await pdfViewer.draw()

  loading.value = false
}

function resizeScale() {
  if (!resize) return
  drawScaled('page-width')
}
</script>

<template>
  <div>
    <slot v-if="loading" name="loading" />
    <div
      ref="container"
      :class="containerClass"
      class="pdfViewer tracking-normal"
    />

    <WithDimensions @resize="resizeScale">
      <div class="w-full" />
    </WithDimensions>
  </div>
</template>

<style>
.textLayer {
  position: absolute;
  text-align: initial;
  inset: 0;
  overflow: hidden;
  opacity: 0.25;
  line-height: 1;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
}

.textLayer :is(span, br) {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.textLayer span.markedContent {
  top: 0;
  height: 0;
}

.textLayer .highlight {
  --highlight-bg-color: rgb(180 0 170);
  --highlight-selected-bg-color: rgb(0 100 0);

  margin: -1px;
  padding: 1px;
  background-color: var(--highlight-bg-color);
  border-radius: 4px;
}

@media screen and (forced-colors: active) {
  .textLayer .highlight {
    --highlight-bg-color: Highlight;
    --highlight-selected-bg-color: ButtonText;
  }
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: var(--highlight-selected-bg-color);
}

.textLayer ::-moz-selection {
  background: blue;
  background: AccentColor;
}

.textLayer ::selection {
  background: blue;
  background: AccentColor;
}

.textLayer br::-moz-selection {
  background: transparent;
}

.textLayer br::selection {
  background: transparent;
}

.textLayer .endOfContent {
  display: block;
  position: absolute;
  inset: 100% 0 0;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.textLayer .endOfContent.active {
  top: 0;
}

.annotationLayer {
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;

  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
}

@media screen and (forced-colors: active) {
  .annotationLayer {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
    --hcm-highlight-filter: invert(100%);
  }

  .annotationLayer .textWidgetAnnotation :is(input, textarea):required,
  .annotationLayer .choiceWidgetAnnotation select:required,
  .annotationLayer
    .buttonWidgetAnnotation:is(.checkBox, .radioButton)
    input:required {
    outline: 1.5px solid selectedItem;
  }

  .annotationLayer .linkAnnotation {
    outline: var(--link-outline);
  }

  .annotationLayer .linkAnnotation:hover {
    -webkit-backdrop-filter: var(--hcm-highlight-filter);
    backdrop-filter: var(--hcm-highlight-filter);
  }

  .annotationLayer .linkAnnotation > a:hover {
    opacity: 0 !important;
    background: none !important;
    box-shadow: none;
  }

  .annotationLayer .popupAnnotation .popup {
    outline: calc(1.5px * var(--scale-factor)) solid CanvasText !important;
    background-color: ButtonFace !important;
    color: ButtonText !important;
  }

  .annotationLayer .highlightArea:hover::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: var(--hcm-highlight-filter);
    backdrop-filter: var(--hcm-highlight-filter);
    content: '';
    pointer-events: none;
  }

  .annotationLayer .popupAnnotation.focused .popup {
    outline: calc(3px * var(--scale-factor)) solid Highlight !important;
  }
}

.annotationLayer[data-main-rotation='90'] .norotate {
  transform: rotate(270deg) translateX(-100%);
}

.annotationLayer[data-main-rotation='180'] .norotate {
  transform: rotate(180deg) translate(-100%, -100%);
}

.annotationLayer[data-main-rotation='270'] .norotate {
  transform: rotate(90deg) translateY(-100%);
}

.annotationLayer canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.annotationLayer section {
  position: absolute;
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotationLayer
  :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton):not(.hasBorder)
  > a:hover {
  opacity: 0.2;
  background-color: rgb(255 255 0);
  box-shadow: 0 2px 10px rgb(255 255 0);
}

.annotationLayer .linkAnnotation.hasBorder:hover {
  background-color: rgb(255 255 0 / 0.2);
}

.annotationLayer .hasBorder {
  background-size: 100% 100%;
}

.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea),
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  margin: 0;
  vertical-align: top;
  width: 100%;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required,
.annotationLayer .choiceWidgetAnnotation select:required,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation [disabled]:is(input, textarea),
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input[disabled] {
  background: none;
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-image: none;
  background-color: transparent;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  background-color: CanvasText;
  content: '';
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  height: 80%;
  left: 45%;
  width: 1px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  border-radius: 50%;
  height: 50%;
  left: 25%;
  top: 25%;
  width: 50%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  height: 100%;
  width: 100%;
}

.annotationLayer .popupAnnotation {
  position: absolute;
  font-size: calc(9px * var(--scale-factor));
  pointer-events: none;
  width: -moz-max-content;
  width: max-content;
  max-width: 45%;
  height: auto;
}

.annotationLayer .popup {
  background-color: rgb(255 255 153);
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor))
    rgb(136 136 136);
  border-radius: calc(2px * var(--scale-factor));
  outline: 1.5px solid rgb(255 255 74);
  padding: calc(6px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
}

.annotationLayer .popupAnnotation.focused .popup {
  outline-width: 3px;
}

.annotationLayer .popup * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup > .header {
  display: inline-block;
}

.annotationLayer .popup > .header h1 {
  display: inline;
}

.annotationLayer .popup > .header .popupDate {
  display: inline-block;
  margin-left: calc(5px * var(--scale-factor));
  width: -moz-fit-content;
  width: fit-content;
}

.annotationLayer .popupContent {
  border-top: 1px solid rgb(51 51 51);
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popupTriggerArea {
  cursor: pointer;
}

.annotationLayer section svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .annotationTextContent {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

.annotationLayer svg.quadrilateralsContainer {
  contain: strict;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

:root,
:host {
  --viewer-container-height: 0;
  --pdfViewer-padding-bottom: 0;
  --page-margin: 1px auto -8px;
  --page-border: 9px solid transparent;
  --spreadHorizontalWrapped-margin-LR: -3.5px;
  --loading-icon-delay: 400ms;
}

@media screen and (forced-colors: active) {
  :root,
  :host {
    --pdfViewer-padding-bottom: 9px;
    --page-margin: 8px auto -1px;
    --page-border: 1px solid CanvasText;
    --spreadHorizontalWrapped-margin-LR: 3.5px;
  }
}

.pdfViewer {
  --scale-factor: 1;

  padding-bottom: var(--pdfViewer-padding-bottom);
}

.pdfViewer .canvasWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.pdfViewer .page {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: var(--page-margin);
  position: relative;
  overflow: visible;
  border: var(--page-border);
  background-clip: content-box;
  background-color: rgb(255 255 255);
}

.pdfViewer .dummyPage {
  position: relative;
  width: 0;
  height: var(--viewer-container-height);
}

.pdfViewer.noUserSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pdfViewer.removePageBorders .page {
  margin: 0 auto 10px;
  border: none;
}

.pdfViewer.singlePageView {
  display: inline-block;
}

.pdfViewer.singlePageView .page {
  margin: 0;
  border: none;
}

.pdfViewer:is(.scrollHorizontal, .scrollWrapped),
.spread {
  margin-inline: 3.5px;
  text-align: center;
}

.pdfViewer.scrollHorizontal,
.spread {
  white-space: nowrap;
}

.pdfViewer.removePageBorders,
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .spread {
  margin-inline: 0;
}

.spread :is(.page, .dummyPage),
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) :is(.page, .spread) {
  display: inline-block;
  vertical-align: middle;
}

.spread .page,
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .page {
  margin-inline: var(--spreadHorizontalWrapped-margin-LR);
}

.pdfViewer.removePageBorders .spread .page,
.pdfViewer.removePageBorders:is(.scrollHorizontal, .scrollWrapped) .page {
  margin-inline: 5px;
}

.pdfViewer .page canvas {
  margin: 0;
  display: block;
}

.pdfViewer .page canvas .structTree {
  contain: strict;
}

.pdfViewer .page canvas[hidden] {
  display: none;
}

.pdfViewer .page canvas[zooming] {
  width: 100%;
  height: 100%;
}

.pdfViewer .page.loadingIcon::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  /* background:url("images/loading-icon.gif") center no-repeat; */
  display: none;
  transition-property: display;
  transition-delay: var(--loading-icon-delay);
  z-index: 5;
  contain: strict;
}

.pdfViewer .page.loading::after {
  display: block;
}

.pdfViewer .page:not(.loading)::after {
  transition-property: none;
  display: none;
}
</style>
