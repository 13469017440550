<template>
  <div :class="{ 'is-expanded': expanded }" class="control">
    <CButton
      ref="label"
      tag="label"
      :type="newValue === nativeValue ? type : null"
      :size="size"
      :class="[{ 'is-disabled': disabled, 'is-focused': isFocused }]"
      :disabled="disabledOrUndefined"
      class="c-radio radio button"
      @click="focus"
      @keydown.prevent.enter="$refs.$el.label.click()"
    >
      <slot />
      <input
        ref="input"
        v-model="computedValue"
        :disabled="disabledOrUndefined"
        :required="requiredOrUndefined"
        :name="name"
        :value="nativeValue"
        type="radio"
        @click.stop
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </CButton>
  </div>
</template>

<script>
import CButton from '@cling/components/ui/Button'
import CheckRadioMixin from '@cling/components/ui/utils/CheckRadioMixin'

export default {
  name: 'CRadioButton',
  components: {
    CButton
  },
  mixins: [CheckRadioMixin],
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    expanded: Boolean
  },
  data() {
    return {
      isFocused: false
    }
  }
}
</script>

<style lang="scss">
@import '@cling/styles/theme/utilities/_all.sass';

$speed-slow: 200ms;
$easing: $easeOutBack;

$radio-size: 1.25em !default;

.c-radio {
  &.radio {
    @extend %unselectable;
    outline: none;
    display: inline-flex;
    align-items: center;
    &:not(.c-button) {
      margin-right: 0.5em;
      & + .radio:last-child {
        margin-right: 0;
      }
    }
    // reset Bulma
    & + .radio {
      margin-left: 0;
    }
    input[type='radio'] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
      + .check {
        display: flex;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
        width: $radio-size;
        height: $radio-size;
        transition: background $speed-slow $easing;
        border-radius: 50%;
        border: 2px solid $grey;
        &:before {
          @apply bg-primary-500;
          content: '';
          display: flex;
          position: absolute;
          left: 50%;
          margin-left: calc(-#{$radio-size}/ 2);
          bottom: 50%;
          margin-bottom: calc(-#{$radio-size}/ 2);
          width: $radio-size;
          height: $radio-size;
          transition: transform $speed-slow $easing;
          border-radius: 50%;
          transform: scale(0);
        }
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          &.is-#{$name}:before {
            background: $color;
          }
        }
      }
      &:checked + .check {
        @apply border-primary-500;
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          &.is-#{$name} {
            border-color: $color;
          }
        }
        &:before {
          transform: scale(0.5);
        }
      }
      &:focus {
        + .check {
          box-shadow: 0 0 0.5em rgba($grey, 0.8);
        }
        &:checked + .check {
          @apply ring-primary-500 ring-4 ring-opacity-80;
          @each $name, $pair in $colors {
            $color: nth($pair, 1);
            &.is-#{$name} {
              box-shadow: 0 0 0.5em rgba($color, 0.8);
            }
          }
        }
      }
    }
    .control-label {
      padding-left: $control-padding-horizontal;
    }
    &.c-button {
      display: flex;
      box-shadow: 0 0 1px $grey;
    }
    &[disabled] {
      opacity: 0.5;
    }
    &:hover {
      input[type='radio']:not(:disabled) + .check {
        @apply ring-primary-500;
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          &.is-#{$name} {
            border-color: $color;
          }
        }
      }
    }
    &.is-small {
      @include control-small;
    }
    &.is-medium {
      @include control-medium;
    }
    &.is-large {
      @include control-large;
    }
  }
}

// Quick-fix

.c-radio.radio input[type='radio'] + .check.is-primary:before,
.c-radio.radio input[type='radio'] + .check.is-secondary:before {
  @apply bg-primary-500;
}

.c-radio.radio input[type='radio']:checked + .check.is-primary,
.c-radio.radio input[type='radio']:checked + .check.is-secondary {
  @apply border-primary-500;
}

.c-radio.radio input[type='radio']:focus:checked + .check.is-primary,
.c-radio.radio input[type='radio']:focus:checked + .check.is-secondary {
  @apply ring-primary-500 ring-2;
  // box-shadow: 0 0 0.5em rgba(0, 149, 255, 0.8);
}

.c-radio.radio:hover input[type='radio']:not(:disabled) + .check.is-primary,
.c-radio.radio:hover input[type='radio']:not(:disabled) + .check.is-secondary {
  @apply border-primary-500;
}
</style>
