<template>
  <component
    :is="tag"
    ref="container"
    :class="$props.class"
    :style="$props.style"
  >
    <slot
      v-for="(item, index) in items"
      :key="item[itemKey] || index"
      :item="item"
      :index="index"
      name="item"
    />
  </component>
</template>

<script setup lang="ts">
import {
  useSortable,
  UseSortableOptions
} from '@vueuse/integrations/useSortable'
import { computed, useTemplateRef } from 'vue'

import { moveArrayElement } from './utils'

type Props = {
  tag?: string
  itemKey?: string
  modelValue?: any[] | null
  list?: any[]
  options?: UseSortableOptions
  clone?: (item: unknown) => unknown
}

type Emits = {
  (event: 'update:modelValue', payload: any[]): void
}

const {
  tag = 'div',
  itemKey = 'id',
  modelValue = null,
  list = [],
  options = null,
  clone = original => original
} = defineProps<Props>()

const emit = defineEmits<Emits>()

const items = computed(() => modelValue || list)

const container = useTemplateRef<HTMLElement>('container')

useSortable(container, items, {
  animation: 250,
  onChoose(e) {
    // Store the underlying context/data programmatically
    const { item } = e
    const index = e.oldIndex
    item._data = clone(items.value[index])
  },
  ...(modelValue && {
    onUpdate(e) {
      const { oldIndex, newIndex } = e
      const newItems = moveArrayElement(modelValue, oldIndex, newIndex)
      emit('update:modelValue', newItems)
    }
  }),
  ...options
})
</script>
