import logger from '@cling/services/logger'
import languages from '@cling/static/languages' // Supported languages

import i18next from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'

import l10n from './l10n'
import { en, sv } from './locales' // Keys + Messages
import { loadOptions } from './utils'
import VueI18Next from './vueI18next'

export const LanguageDetector = LngDetector

export const withCount = {
  type: 'postProcessor',
  name: 'withCount',
  process: (value, key, options, translator) => {
    const { count } = options || {}
    return typeof count === 'number' ? `${count} ${value}` : value
  }
}

export const capitalize = {
  type: 'postProcessor',
  name: 'capitalize',
  process: s =>
    typeof s !== 'string' ? s : s.charAt(0).toUpperCase() + s.slice(1)
}

// Default init options
// Note that we need to pass the instance to the options, as we have methods using the instance for translation
export const initOptions = i18nInstance => ({
  debug: false,
  supportedLngs: languages,
  resources: { sv, en },
  fallbackLng: 'en',
  saveMissing: true, // Needed for missingKeyHandler to be used
  missingKeyHandler(lng, ns, key, fallbackValue) {
    logger.info(
      `i18next: Missing translation lng '${lng}', ns '${ns}', 'key: '${key}'`
    )
  },
  // parseMissingKeyHandler: function () {
  //   return 'Invalid i18n Key';
  // },
  // Adds fallback to _common namespace on returned objects
  // Lets group keys in namespaces overlap with _common keys
  returnedObjectHandler(key, value, options) {
    // Let arrays pass through as default
    if (Array.isArray(value)) return value
    // If we are already checking common, return value to avoid infinite loop
    if (options && options.ns === '_common') return value
    // Check if value exists in common, otherwise return value
    return i18nInstance.t(key, { ns: '_common' }) || value
  },
  interpolation: {
    skipOnVariables: false // default true after >= v21.0.0
  }
})

export function addCustomFormatting(i18nInstance) {
  i18nInstance.services.formatter.add('uppercase', value => value.toUpperCase())
  i18nInstance.services.formatter.add('lowercaseSpace', value =>
    value ? ` ${value}`.toLowerCase() : value
  )
  i18nInstance.services.formatter.add('inlineSpace', value =>
    value ? ` ${value}` : value
  )
  i18nInstance.services.formatter.add('spaceAfter', value =>
    value ? `${value} ` : value
  )
  i18nInstance.services.formatter.add('capitalize', value =>
    typeof value !== 'string'
      ? value
      : value.charAt(0).toUpperCase() + value.slice(1)
  )
}

// Internationalization (i18n)
const defaultInstance = i18next.createInstance()
defaultInstance
  .use(LanguageDetector)
  .use(capitalize)
  .use(withCount)
  .init({
    ...initOptions(defaultInstance),
    namespaces: [
      '_common',
      'views',
      'components',
      'errors',
      'actions',
      'router',
      'iso'
    ],
    defaultNS: '_common',
    fallbackNS: '_common'
  })
  .then(() => addCustomFormatting(defaultInstance))

// Localization (l10n)
l10n.init({ fallbackLocale: 'en-US' })

export const l10nInstance = l10n

const i18n = new VueI18Next(defaultInstance, l10n)

i18n.loadOptions = loadOptions.bind(i18n)

// Exort general UI language instance
export default i18n
