<template>
  <div :class="[rootClasses, fieldType()]" class="field">
    <div
      v-if="horizontal"
      :class="[customClass, fieldLabelSize]"
      class="field-label"
    >
      <label
        v-if="hasLabel"
        :for="labelFor"
        :class="customClass"
        class="label truncate-text"
      >
        <slot v-if="$slots.label" name="label" />
        <template v-else>{{ label }}</template>
      </label>
    </div>
    <template v-else>
      <label
        v-if="hasLabel"
        :for="labelFor"
        :class="customClass"
        class="label truncate-text"
      >
        <slot v-if="$slots.label" name="label" />
        <template v-else>{{ label }}</template>
      </label>
    </template>
    <CFieldBody
      v-if="horizontal"
      :message="newMessage ? formattedMessage : ''"
      :type="newType"
    >
      <slot />
    </CFieldBody>
    <template v-else>
      <slot />
    </template>
    <p
      v-if="newMessage && !horizontal"
      :class="newType"
      class="help"
      v-html="formattedMessage"
    />
  </div>
</template>

<script>
import { isTag, isFragment } from '@cling/components/ui/utils/helpers'

import CFieldBody from './CFieldBody.vue'

export default {
  name: 'CField',
  components: {
    CFieldBody
  },
  provide() {
    return {
      CField: this
    }
  },
  props: {
    type: {
      type: [String, Object],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    labelFor: {
      type: String,
      default: ''
    },
    message: {
      type: [String, Array, Object],
      default: ''
    },
    grouped: Boolean,
    groupMultiline: Boolean,
    position: {
      type: String,
      default: ''
    },
    expanded: Boolean,
    horizontal: Boolean,
    addons: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      default: () => 'top' // config.defaultFieldLabelPosition,
    }
  },
  data() {
    return {
      newType: this.type,
      newMessage: this.message,
      fieldLabelSize: null,
      numberInputClasses: [],
      _isField: true // Used internally by Input and Select
    }
  },
  computed: {
    rootClasses() {
      return [
        this.newPosition,
        {
          'is-expanded': this.expanded,
          'is-grouped-multiline': this.groupMultiline,
          'is-horizontal': this.horizontal,
          'is-floating-in-label':
            this.hasLabel &&
            !this.horizontal &&
            this.labelPosition === 'inside',
          'is-floating-label':
            this.hasLabel &&
            !this.horizontal &&
            this.labelPosition === 'on-border'
        },
        this.numberInputClasses
      ]
    },
    /**
     * Correct Bulma class for the side of the addon or group.
     *
     * This is not kept like the others (is-small, etc.),
     * because since 'has-addons' is set automatically it
     * doesn't make sense to teach users what addons are exactly.
     */

    newPosition() {
      if (this.position === undefined) return
      const position = this.position.split('-')
      if (position.length < 1) return
      const prefix = this.grouped ? 'is-grouped-' : 'has-addons-'
      if (this.position) return prefix + position[1]
      return undefined
    },
    /**
     * Formatted message in case it's an array
     * (each element is separated by <br> tag)
     */
    formattedMessage() {
      if (typeof this.newMessage === 'string') {
        return this.newMessage
      }
      const messages = []
      if (Array.isArray(this.newMessage)) {
        this.newMessage.forEach(message => {
          if (typeof message === 'string') {
            messages.push(message)
          } else {
            for (const key in message) {
              if (message[key]) {
                messages.push(key)
              }
            }
          }
        })
      } else {
        for (const key in this.newMessage) {
          if (this.newMessage[key]) {
            messages.push(key)
          }
        }
      }
      return messages.filter(m => !!m).join(' <br> ')
    },
    hasLabel() {
      return this.label || this.$slots.label
    }
  },
  watch: {
    /**
     * Set internal type when prop change.
     */
    type(value) {
      this.newType = value
    },
    /**
     * Set internal message when prop change.
     */
    message(value) {
      // we deep comparison here becase an innner Field of another Field
      // receives the message as a brand new array every time, so simple
      // identity check won't work and will end up with infinite
      // recursions
      // https://github.com/buefy/buefy/issues/4018#issuecomment-1985026234
      if (JSON.stringify(value) !== JSON.stringify(this.newMessage)) {
        this.newMessage = value
      }
    }
  },
  mounted() {
    if (this.horizontal) {
      // Bulma docs: .is-normal for any .input or .button
      const elements = this.$el.querySelectorAll(
        '.input, .select, .button, .textarea, .c-slider'
      )
      if (elements.length > 0) {
        this.fieldLabelSize = 'is-normal'
      }
    }
  },
  methods: {
    /**
     * Field has addons if there are more than one slot
     * (element / component) in the Field.
     * Or is grouped when prop is set.
     * Is a method to be called when component re-render.
     */
    fieldType() {
      if (this.grouped) return 'is-grouped'
      if (this.hasAddons()) return 'has-addons'
    },
    getSlotNodes(nodes) {
      // If the node is a Fragment, recursively extract its children
      return nodes.flatMap(node =>
        isFragment(node) ? this.getSlotNodes(node.children) : [node]
      )
    },
    hasAddons() {
      let renderedNode = 0
      if (this.$slots.default) {
        renderedNode = this.getSlotNodes(this.$slots.default()).reduce(
          (i, node) => (isTag(node) ? i + 1 : i),
          0
        )
      }
      return renderedNode > 1 && this.addons && !this.horizontal
    },
    // called by a number input if it is a direct child.
    wrapNumberinput({ controlsPosition, size }) {
      const classes = ['has-numberinput']
      if (controlsPosition) {
        classes.push(`has-numberinput-${controlsPosition}`)
      }
      if (size) {
        classes.push(`has-numberinput-${size}`)
      }
      this.numberInputClasses = classes
    }
  }
}
</script>
<style lang="scss">
@import '@cling/styles/theme/utilities/_all.sass';
@import '@cling/styles/theme/form/shared.sass';

$floating-in-height: 3.25em !default;

.field {
  &.is-grouped {
    .field {
      flex-shrink: 0;
      &:not(:last-child) {
        margin-right: calc(0.75 * var(--rem));
      }
      &.is-expanded {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }
  // Nested control addons (for Autocomplete and Datepicker)
  &.has-addons .control {
    &:first-child .control {
      .button,
      .input,
      .select select {
        border-bottom-left-radius: $input-radius;
        border-top-left-radius: $input-radius;
      }
    }
    &:last-child .control {
      .button,
      .input,
      .select select {
        border-bottom-right-radius: $input-radius;
        border-top-right-radius: $input-radius;
      }
    }
    .control {
      .button,
      .input,
      .select select {
        border-radius: 0;
      }
    }
  }
  // Fix for number input with addons
  &.has-addons {
    .b-numberinput {
      &:not(:first-child) {
        .control:first-child {
          .button,
          .input,
          .select select {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }
      &:not(:last-child) {
        .control:last-child {
          .button,
          .input,
          .select select {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
}

.field {
  &.is-floating-label,
  &.is-floating-in-label {
    position: relative;

    .label {
      @apply left-3 top-2 text-xs;
      pointer-events: none;
      position: absolute;
      background-color: transparent;
      z-index: 5;
      max-width: 90%;
      &.is-small {
        @apply text-xs;
      }
      &.is-medium {
        @apply text-xs;
      }
      &.is-large {
        @apply text-sm;
      }
    }
    .taginput .counter {
      float: none;
      text-align: right;
    }

    &.has-addons {
      > .label {
        + .control {
          .button,
          .input,
          .select select {
            border-bottom-left-radius: $input-radius;
            border-top-left-radius: $input-radius;
          }
        }
      }
    }
  }

  &.is-floating-label {
    .label {
      top: -0.775em;
      padding-left: 0.125em;
      padding-right: 0.125em;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0.775em;
        left: 0;
        right: 0;
        height: 0.375em;
        background-color: $input-background-color;
        z-index: -1;
      }
    }
    .input,
    .textarea,
    .select select {
      &:focus {
        box-shadow: none;
      }
    }
    .taginput .taginput-container {
      padding-top: 0.475em;
      &.is-focused {
        box-shadow: none;
      }
    }
  }
  &.is-floating-in-label {
    > .datepicker,
    > .timepicker {
      .input {
        padding-top: calc(
          #{$floating-in-height} / 2 - (#{$size-large} * 3 / 4) / 2
        );
        padding-bottom: 1px;
        height: $floating-in-height;
      }
    }
    > :not(.datepicker):not(.timepicker):not(.taginput) {
      .input,
      .textarea,
      select {
        padding-top: calc(
          #{$floating-in-height} / 2 - (#{$size-large} * 3 / 4) / 2
        );
        padding-bottom: 1px;
        height: $floating-in-height;
      }
      .select:not(multiple) {
        height: $floating-in-height;
        &.is-loading {
          &::after {
            margin-top: calc(
              #{$floating-in-height} / 2 - (#{$size-large} * 3 / 4) / 2
            );
          }
        }
        &::after {
          margin-top: 1px;
        }
      }
    }
    > :not(.taginput) {
      .is-left.icon,
      .is-right.icon {
        height: $floating-in-height;
      }
      .is-left.icon {
        padding-top: calc(
          #{$floating-in-height} / 2 - (#{$size-large} * 3 / 4) / 2
        );
      }
    }
    .control.is-loading::after {
      margin-bottom: calc(
        #{$floating-in-height} / 2 - (#{$size-large} * 3 / 4) / 2
      );
    }
    .taginput .taginput-container {
      // 0.275em - 1px _taginput.scss
      padding-top: calc(
        #{$floating-in-height} / 2 - (#{$size-large} * 3 / 4) / 2 + (0.275em -
              1px)
      );
    }

    &.has-numberinput {
      .b-numberinput {
        .control {
          .button {
            height: $floating-in-height;
          }
        }
      }
    }

    &.has-addons,
    &.is-grouped {
      .control {
        .button,
        .input,
        .select select {
          height: $floating-in-height;
        }
      }
    }
  }
  &.is-floating-label,
  &.is-floating-in-label {
    &.has-numberinput {
      .label {
        margin-left: calc(#{$size-normal} * 3);
      }
      &.has-numberinput-is-small {
        .label {
          margin-left: calc(#{$size-small} * 3);
        }
      }
      &.has-numberinput-is-medium {
        .label {
          margin-left: calc(#{$size-medium} * 3);
        }
      }
      &.has-numberinput-is-large {
        .label {
          margin-left: calc(#{$size-large} * 3);
        }
      }
    }
    &.has-numberinput-compact {
      .label {
        margin-left: calc(#{$size-normal} * 2.25);
      }
      &.has-numberinput-is-small {
        .label {
          margin-left: calc(#{$size-small} * 2.25);
        }
      }
      &.has-numberinput-is-medium {
        .label {
          margin-left: calc(#{$size-medium} * 2.25);
        }
      }
      &.has-numberinput-is-large {
        .label {
          margin-left: calc(#{$size-large} * 2.25);
        }
      }
    }
  }
}

.control {
  .help.counter {
    float: right;
    margin-left: 0.5em;
  }
  .icon {
    &.is-clickable {
      pointer-events: auto;
      cursor: pointer;
    }
  }
}
</style>
