<template>
  <div v-if="visibleModals.length" class="modal-root-overlay">
    <div class="stack-list" @click.self="onClose">
      <template v-for="(modal, i) in visibleModals">
        <component
          v-bind="modal.params"
          :is="components[modal.name]"
          v-if="modal.name"
          :key="i"
          :is-modal="true"
          :style="getStyle(i)"
          :class="[{ 'is-behind': visibleModals.length - 1 !== i }]"
          class="stack-item"
          @close="onClose"
          @id-change="
            $event =>
              i === visibleModals.length - 1 ? onIdChange($event, modal) : null
          "
        />
        <!-- idChange events are ONLY allowed from the last modal -->
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { Modal, useApplicationModalsStore } from '@/stores/applicationModals'

const components = {
  project: defineAsyncComponent(
    () => import('@/views/account/project/Project.vue')
  ),
  document: defineAsyncComponent(
    () => import('@/views/account/document/Document.vue')
  )
}

const router = useRouter()
const route = useRoute()

const applicationModals = useApplicationModalsStore()

const visibleModals = computed(() => applicationModals.modals.slice(-2))

watch(
  () => applicationModals.modals,
  async (v, oldV) => {
    if (!v.length) return
    const { href } = router.resolve(v[v.length - 1])

    if (v.length === oldV.length) {
      window.history.replaceState(window.history.state, '', href)
    } else {
      window.history.pushState({}, '', href)
    }
  }
)

const onClose = async () => {
  if (applicationModals.canCloseModal) {
    await applicationModals.popModal()
    window.history.replaceState(window.history.state, '', route.fullPath)
  }
}

const onIdChange = async (newId: string, modal: Modal) => {
  const newModal = { ...modal, params: { ...modal.params, id: newId } }
  const { href: newHref } = router.resolve(newModal)

  const newModals = [...applicationModals.modals.slice(0, -1), newModal]
  applicationModals.setModals(newModals)

  window.history.replaceState({}, '', newHref)
}

const getStyle = (i: number) => {
  const length = visibleModals.value.length
  const index = i + 1 // Begins with count 1
  const style = { zIndex: 1000 + index }

  if (length === index) return style

  return {
    ...style,
    opacity: 0.75,
    transform: `translate3d(0%, 0%, ${-index * 10}px)`
  }
}
</script>

<style lang="scss" scoped>
.modal-root-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.stack-list {
  perspective: 100px;
  perspective-origin: 50% -10%;
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100vw 1fr;
  grid-template-rows: 100vh 1fr;
}

.stack-item {
  /* contain: layout; Disabled intentionally - Causing render issues and drag drop not working properly with it */
  margin: auto;
  backface-visibility: hidden;
  opacity: 1;
  transition:
    transform 200ms ease,
    opacity 200ms;
  grid-area: 1 / 1 / 2 / 2;
  &.is-behind :deep(*) {
    opacity: 0;
  }
}
</style>
