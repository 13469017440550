<template>
  <modal
    :adaptive="true"
    :scrollable="true"
    :max-width="340"
    height="auto"
    width="90%"
    classes="primary overflow-visible"
    class="font-inter overflow-visible"
    :name="name"
    @before-open="onBeforeOpen"
  >
    <div class="feedback-modal">
      <div v-if="view === 'form'">
        <div>
          <div class="feedback-title" v-text="$t('feel.title')" />
          <div class="feedback-subtitle" v-text="$t('feel.subTitle')" />
        </div>
        <div
          style="
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 8px;
            margin-bottom: 3rem;
          "
        >
          <CButton
            v-for="(feel, i) in [
              '🤬',
              '😠',
              '😑',
              '😎',
              '🤩',
              '🤘',
              '🔥',
              '🥳',
              '💩',
              '🥰'
            ]"
            :key="i"
            :type="emojis.includes(feel) ? 'secondary' : null"
            pattern="secondary"
            style="
              padding: 10px 10px 10px 8px;
              font-size: 16px;
              height: initial;
            "
            @click="
              emojis.includes(feel)
                ? emojis.splice(emojis.indexOf(feel), 1)
                : emojis.push(feel)
            "
            v-text="feel"
          />
        </div>
        <div style="margin-bottom: 2rem">
          <div class="feedback-title" v-text="$t('text.title')" />
          <div class="feedback-subtitle" v-text="$t('text.subTitle')" />
          <CInput
            v-model="text"
            type="textarea"
            filled
            :placeholder="$t('text.placeholder')"
          />
        </div>
        <CButton
          type="secondary"
          wide
          :disabled="!(text || emojis.length)"
          :loading="isLoading"
          style="
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
          "
          @click="sendFeedback"
          v-text="$t('action')"
        />
      </div>
      <div v-else-if="view === 'success'" class="text-center">
        <div class="feedback-title" v-text="$t('success.title')" />
        <div
          class="feedback-subtitle"
          style="font-size: 14px; margin-bottom: 2rem; margin-top: 0.5rem"
          v-text="$t('success.subTitle')"
        />
        <CButton
          style="
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
          "
          type="secondary"
          wide
          @click="() => $modal.hide(name)"
          v-text="$t('success.action')"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { slackRoutes } from '@cling/api'
import { wait } from '@cling/utils'

export default {
  props: {
    name: {
      type: String,
      default: 'feedback'
    }
  },
  i18nOptions: {
    namespaces: 'feedback',
    messages: {
      sv: {
        feel: {
          title: 'Vad tycker du om $t(brand.name)?',
          subTitle: 'Välj så många du vill.'
        },
        text: {
          title: 'Något vi kan förbättra?',
          text: 'Allt är till hjälp.',
          placeholder: 'Vad gillar du eller inte gillar? Saknar vi något?'
        },
        action: 'Skicka feedback',
        success: {
          title: 'Tack!',
          subTitle:
            'Vi uppskattar att du tagit dig tiden att dela med dig av din feedback. Vi ser till att feedback:en kommer fram till rätt person.',
          action: 'Stäng'
        }
      },
      en: {
        feel: {
          title: 'How do you feel about $t(brand.name)?',
          subTitle: "Pick as many as you'd like."
        },
        text: {
          title: 'Anything we could improve?',
          subTitle: 'Every bit helps.',
          placeholder: 'What did you like or dislike? Are we missing something?'
        },
        action: 'Send feedback',
        success: {
          title: 'Thank you!',
          subTitle:
            'Our team will review your feedback with great care and try to implement the necessary changes required.',
          action: 'Close'
        }
      }
    }
  },
  data() {
    return {
      emojis: [],
      text: '',
      view: 'form',
      isLoading: false
    }
  },
  methods: {
    async sendFeedback() {
      this.isLoading = true

      const text = `Feedback: ${this.emojis.join(' ')} ${this.text}`
      await slackRoutes.feedback({ text })
      await wait(500)
      this.view = 'success'
      this.isLoading = false
    },
    onBeforeOpen() {
      // Reset the form if they have previously submitted
      if (this.view !== 'success') return
      this.view = 'form'
      this.emojis = []
      this.text = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback {
  &-modal {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    font-size: 14px;
  }
  &-title {
    font-size: 16px;
    font-weight: 600;
  }
  &-subtitle {
    color: hsl(0, 0%, 45%);
    font-size: 13px;
    margin-bottom: 1rem;
  }
}
</style>
