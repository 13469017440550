import config from '@cling/config'

import { reactive } from 'vue'

const getSubdomain = () => {
  const strings = window.location.host.split('.')
  return strings && strings[0]
}

export default {
  install(app, { store } = {}) {
    const themeObj = reactive({ theme: config.brand })

    if (config.brand.includes('fortnox')) {
      themeObj.theme = 'fortnox'
    }

    if (getSubdomain() === 'officedepot') {
      themeObj.theme = 'officedepot'
    }

    // Make available i store
    store.$theme = themeObj.theme // Note: Is set if changed below

    app.mixin({
      computed: {
        $theme: {
          get: () => themeObj.theme,
          set: theme => {
            themeObj.theme = theme
            store.$theme = theme // Update store property as well
          }
        }
      }
    })
  }
}
