<template>
  <div
    :class="{ 'full-screen': isFullScreen }"
    class="font-inter modal-overlay"
    @click.passive.self="$emit('close')"
  >
    <div :style="!isFullScreen && { padding: '2rem' }" class="modal-container">
      <CButton
        v-if="isFullScreen"
        class="modal-close"
        pattern="tertiary"
        type="none"
        circle
        icon="x"
        @click="$emit('close')"
      />
      <DocumentPrint
        :document="document"
        :is-super-user="isSuperUser()"
        @close="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import DocumentPrint from '@cling/components/document/components/DocumentPrint.vue'
import windowSize from '@cling/mixins/windowSize.js'
import webStorage from '@cling/utils/webStorage'

import { mapGetters } from 'vuex'

export default {
  i18nOptions: {
    namespaces: 'components',
    keyPrefix: 'document.printModal'
  },
  name: 'DocumentPrintModal',
  components: {
    DocumentPrint
  },
  emits: ['close'],
  mixins: [windowSize],
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        appendAttachments: true,
        hideDocumentAttachmentBlocks: false,
        showVerification: true,
        showEvents: true,
        showLast4ssn: true,
        forceDoCreatePdf: false, // Only for superuser
        debugPage: false, // Only for superuser
        extraTimeout: 0 // Only for superuser
      }
    }
  },
  computed: {
    ...mapGetters({
      isSuperUser: 'application/isSuperUser'
    }),
    isFullScreen() {
      return this.mq === 'sm'
    },
    allowVerification() {
      return this.document && this.document.allowVerification
    },
    hasBankIdSignatures() {
      const { clients, senderClient } = this.document
      if (
        senderClient?.socialNo &&
        senderClient.answerMethod?.accept === 'bankId'
      )
        return true

      return clients.some(
        client => client.socialNo && client.answerMethod?.accept === 'bankId'
      )
    }
  },
  created() {
    Object.assign(
      this.options,
      JSON.parse(webStorage.getItem('print-settings'))
    )

    if (!this.allowVerification) this.options.showVerification = false

    this.$watch('options', {
      deep: true,
      handler(v) {
        // Store latest changes
        webStorage.setItem('print-settings', JSON.stringify(v))

        // Return suboptions to their defaults if parent gets disabled
        if (!v.showVerification && !v.showEvents) this.options.showEvents = true
        if (!v.showVerification && !v.showLast4ssn) {
          this.options.showLast4ssn = true
        }
      }
    })
  },
  methods: {
    onGeneratePdfDone() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.modal-overlay {
  background-color: rgba($black, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  &-container {
    margin: 0 auto;
    background-color: $white;
    border-radius: 14px;
    position: relative;
    font-size: 16px;
    max-width: 90%;
    width: 380px;
    .full-screen & {
      width: 100%;
      height: 100%;
      display: block;
      justify-content: center;
      border-radius: 0;

      padding: 4em 1em 2em 1em;
      overflow: auto;
    }
  }
  &-close {
    position: absolute;
    z-index: 2;
    top: 1em;
    right: 1em;
  }
}
.full-screen {
  height: 100%;
  overflow: auto;
}
</style>
