import config from '@cling/config'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  sign: 'signer',
  accept: 'accepter',
  address: 'adresse',
  and: 'et',
  answerFeedback: {
    intermediate: {
      title: "Merci d'avoir partager votre avis."
    },
    no: {
      start: {
        action: 'Envoyer',
        placeholder: 'Que devrions-nous améliorer ?',
        text: 'Y a-t-il quelque chose de spécifique que vous aimeriez partager et que nous pouvons améliorer ?',
        title: "Merci d'avoir partagé."
      },
      success: {
        action: 'En savoir plus sur $t(brand.name)',
        secondary: 'Retour au document',
        text: 'Votre commentaire a été envoyé avec succès. Notre équipe examinera attentivement votre commentaire.',
        title: 'Merci.'
      }
    },
    start: {
      no: 'Non',
      title:
        'Avez-vous aimé utiliser $t(brand.name) pour visualiser ce document ?',
      yes: 'Oui'
    },
    yes: {
      action: 'Inscrivez-vous gratuitement',
      secondary: 'En savoir plus sur $t(brand.name)',
      text: "Créez et envoyez des documents attrayants avec $t(brand.name). Découvrez tous les avantages de l'envoi de documents enregistrés sur le web avec des signatures électroniques.",
      title: 'Commencez à utiliser $t(brand.name) gratuitement.'
    },
    custom: {
      title: 'Merci.',
      text: 'Votre commentaire a été envoyé avec succès. Notre équipe examinera attentivement votre commentaire.',
      action: 'Retour au document'
    }
  },
  answerModal: {
    answerSuccess: 'Votre réponse a été envoyée avec succès.',
    bankId: {
      component: {
        defaultError:
          'Il y a eu un problème. Veuillez vous assurer que vous avez correctement saisi votre numéro de sécurité sociale et réessayez.',
        signingInterrupted: 'Signature interrompue, réessayez...',
        startOnThisDevice: 'Démarrer BankID sur cet appareil',
        validations: {
          socialNo: {
            personalNumber: 'Numéro de sécurité sociale invalide',
            required: 'Saisissez votre numéro de sécurité sociale'
          }
        },
        viewText: {
          canceled: {
            text: 'La signature a été annulée. Veuillez réessayer',
            title: 'Signature interrompue'
          },
          error: {
            text: 'Quelque chose a mal tourné. Veuillez réessayer.',
            title: "Une erreur s'est produite"
          },
          form: {
            text: "Saisissez votre numéro de sécurité sociale pour commencer à signer l'accord.",
            title: 'Signer le document avec BankID'
          },
          new: {
            spinnerText: 'Scannez le code QR sur votre appareil.',
            text: "Ouvrez BankID sur votre appareil et signez l'accord.",
            title: 'Signer le document'
          },
          signing: {
            spinnerText: 'Signer le document.',
            text: "Ouvrez BankID sur votre appareil et signez l'accord.",
            title: 'Signature en cours'
          },
          waiting: {
            spinnerText: "En attente de l'ouverture de BankID.",
            text: "Ouvrez BankID sur votre appareil et signez l'accord.",
            title: 'Signer le document'
          }
        }
      },
      start: {
        action: 'Signer avec BankID',
        text: 'Aceptar y firmar el documento con BankID.',
        title: 'Signer avec BankID'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    decline: {
      component: {
        label: 'Commentaire (facultatif)'
      },
      start: {
        action: 'Rejeter',
        text: 'Pour rejeter ce document, confirmez en cliquant sur le bouton ci-dessous.',
        title: 'Rejeter le document'
      },
      success: {
        text: 'Merci de laisser une réponse.',
        textFallback: '$t(answerModal.decline.success.text)',
        title: 'Vous avez rejeté le document.'
      }
    },
    signature: {
      component: {
        draw: {
          canvasLabel: 'Dessiner la signature',
          tab: 'Dessiner'
        },
        subTitle: 'Choisissez la méthode de signature',
        terms:
          "En signant le document, j'accepte que la signature électronique utilisée soit une représentation de ma signature manuscrite et ait la même validité.",
        text: {
          canvasLabel: 'Prévisualisation',
          nameLabel: 'Signé par',
          namePlaceholder: 'Saisissez votre nom',
          tab: 'Saisir'
        }
      },
      start: {
        action: 'Signer et accepter',
        text: '',
        title: 'Accepter le document'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    versionError: {
      accept: { title: 'Impossible de signer le document' },
      decline: { title: 'Impossible de refuser le document' },
      text: 'Le document a été modifié pendant que vous le regardiez. Veuillez recharger et examiner le nouveau document afin de répondre.',
      btn: 'Recharger'
    },
    successText:
      'Une fois que le document a été signé par toutes les parties concernées, vous recevrez un courriel à {{email}} avec une copie tamponnée du document.',
    successTextFallback:
      'Une fois que le document a été signé par toutes les parties concernées, vous pouvez revenir sur ce site web pour télécharger une copie tamponnée et complétée du document.',
    successTextFallbackSingle:
      'Le document a maintenant été signé et complété.',
    successTextSingle:
      'Un courriel de confirmation a été envoyé à {{email}} avec une copie tamponnée du document.',
    successTitle: 'Vous avez signé le document.'
  },
  approved: 'approuvé',
  approx: 'environ.',
  cancel: 'Annuler',
  chapters: {
    answerButtons: {
      approveDescription:
        'Nous avons informé {{- sender}} que le document est approuvé.',
      approveTitle: 'Le document est approuvé!',
      denyDescription:
        "Nous avons informé {{- sender}} que vous n'êtes pas intéressé.",
      denyTitle: 'Le document est rejeté',
      expiredTitle: 'Le document a expiré',
      expiredDescription:
        "La date d'expiration du document est passée. Il n'est plus possible de signer le document.",
      editPropDes: 'Modifier la désignation de la propriété',
      enterPropDes: 'Entrer la désignation de la propriété',
      inPersonSignatureHint:
        'Vous avez été programmé pour signer le document en personne. Si vous souhaitez plutôt signer la copie numérique, veuillez contacter',
      waitingDescription:
        "Attendre que l'autre {{remaining}} laisse une réponse.",
      youHave: 'Vous avez'
    },
    articleItem: {
      eligibleForDeduction: 'Éligible à {{type}}-déduction'
    },
    articleList: {
      changeShowVatTooltip: 'Basculer pour afficher {{type}}',
      title: 'Services / Produits'
    },
    packageGroup: {
      errorCallout: 'Sélectionner au moins une option',
      selectableOptions: 'Options disponibles'
    },
    packageItem: {
      add: 'Ajouter une option',
      select: 'Sélectionner une option',
      selected: 'Sélectionné'
    },
    parties: {
      allRiskAndLiability:
        "Dispose d'une assurance tous risques et responsabilité civile",
      fTax: 'Certifié pour la taxe F'
    },
    pricingSummary: {
      blocks: {
        approx: 'Prix approximatif',
        discount: 'Rabais',
        deductionSubValue: 'Prix total sans déduction {{price}}',
        maxPrice: 'Prix maximum',
        maxPriceVat: 'Prix maximum {{vat}}',
        net: 'Net',
        price: 'Prix',
        rounding: 'Arrondi',
        subTitleArticles: 'Lorsque les produits / services ont des coûts fixes',
        summary: 'Résumé',
        total: 'Total',
        typeDeduction: 'Après {{type}}-déduction'
      },
      placeholder: {
        description:
          'Choisir la ou les options qui vous conviennent le mieux en cliquant sur le bouton ',
        descriptionBold: 'Touche de sélection',
        title: 'Sélectionner une option'
      },
      terms: {
        houseWork:
          "Le prix total après déduction fiscale pour les travaux de {{type}} nécessite que les travaux effectués soient éligibles à la déduction fiscale, que l'acheteur dispose d'un montant suffisant de déduction et qu'il n'ait pas été utilisé pour d'autres travaux de {{type}}. L'acheteur est responsable de la vérification de ces informations. \n",
        houseWorkApprox:
          "Le prix est approximatif. N'oubliez pas que le prix peut varier jusqu'à 15%. Si les services comprennent des travaux de terrassement, de démolition ou similaires. N'oubliez pas que le prix peut varier jusqu'à 25%.",
        reverseVat:
          "Remarque : l'assujettissement à l'impôt inverse pour les travaux de construction s'applique."
      }
    },
    termsDisclaimer: {
      description: 'En approuvant ce document, vous acceptez ',
      termsAndConditions: 'conditions générales',
      title: 'Conditions générales'
    }
  },
  close: 'Fermer',
  company: 'entreprise',
  deduction: {
    after: 'après la déduction',
    afterType: 'après {{type}}-déduction',
    deduction: 'déduction',
    greenRot15: 'ROT vert',
    greenRot15_percentage: 'ROT vert 15%',
    greenRot20: 'ROT vert',
    greenRot20_percentage: 'ROT vert 20%',
    greenRot50: 'ROT vert',
    greenRot50_percentage: 'ROT vert 50%',
    manualType: 'Déduction {{type}} personnalisée',
    rot: 'ROT',
    // percentage context
    rot_percentage: 'ROT',
    rut: 'RUT',
    rut_percentage: 'RUT',
    type: '{{type}}-déduction'
  },
  denied: 'rejeté',
  deny: 'Rejeter',
  denyModal: {
    denyButton: 'Rejeter le document',
    helpfulInformation:
      'Nous informerons {{- senderName}} de {{- senderCompany}} que vous avez rejeté le document.',
    otherPlaceholder:
      'Optionnel. Veuillez laisser un commentaire sur les raisons de votre rejet.',
    subTitle: 'Confirmer en cliquant sur le bouton Rejeter.',
    successTitle: 'Vous avez rejeté le document',
    thanksAnswer: 'Merci pour votre réponse !',
    title: 'Rejeter le document'
  },
  detail_plural: 'détails',
  document: 'Document',
  document_plural: 'Documents',
  documentDetails: {
    subTitle: 'Informations sur le document.'
  },
  documentVoided: {
    title: "Le document n'est plus disponible.",
    description: "L'expéditeur a choisi d'annuler le document."
  },
  documentTemplateView: {
    emailTemplate: {
      expires: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.expires.title) $t(documentTemplateView.emailTemplate.expires.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      first: {
        content:
          'Vous avez reçu un document de {{company.name}}. Cliquez sur le lien pour en savoir plus et laissez votre réponse.',
        subject: '$t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.first.title) $t(documentTemplateView.emailTemplate.first.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.greetingName)'
      },
      reminder: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.reminder.title) $t(documentTemplateView.emailTemplate.reminder.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      viewed: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.viewed.title) $t(documentTemplateView.emailTemplate.viewed.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      }
    },
    greetingName: 'Salut{{clientFirstName}}!',
    readMore: 'Lire la suite:',
    readMoreAndReply: 'Lire la suite et répondre à:',
    reminder: 'Rappel!',
    sharedSubject:
      'Document provenant de {{company.name}} - {{document.data.name}}',
    smsContent:
      '{{company.name}} vous a envoyé un document auquel vous pouvez répondre.',
    smsTemplate: {
      expires: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      first: {
        text: '$t(documentTemplateView.greetingName)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      reminder: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      viewed: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      }
    }
  },
  email: 'e-mail',
  enterYour: 'Saisissez votre {{thing}}',
  event: {
    accepted: 'Document signé par {{by}}.',
    denied: 'Document rejeté par {{by}}.',
    event_plural: 'événements',
    expired: 'Document expiré.',
    requestChange: 'Document marqué manuellement comme {{status}}.',
    sent: 'Document envoyé à {{to}}.',
    statusChanged: 'Document marqué comme {{status}}.',
    viewed: 'A été consulté',
    viewedName: '{{name}} a consulté le document.'
  },
  goBack: 'Retour',
  less: 'moins',
  login: 'Se connecter',
  logout: 'Déconnexion',
  myPages: {
    docCallout: {
      form: {
        text: 'La bibliothèque de documents vous permet de visualiser tous les documents que vous avez reçus et qui sont liés à votre courrier électronique.',
        title: 'Bibliothèque de documents'
      },
      loginLink: "lien d'accès",
      goToMyPagesTitle: 'Aller à vos pages de documents'
    },
    documentItem: {
      approved: 'Approuvé',
      expired: 'Expiré'
    },
    documentsPlaceholder: "Vous n'avez reçu aucun document.",
    pageDescription: 'Vous trouverez ici tous vos documents entrants.',
    requestForm: {
      alreadySentDescription:
        "Un lien d'accès a déjà été envoyé à {{email}}. Il peut prendre une minute pour arriver.",
      alreadySentTitle: "Le lien d'accès a déjà été envoyé",
      emailFormat: 'Format valide : nom@exemple.com',
      emailRequired: 'Saisissez votre courrier électronique',
      sendLink: "Envoyer un lien d'accès",
      sentDescription:
        'Nous avons envoyé un lien à {{email}}. Utilisez ce lien pour vous connecter.',
      sentDescriptionSub:
        "Pour des raisons de sécurité, vous ne pouvez vous connecter que via le lien d'accès.",
      sentTitle: "Un lien d'accès a été envoyé dans votre boîte de réception."
    },
    title: 'Mes pages'
  },
  name: 'nom',
  orgNoShort: "Numéro d'enregistrement de la société",
  participant_plural: 'participants',
  sender: 'expéditeur',
  phone: 'téléphone',
  price: {
    approx: 'Prix approximatif',
    discount: 'Rabais',
    fixed: 'Prix fixe',
    markup: 'Balisage',
    maxPrice: 'prix maximum',
    net: 'Total net',
    openAcc: 'Ouvrir un compte',
    openAccMaxPrice: 'Ouvrir un compte avec un prix maximum',
    openAccPrice: 'Ouvrir un compte',
    price: 'prix',
    price_plural: 'prix',
    pricing: 'tarification',
    proposal: 'Options du forfait',
    total: 'Prix total'
  },
  print: 'Imprimer{{thing,inlineSpace}}',
  download: 'Télécharger{{thing,inlineSpace}}',
  reference: 'référence',
  replyBy: 'Répondre par',
  see: 'voir',
  select: 'Sélectionner{{thing,inlineSpace}}',
  send: 'envoyer{{thing,inlineSpace}}',
  series: {
    ata: "Accord d'adhésion",
    contract: 'Contrat',
    default: 'Document',
    document: 'Document',
    proposal: 'Proposition'
  },
  show: 'Afficher{{thing,inlineSpace}}',
  socialNo: 'numéro de sécurité sociale',
  status: {
    accepted: 'Accepté',
    active: 'Activer',
    archived: 'Archivé',
    expired: 'Expiré',
    voided: 'Annulé',
    completed: 'Terminé',
    denied: 'Refusé',
    draft: 'Brouillon',
    new: 'Brouillon',
    semiSigned: 'Partiellement signé',
    sent: 'Envoyé',
    signed: 'Signé'
  },
  summaryBottom: {
    chooseFrom: 'Choisir entre les options sélectionnables',
    forDetails: 'pour en savoir plus',
    noOfOptions: '{{count}}',
    option: 'option',
    option_plural: 'options',
    reverseVat:
      "VAT {{- amount}}. La responsabilité fiscale inversée s'applique"
  },
  theDocument: 'le document',
  theSender: "l'expéditeur",
  times: 'temps',
  times_plural: 'temps',
  total: 'total',
  tryAgain: 'Réessayer',
  unitType: {
    day: 'jour',
    hour: 'h',
    kg: 'kg',
    km: 'km',
    kWh: 'kWh',
    litre: 'litre',
    m: 'm',
    m2: 'm²',
    m3: 'm³',
    mile: 'mile',
    minute: 'min',
    month: 'mois',
    second: 's',
    ton: 'tonne',
    unit: 'pièces',
    unitType: "type d'unité",
    unitType_plural: "types d'unité",
    week: 'semaine',
    week_plural: 'semaines',
    year: 'année'
  },
  vat: {
    exVat: 'ex. {{- thing}}',
    incVat: 'incl. {{- thing}}',
    rate: "TVA / Taux d'imposition",
    reverseVat: 'Responsabilité fiscale inversée',
    reverseVatApplies: "La responsabilité fiscale inversée s'applique",
    sharedTitle: 'TVA / Taxe',
    tax: 'taxe',
    vat: 'TVA',
    vatIdentificationNumberShort: "N° d'identification TVA"
  }
}
