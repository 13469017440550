import lang from '@cling/language'

const DocumentForm = () =>
  import(
    /* webpackChunkName: "documentCreate" */ '@/views/account/create/document/DocumentForm.vue'
  )

const formMetaDefault = {
  fullscreen: true,
  body: {
    background: 'white',
    fullSize: true,
    nativeScroll: true
  }
}

export default [
  {
    path: 'document/select',
    name: 'documentFormSelect',
    components: {
      content: () =>
        import(
          /* webpackChunkName: "documentCreate" */ '@/views/account/create/document/DocumentFormSelectRoot.vue'
        )
    },
    props: { content: true },
    meta: {
      fullscreen: true,
      body: {
        background: 'white',
        fullSize: true,
        title: lang.t('router:document.new'),
        navbar: {
          title: lang.t('router:document.new')
        }
      }
    },
    beforeEnter: (to, from, next) => {
      // Save route, before coming to select screen
      // Then passed and used in documentForm route
      if (from && from.name !== 'documentForm') to.meta.from = from
      return next()
    }
  },
  {
    path: 'document/:id?/edit',
    alias: 'document/edit', // Alias will ensure priority over 'document' route on reload
    name: 'documentForm',
    components: {
      content: DocumentForm
    },
    props: {
      content: true
    },
    meta: {
      ...formMetaDefault,
      title: lang.t('router:document.edit'),
      navbar: {
        title: lang.t('router:document.edit')
      }
    },
    beforeEnter: (to, from, next) => {
      // Pass saved route from before select screen
      if (from && from.meta && from.meta.from) to.meta.from = from.meta.from
      // if coming from route other than select
      // Note: 'from' could be 'empty' (name: null), if navigating directly to route (from outside of app)
      else if (from && from.name) {
        to.meta.from = from
        // project modals uses browser history api outside of vue router
        // then we store actual path instead of route object, to get the correct destination back
        if (
          document &&
          document.location &&
          document.location.pathname &&
          document.location.pathname !== from.path
        )
          to.meta.from = document.location.pathname
      }
      return next()
    }
  },
  {
    path: 'document/:id/sent',
    name: 'postDocumentSend',
    components: {
      content: () =>
        import(
          /* webpackChunkName: "documentCreate" */ '@/views/account/create/document/PostDocumentSend.vue'
        )
    },
    props: {
      content: true
    },
    meta: {
      ...formMetaDefault,
      title: lang.t('router:document.sent')
    },
    beforeEnter: (to, from, next) => {
      // Pass along pre dec select 'from' if existing
      to.meta.from = from?.meta?.from || { name: 'account' }
      return next()
    }
  },
  {
    path: 'document/:id',
    name: 'document',
    components: {
      content: () => import('@/views/account/document/Document.vue')
    },
    meta: {
      body: { background: 'white' },
      scrollBehavior: false
    },
    props: {
      content: true
    },
    beforeEnter: (to, from, next) => {
      if (to.query.fullscreen) to.meta.fullscreen = true
      return next()
    }
  },
  {
    path: 'template/:id?/edit',
    name: 'templateForm',
    components: {
      content: DocumentForm
    },
    props: {
      content: true
    },
    meta: {
      ...formMetaDefault,
      title: lang.t('router:template.edit'),
      navbar: {
        title: lang.t('router:template.edit')
      }
    }
  }
]
