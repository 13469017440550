<template>
  <div
    class="text-primary-500 event relative ml-4 flex cursor-default flex-nowrap pb-5 before:absolute before:-left-px before:top-4 before:h-full before:w-px before:bg-gray-300 before:content-[''] last:before:hidden"
  >
    <div style="--bg: #fff" class="relative m-0 h-[23px]">
      <div
        :class="iconClasses"
        class="absolute left-1/2 top-1/2 z-[1] flex h-7 w-7 items-center justify-center rounded-full border-none bg-gray-100"
        style="
          transform: translate(-50%, calc(-50% + 8px));
          box-shadow: 0 0 0 2px var(--bg);
        "
      >
        <CIcon v-if="!loading" :type="icon" size="14" />
      </div>
    </div>
    <div class="ml-7 flex min-w-0 flex-grow flex-col pt-1">
      <template v-if="loading">
        <CSkeleton
          :class="titleSize"
          class="max-w-md font-medium text-gray-900"
          width="80%"
        />
        <CSkeleton :class="textSize" class="text-gray-600" width="40%" />
        <CSkeleton
          :class="dateSize"
          class="inline-flex pt-0.5 text-center text-xs text-gray-500"
          hidden
        />
      </template>
      <div
        :class="[isFuture ? 'text-gray-500' : 'text-gray-700', titleSize]"
        class="max-w-md font-medium"
      >
        <div v-if="template" v-html="template" />
        <template v-else>{{ title }}</template>
      </div>
      <div
        v-if="content"
        :class="[isFuture ? 'text-gray-400' : 'text-gray-600', textSize]"
      >
        <div v-html="content" />
      </div>
      <div :class="dateSize" class="inline-flex pt-1 text-center text-gray-500">
        <div v-tooltip="date">{{ dayOfMonth }} {{ month }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CSkeleton from '@cling/components/ui/Skeleton'
import { isValid, parseISO } from '@cling/utils/date'

export default {
  name: 'EventItem',
  components: {
    CSkeleton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    template: {
      type: String,
      default: ''
    },
    content: {
      type: [String, Array],
      default: ''
    },
    createdAt: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'square'
    },
    compact: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'gray',
      validator: val =>
        [
          'blue',
          'teal',
          'orange',
          'green',
          'red',
          'purple',
          'gray',
          'primary'
        ].indexOf(val) !== -1
    },
    isFuture: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    date() {
      return isValid(parseISO(this.createdAt))
        ? this.$formatDate(this.createdAt, 'Pp')
        : null
    },
    dayOfMonth() {
      return isValid(parseISO(this.createdAt))
        ? this.$formatDate(this.createdAt, 'd')
        : null
    },
    month() {
      return isValid(parseISO(this.createdAt))
        ? this.$formatDate(this.createdAt, 'MMM')
        : null
    },
    iconClasses() {
      const colorMap = {
        blue: 'text-blue-600 bg-blue-50',
        teal: 'text-cyan-600 bg-cyan-50',
        orange: 'text-orange-600 bg-orange-50',
        green: 'text-green-600 bg-green-50',
        red: 'text-red-600 bg-red-50',
        purple: 'text-purple-600 bg-purple-50',
        gray: 'text-gray-500 bg-gray-100',
        primary: 'text-primary-500 bg-primary-50'
      }

      return this.isFuture
        ? 'bg-purple-100 text-purple-500'
        : colorMap[this.color] || colorMap.gray
    },
    titleSize() {
      return this.size === 'large' ? 'text-base' : 'text-sm'
    },
    textSize() {
      return this.size === 'large' ? 'text-[15px]' : 'text-[13px]'
    },
    dateSize() {
      return this.size === 'large' ? 'text-sm' : 'text-xs'
    }
  }
}
</script>
