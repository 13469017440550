<template>
  <div>
    <FilePreviewWrapper :list="projectFiles">
      <template #default="{ openModal, isImageOrPdf }">
        <div>
          <ProjectFileItem
            v-for="(file, i) in projectFiles"
            :id="file.id"
            :key="file.projectFileId"
            :name="file.displayName"
            :project-file-id="file.projectFileId"
            :mime="file.mime"
            :created-at="file.createdAt"
            :href="file.src"
            :project-id="file.projectId"
            :is-image="isImageOrPdf(file.mime)"
            @submit="submitEditFile"
            @remove="remove"
            @click="isImageOrPdf(file.mime) ? openModal(i) : null"
          />
        </div>
      </template>
    </FilePreviewWrapper>
    <template v-for="file in newFiles" :key="file.id">
      <ProjectFileItem
        v-if="showFilePreview(file.response)"
        :id="file.id"
        :name="file.name"
        :project-file-id="file.id"
        :mime="file.type"
        :href="file.url"
        :created-at="file.createdAt"
        :project-id="file.id"
        :upload-progress="parseInt(file.progress, 10)"
      />
    </template>
    <CButton
      :style="{ marginTop: projectFiles.length || newFiles.length ? '5px' : 0 }"
      type="none"
      size="normal"
      pattern="secondary"
      icon-left="plus"
      @click="() => $refs.uploader.open()"
    >
      {{ $t('addButton') }}
    </CButton>
    <BaseUpload
      ref="uploader"
      v-model="newFiles"
      :multiple="true"
      :autostart-upload="true"
      :recent-modal="true"
      extensions="png,gif,jpg,jpeg,svg,bmp,heic,heif,pdf,txt,odp,ods,odt,rtf,doc,docx,xls,xlsx,ppt,pptx,csv,ogv,mp4,webm,m4v,ogg,mp3,wav,zip,rar,json,7z,mpeg,oga,ogv,vsd,weba,webm,webp,xml,gz,tif,jfif,dwg,dwf,dwfx,dwt,msg,ai,psd,md,markdown,mdown,markdn"
      accept="*"
      style="cursor: pointer; width: 100%"
      @upload-success="uploadFileFinished"
    />
  </div>
</template>

<script>
import BaseUpload from '@cling/components/BaseUpload.vue'
import FilePreviewWrapper from '@cling/components/FilePreviewWrapper.vue'
import config from '@cling/config'
import { getType } from '@cling/utils'
import { mapActions, mapGetters } from 'vuex'

import ProjectFileItem from './ProjectFileItem.vue'

import { global } from '@/store/action-types'

const apiUrl = config.api.baseUrl

const { CREATE_PROJECT_FILE, LOAD_FILE, UPDATE_FILE, DELETE_PROJECT_FILE } =
  global

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.file'
  },
  name: 'ProjectFileList',
  components: {
    ProjectFileItem,
    BaseUpload,
    FilePreviewWrapper
  },
  props: {
    files: {
      type: Array,
      default: null
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      currentHover: 0,
      dropdownIsOpen: false,
      newFiles: [] // array to upload new files
    }
  },
  computed: {
    ...mapGetters({
      fileById: 'files/fileById'
    }),
    fileIdList() {
      if (!this.files) {
        return []
      }
      const result = this.files.map(file => ({
        fileId: file.FileId,
        projectFileId: file.id,
        projectId: file.ProjectId
      }))
      if (getType(result) === 'array') {
        return result
      }
      return []
    },
    projectFiles() {
      return this.fileIdList.reduce((result, current) => {
        const file = this.fileById(current.fileId)
        if (!file) return result
        result.push({
          ...file,
          projectId: current.projectId,
          projectFileId: current.projectFileId,
          name: file.displayName, // Component props
          src: `${apiUrl}/file/${file.publicId}/download` // Component props
        })
        return result
      }, [])
    }
  },
  methods: {
    ...mapActions({
      createProjectFile: CREATE_PROJECT_FILE,
      loadFile: LOAD_FILE,
      updateFile: UPDATE_FILE,
      deleteProjectFile: DELETE_PROJECT_FILE
    }),
    // When a new projectFile is uploaded
    async uploadFileFinished(file) {
      const { id } = file.response
      if (!id) {
        throw new Error('Could not read id from uploaded file')
      }
      await this.loadFile({ id })
      await this.createProjectFile({
        projectId: this.projectId,
        fileId: id
      })
      this.$refs.uploader.remove(file)
    },
    async submitEditFile(file) {
      try {
        return this.updateFile({
          id: file.id,
          body: { displayName: file.name }
        })
      } catch (err) {
        // TODO: error handling
        return false
      }
    },
    /**
     * Remove a projectFile by id
     * @param {Number} projectFileId Numeric id of the projectFile
     */
    async remove(projectFileId) {
      await this.deleteProjectFile({
        id: projectFileId,
        projectId: this.projectId
      })
    },
    // Helper function to show upload preview until a real object is added to the store
    showFilePreview(response) {
      if (response && response.id) {
        if (this.projectFiles.some(currId => currId.id === response.id)) {
          return false
        }
      }
      return true
    }
  }
}
</script>
