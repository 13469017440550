import {
  convertArrToObject,
  deepMergeReplace,
  getUniqueArrayElements
} from '@cling/store/utils'

import { updateField } from 'vuex-map-fields'

import { mutationTypes } from './constants'
import eventMutations from './events/mutations'
import projectFileMutations from './projectFiles/mutations'
import projectNoteMutations from './projectNotes/mutations'

const {
  SET_PROJECTS,
  CLEAR_PROJECT_DATA,
  DELETE_MANY_PROJECTS,
  ADD_PROJECT_MEMBER,
  DELETE_PROJECT_MEMBER,
  SET_PROJECTS_FETCHING,
  SET_PROJECTS_POSTING,
  SET_PROJECTS_CAN_FETCH_MORE,
  SET_PROJECTS_COUNT
} = mutationTypes

export default {
  ...projectNoteMutations,
  ...eventMutations,
  ...projectFileMutations,
  updateField, // TODO remove?

  /**
   * @name SET_PROJECTS
   * Add projects to store by overwriting old data with new.
   * Will not remove old projects
   *
   * @param {Object} state Vuex Store Props
   * @param {Object} payload
   * @param {Object|Array} payload.data project or an array of projects
   *
   * @returns {Void}
   */
  [SET_PROJECTS](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a project or an array of projects!')
    }
    const items = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(items)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  [CLEAR_PROJECT_DATA](state) {
    Object.assign(state, {
      data: {},
      ids: [],
      projectPublicIdToId: {}
    })
  },

  [DELETE_MANY_PROJECTS](state, ids) {
    const idArr = Array.isArray(ids) ? ids : [ids]
    idArr.forEach(id => {
      if (!state.data[id]) return
      const { publicId } = state.data[id]
      delete state.data[id]

      state.ids = state.ids.filter(x => x !== id)
      delete state.projectPublicIdToId[publicId]
    })
  },

  // Add a companyUser id to a project
  [ADD_PROJECT_MEMBER](state, { projectId, companyUserId }) {
    if (!projectId) {
      throw Error('Cannot add member as no projectId is defined')
    }
    if (!companyUserId) {
      throw Error('Cannot add member as no companyUserId is defined')
    }

    if (!state.data[projectId]) return false
    // Does the ProjectMembers array exist on project?
    let newProjectMembers = []
    if (state.data[projectId].ProjectMembers) {
      newProjectMembers = state.data[projectId].ProjectMembers
    } else {
      state.data[projectId].ProjectMembers = newProjectMembers
    }
    // Does the companyUserId already exist?
    const index = newProjectMembers.findIndex(id => id === companyUserId)
    if (index !== -1) {
      newProjectMembers[index] = companyUserId
    } else {
      newProjectMembers.push(companyUserId)
    }
    return true
  },

  // Delete a companyUser id from a project
  [DELETE_PROJECT_MEMBER](state, { projectId, companyUserId }) {
    if (!projectId) {
      throw Error('Cannot delete member as no projectId is defined')
    }
    if (!companyUserId) {
      throw Error('Cannot delete member as no companyUserId is defined')
    }

    if (!state.data[projectId]) return false
    // Does the ProjectMembers array exist on project?
    let newProjectMembers = []
    if (state.data[projectId].ProjectMembers) {
      newProjectMembers = state.data[projectId].ProjectMembers
    } else {
      state.data[projectId].ProjectMembers = newProjectMembers
    }
    // Does the companyUserId already exist?
    const index = newProjectMembers.findIndex(id => id === companyUserId)
    if (index !== -1) {
      newProjectMembers.splice(index, 1)
    }
    return true
  },

  /**
   * @name SET_PROJECTS_FETCHING
   * Set fetching status of projects
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_PROJECTS_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_PROJECTS_POSTING
   * Set posting status of projects
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_PROJECTS_POSTING](state, isPosting) {
    state.isPosting = isPosting
  },

  /**
   * @name SET_PROJECTS_CAN_FETCH_MORE
   * Set posting status of projects can fetch more
   *
   * @param {Object} state Vuex state
   * @param {Boolean} canFetchMore New state of canFetchMore
   */
  [SET_PROJECTS_CAN_FETCH_MORE](state, canFetchMore) {
    state.canFetchMore = canFetchMore
  },

  /**
   * @name SET_PROJECTS_COUNT
   * Set total project count for each status
   *
   * @param {Object} state Vuex state
   * @param {Object} data Object with total project with status keys
   */
  [SET_PROJECTS_COUNT](state, { data = {} }) {
    if (typeof data !== 'object' || Array.isArray(data))
      throw Error('Data must be an object!')

    const countObj = {}
    Object.keys(data).forEach(status => {
      countObj[status] = data[status] || 0
    })

    state.count = countObj
  }
}
