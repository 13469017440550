<template>
  <CDropdown
    ref="dropdown"
    :disabled="project ? !$can('projects', 'modify', project.id) : true"
    :mobile-label="$t('change', { thing: $t('status.status') })"
    style="display: flex; width: 100%"
    expanded
    @active-change="dropdownIsOpen = $event"
  >
    <template #trigger="{ active }">
      <CButton
        raised
        expanded
        dropdown-icon
        type="text"
        pattern="tertiary"
        class="is-left"
        style="padding-left: 1.25em"
      >
        <CIcon
          :class="[
            `status-project-text-${derivedStatus}`,
            { rotateSpin: loading }
          ]"
          :type="loading ? 'loader' : 'circle-status'"
          size="16"
        />
        <span style="margin-left: 1em">
          {{
            active ? $t('change', { thing: $t('status.status') }) : statusText
          }}
        </span>
      </CButton>
    </template>
    <CDropdownItem
      v-for="(option, key) in statusOptions"
      :key="key"
      :tooltip="option.tooltip"
      :divided="option.divided"
      :disabled="derivedStatus === option.value"
      :type="option.value === 'destroy' ? 'danger' : null"
      :icon-props="{
        class: `status-project-text-${option.value} status-project-icon`,
        strokeWidth: option.value === 'destroy' ? 3 : ''
      }"
      :icon-left="option.value === 'destroy' ? 'x' : 'circle-status'"
      @click="option.onClick"
    >
      {{ option.label.capitalize() }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'

import { mapActions } from 'vuex'

import { global } from '@/store/action-types'

export default {
  i18nOptions: {
    namespaces: 'ProjectStatusDropdown',
    messages: {
      en: {
        tooltip: {
          sent: 'If you send a project it will automatically be moved here.',
          accepted:
            'If your recipients signs your document the project will automatically be moved here.',
          denied:
            'If your recipients declines your document the project will automatically be moved here.',
          expired:
            'The document will automatically be set as expired if expiration date has passed.',
          voided:
            'Void the document to prevent recipients from viewing and signing it.',
          active: 'Mark as ongoing when it is time to start the project.',
          completed: 'Project is done but you are waiting to get paid.',
          archived:
            'Move project to archive when you are done and have received payment.',
          destroy: 'Say good bye to this project forever.'
        }
      },
      sv: {
        tooltip: {
          sent: 'Flyttas automatiskt hit efter att du skickat första dokumentet.',
          accepted:
            'Flyttas automatiskt hit om din mottagare signerar ett dokument.',
          denied:
            'Flyttas automatiskt hit om din mottagare nekar ett dokument.',
          expired: 'Flyttas automatiskt hit om giltighetstid har passerats',
          voided:
            'Återkalla dokumentet för att förhindra dina mottagare från att titta eller besvara dokumentet.',
          active:
            'Markera som pågående när det är dags att sätta igång arbetet.',
          completed: 'Du är klar med arbetet men väntar på att få betalt.',
          archived:
            'Flytta projektet till arkivet när du är klar och fått betalt.',
          destroy: 'Säg hej då för alltid till projektet.'
        }
      }
    }
  },
  name: 'ProjectStatusDropdown',
  components: {
    CDropdown,
    CDropdownItem
  },
  emits: ['remove'],
  props: {
    project: {
      type: Object,
      default: () => ({})
    },
    compact: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    },
    // Optional function to call if the project is deleted
    onDeleteProject: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      dropdownIsOpen: false
    }
  },
  computed: {
    derivedStatus() {
      if (!this.project) return ''
      let result = ''
      result = this.project.virtualStatus
      if (!result) result = 'new'
      return result
    },
    statusText() {
      return this.$t(`status.${this.derivedStatus || 'status'}`)
    },
    statusOptions() {
      // Don't use get/$get unless a direct path is provided.
      // Computed is not reactive on string key paths
      const projectId = this.project ? this.project.id : null
      const id =
        this.project && this.project.mainDocument
          ? this.project.mainDocument.id
          : null

      const getTooltip = value => ({
        placement: 'left',
        boundariesElement: this.$refs.dropdown,
        delay: { show: 300, hide: 0 },
        content: this.$t(`tooltip.${value}`)
      })

      const options = {
        sent: {
          value: 'sent',
          label: this.$t('status.sent'),
          divided: false,
          disabled: this.derivedStatus === 'sent',
          onClick: () => {
            this.submitProject({ data: { id: projectId, status: 'offer' } })
            this.updateDocumentStatus({ id, status: 'sent' })
          },
          tooltip: getTooltip('sent')
        },
        accept: {
          value: 'accepted',
          label: this.$t('status.accepted'),
          divided: false,
          onClick: () => {
            this.submitProject({ data: { id: projectId, status: 'offer' } })
            this.updateDocumentStatus({ id, status: 'accepted' })
          },
          tooltip: getTooltip('accepted')
        },
        deny: {
          value: 'denied',
          label: this.$t('status.denied'),
          divided: false,
          onClick: () => {
            this.submitProject({ data: { id: projectId, status: 'offer' } })
            this.updateDocumentStatus({ id, status: 'denied' })
          },
          tooltip: getTooltip('denied')
        },
        expire: {
          value: 'expired',
          label: this.$t('status.expired'),
          divided: false,
          onClick: () => {
            this.submitProject({ data: { id: projectId, status: 'offer' } })
            this.updateDocumentStatus({ id, status: 'expired' })
          },
          tooltip: getTooltip('expired')
        },
        ...(this?.project?.mainDocument?.status === 'voided' && {
          void: {
            value: 'voided',
            label: this.$t('status.voided'),
            divided: false,
            onClick: () => {
              this.submitProject({ data: { id: projectId, status: 'offer' } })
              this.updateDocumentStatus({ id, status: 'voided' })
            },
            tooltip: getTooltip('voided')
          }
        }),
        active: {
          value: 'active',
          label: this.$t('status.active'),
          divided: false,
          onClick: () => {
            this.submitProject({
              data: {
                id: projectId,
                status: 'active'
              }
            })
          },
          tooltip: getTooltip('active')
        },
        complete: {
          value: 'completed',
          label: this.$t('status.completed'),
          divided: false,
          onClick: () => {
            this.submitProject({
              data: {
                id: projectId,
                status: 'completed'
              }
            })
          },
          tooltip: getTooltip('completed')
        },
        archive: {
          value: 'archived',
          label: this.$t('status.archived'),
          divided: true,
          onClick: () => {
            this.submitProject({
              data: {
                id: projectId,
                status: 'archived'
              }
            })
          },
          tooltip: getTooltip('archived')
        },
        destroy: {
          value: 'destroy',
          label: this.$t('remove'),
          divided: false,
          onClick: () => {
            this.deleteProject({
              id: projectId
            })
          },
          tooltip: getTooltip('destroy')
        }
      }

      return options
    }
  },
  methods: {
    ...mapActions({
      updateDocStatus: global.UPDATE_DOCUMENT2_STATUS,
      submitProject: global.SUBMIT_PROJECT,
      actionDeleteProject: global.DELETE_PROJECT
    }),
    async updateDocumentStatus(obj) {
      if (!obj.id) return
      this.updateDocStatus(obj)
    },
    async deleteProject({ id }) {
      const didDelete = await this.actionDeleteProject({ id })
      if (didDelete) {
        this.$emit('remove')
        if (typeof this.onDeleteProject === 'function') this.onDeleteProject()
      }
    }
  }
}
</script>

<style lang="scss">
.status-project-icon.status-project-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 1.5em;
  height: 1.5em;
}
</style>
