<template>
  <BubbleMenu
    :should-show="
      ({ from, to }) =>
        (isFocused || linkMenuIsActive) &&
        from !== to &&
        !editor.isActive('image') &&
        !editor.isActive('horizontalRule')
    "
    :editor="editor"
    :tippy-options="{
      maxWidth: 'none'
    }"
  >
    <div
      :class="[
        {
          'is-bottom': isTouchDevice
        }
      ]"
      class="menu"
    >
      <form
        v-if="linkMenuIsActive"
        v-click-outside="() => (linkMenuIsActive = false)"
        class="menu-form"
        @submit.prevent="setLink(linkUrl)"
      >
        <input
          ref="linkInput"
          v-model="linkUrl"
          class="menu-input"
          type="text"
          placeholder="https://"
          @keydown.esc="hideLinkMenu"
        />
        <div class="menu-button" @mousedown.prevent="setLink(null)">
          <CIcon type="x" size="16" />
        </div>
      </form>
      <div v-else class="menu-content">
        <FontStyleMenu :editor="editor" />
        <button
          v-if="editor.commands.toggleBold"
          :data-texteditor-tooltip="$t('bold')"
          :class="{ 'is-active': editor.isActive('bold') }"
          class="menu-button"
          @mousedown.prevent
          @click.prevent="editor.chain().focus().toggleBold().run()"
        >
          <svg
            width="20"
            height="20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-8 -5 62 62"
          >
            <path
              d="M 14 5 A 4.0004 4.0004 0 0 0 10 9 L 10 39 A 4.0004 4.0004 0 0 0 14 43 L 29 43 C 35.580064 43 41 37.580064 41 31 C 41 27.155249 39.030751 23.864926 36.179688 21.662109 C 37.230561 19.978585 38 18.110372 38 16 C 38 9.972248 33.027752 5 27 5 L 14 5 z M 18 13 L 27 13 C 28.704248 13 30 14.295752 30 16 C 30 17.704248 28.704248 19 27 19 L 18 19 L 18 13 z M 18 27 L 27 27 L 29 27 C 31.255936 27 33 28.744064 33 31 C 33 33.255936 31.255936 35 29 35 L 18 35 L 18 27 z"
            ></path>
          </svg>
        </button>
        <button
          v-if="editor.commands.toggleItalic"
          :data-texteditor-tooltip="$t('italic')"
          :class="{ 'is-active': editor.isActive('italic') }"
          class="menu-button"
          @mousedown.prevent
          @click.prevent="editor.chain().focus().toggleItalic().run()"
        >
          <CIcon type="editorItalic" size="20" />
        </button>
        <button
          :data-texteditor-tooltip="$t('underline')"
          :class="{ 'is-active': editor.isActive('underline') }"
          class="menu-button"
          @mousedown.prevent
          @click.prevent="editor.chain().focus().toggleUnderline().run()"
        >
          <svg
            width="20"
            height="20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 58 58"
          >
            <path
              d="M 10.970703 4.9726562 A 2.0002 2.0002 0 0 0 9 7 L 9 22.5 C 9 30.484726 15.515274 37 23.5 37 C 31.484726 37 38 30.484726 38 22.5 L 38 7 A 2.0002 2.0002 0 1 0 34 7 L 34 22.5 C 34 28.323274 29.323274 33 23.5 33 C 17.676726 33 13 28.323274 13 22.5 L 13 7 A 2.0002 2.0002 0 0 0 10.970703 4.9726562 z M 11 40 A 2.0002 2.0002 0 1 0 11 44 L 37 44 A 2.0002 2.0002 0 1 0 37 40 L 11 40 z"
            ></path>
          </svg>
        </button>
        <button
          :data-texteditor-tooltip="$t('_common:link').capitalize()"
          :class="{ 'is-active': editor.isActive('link') }"
          class="menu-button"
          @mousedown.prevent
          @click.prevent="showLinkMenu"
        >
          <svg
            width="20"
            height="20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -4 54 54"
          >
            <path
              d="M 37 4.0039062 C 34.69525 4.0037812 32.389766 4.8822188 30.634766 6.6367188 L 25.634766 11.636719 C 23.142766 14.128719 22.430516 17.727281 23.478516 20.863281 L 27.070312 17.271484 C 27.220312 16.244484 27.673891 15.253844 28.462891 14.464844 L 33.462891 9.4648438 C 34.437891 8.4908438 35.72 8.0019531 37 8.0019531 C 38.28 8.0019531 39.561156 8.4898437 40.535156 9.4648438 C 42.484156 11.414844 42.484156 14.586156 40.535156 16.535156 L 35.535156 21.535156 C 34.746156 22.324156 33.756516 22.776734 32.728516 22.927734 L 29.134766 26.521484 C 30.062766 26.831484 31.029047 26.996094 31.998047 26.996094 C 34.303047 26.996094 36.608281 26.118281 38.363281 24.363281 L 43.363281 19.363281 C 46.873281 15.854281 46.872281 10.145719 43.363281 6.6367188 C 41.608281 4.8827187 39.30475 4.0040313 37 4.0039062 z M 30.960938 16.980469 A 2.0002 2.0002 0 0 0 29.585938 17.585938 L 17.585938 29.585938 A 2.0002 2.0002 0 1 0 20.414062 32.414062 L 32.414062 20.414062 A 2.0002 2.0002 0 0 0 30.960938 16.980469 z M 18.449219 23.023438 C 15.997141 22.898656 13.506469 23.765766 11.636719 25.634766 L 6.6367188 30.634766 C 3.1277188 34.143766 3.1277187 39.854281 6.6367188 43.363281 C 8.3917188 45.117281 10.696 45.994141 13 45.994141 C 15.304 45.994141 17.608281 45.116328 19.363281 43.361328 L 24.363281 38.361328 C 26.855281 35.869328 27.569484 32.270766 26.521484 29.134766 L 22.927734 32.728516 C 22.777734 33.755516 22.324156 34.746156 21.535156 35.535156 L 16.535156 40.535156 C 14.586156 42.485156 11.413844 42.485156 9.4648438 40.535156 C 7.5158438 38.585156 7.5158438 35.413844 9.4648438 33.464844 L 14.464844 28.464844 C 15.253844 27.675844 16.244484 27.221312 17.271484 27.070312 L 20.863281 23.478516 C 20.079281 23.216516 19.266578 23.065031 18.449219 23.023438 z"
            ></path>
          </svg>
        </button>

        <div class="menu-divider" />

        <button
          v-if="editor.commands.toggleHighlight"
          :class="{ 'is-active': editor.isActive('highlight') }"
          :data-texteditor-tooltip="$t('highlight')"
          class="menu-button"
          @mousedown.prevent
          @click.prevent="editor.chain().focus().toggleHighlight().run()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 48 48"
          >
            <path
              d="M 7.4765625 4.9785156 A 1.50015 1.50015 0 0 0 6 6.5 L 6 15.5 C 6 17.790682 7.7748091 19.635632 10 19.898438 L 10 24.5 C 10 26.967501 12.032499 29 14.5 29 L 15 29 L 15 43.5 C 15 44.013 15.261359 44.489625 15.693359 44.765625 C 15.938359 44.920625 16.219 45 16.5 45 C 16.716 45 16.933766 44.953375 17.134766 44.859375 L 30.402344 38.667969 C 31.980344 37.932969 33 36.330844 33 34.589844 L 33 29 L 33.5 29 C 35.967501 29 38 26.967501 38 24.5 L 38 19.898438 C 40.225191 19.635632 42 17.790682 42 15.5 L 42 6.5 A 1.50015 1.50015 0 1 0 39 6.5 L 39 15.5 C 39 16.346499 38.346499 17 37.5 17 L 36.746094 17 A 1.50015 1.50015 0 0 0 36.259766 17 L 11.746094 17 A 1.50015 1.50015 0 0 0 11.259766 17 L 10.5 17 C 9.6535009 17 9 16.346499 9 15.5 L 9 6.5 A 1.50015 1.50015 0 0 0 7.4765625 4.9785156 z M 13 20 L 35 20 L 35 24.5 C 35 25.346499 34.346499 26 33.5 26 L 14.5 26 C 13.653501 26 13 25.346499 13 24.5 L 13 20 z"
            />
          </svg>
        </button>
        <ColorMenu
          v-if="editor.commands.setColor"
          v-model:show-picker="showColorPicker"
          :editor="editor"
        />

        <div class="menu-divider" />

        <template v-if="editor.commands.setTextAlign">
          <button
            :class="{ 'is-active': textAlignActive('left') }"
            :data-texteditor-tooltip="
              $t('_common:align', { thing: $t('_common:left') })
            "
            class="menu-button"
            @mousedown.prevent
            @click.prevent="setTextAlign('left')"
          >
            <svg
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              fill="currentColor"
            >
              <path
                d="M 6 9 A 2.0002 2.0002 0 1 0 6 13 L 34 13 A 2.0002 2.0002 0 1 0 34 9 L 6 9 z M 6 22 A 2.0002 2.0002 0 1 0 6 26 L 42 26 A 2.0002 2.0002 0 1 0 42 22 L 6 22 z M 7 35 A 2.0002 2.0002 0 1 0 7 39 L 27 39 A 2.0002 2.0002 0 1 0 27 35 L 7 35 z"
              />
            </svg>
          </button>
          <button
            :class="{ 'is-active': textAlignActive('center') }"
            :data-texteditor-tooltip="
              $t('_common:center', { thing: $t('_common:text') }).capitalize()
            "
            class="menu-button"
            @mousedown.prevent
            @click.prevent="setTextAlign('center')"
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
            >
              <path
                d="M 10 9 A 2.0002 2.0002 0 1 0 10 13 L 38 13 A 2.0002 2.0002 0 1 0 38 9 L 10 9 z M 6 22 A 2.0002 2.0002 0 1 0 6 26 L 42 26 A 2.0002 2.0002 0 1 0 42 22 L 6 22 z M 14 35 A 2.0002 2.0002 0 1 0 14 39 L 34 39 A 2.0002 2.0002 0 1 0 34 35 L 14 35 z"
              />
            </svg>
          </button>
          <button
            :class="{ 'is-active': textAlignActive('right') }"
            :data-texteditor-tooltip="
              $t('_common:align', { thing: $t('_common:right') })
            "
            class="menu-button"
            @mousedown.prevent
            @click.prevent="setTextAlign('right')"
          >
            <svg
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              fill="currentColor"
            >
              <path
                d="M 14 9 A 2.0002 2.0002 0 1 0 14 13 L 42 13 A 2.0002 2.0002 0 1 0 42 9 L 14 9 z M 6 22 A 2.0002 2.0002 0 1 0 6 26 L 42 26 A 2.0002 2.0002 0 1 0 42 22 L 6 22 z M 22 35 A 2.0002 2.0002 0 1 0 22 39 L 42 39 A 2.0002 2.0002 0 1 0 42 35 L 22 35 z"
              />
            </svg>
          </button>
        </template>
      </div>
    </div>
  </BubbleMenu>
</template>

<script>
import { isTouchDevice } from '@cling/components/ui/utils'
import { BubbleMenu } from '@tiptap/vue-3'

import ColorMenu from './ColorMenu.vue'
import FontStyleMenu from './FontStyleMenu.vue'

export default {
  name: 'TextEditorBubbleMenu',
  i18nOptions: {
    namespaces: 'TextEditorBubbleMenu',
    messages: {
      en: {
        bold: 'Bold',
        italic: 'Italic',
        highlight: 'Highlight',
        underline: 'Underline'
      },
      sv: {
        bold: 'Fetstil',
        italic: 'Kursiv',
        highlight: 'Markera',
        underline: 'Understryk'
      }
    }
  },
  components: {
    BubbleMenu,
    FontStyleMenu,
    ColorMenu
  },
  props: {
    editor: {
      type: Object,
      required: true
    },
    extensions: {
      type: Object,
      default: () => ({})
    },
    isFocused: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      linkUrl: null,
      linkMenuIsActive: false,
      showColorPicker: false,
      isTouchDevice
    }
  },
  methods: {
    textAlignActive(textAlign) {
      return this.editor.isActive({ textAlign })
    },
    setTextAlign(textAlign) {
      const isActive = this.textAlignActive(textAlign)
      if (isActive) this.editor.chain().focus().unsetTextAlign().run()
      else this.editor.chain().focus().setTextAlign(textAlign).run()
    },
    showLinkMenu() {
      this.linkUrl = this.editor.getAttributes('link').href
      this.linkMenuIsActive = true
      this.$nextTick(() => this.$refs.linkInput.focus())
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLink(url) {
      let cmd = 'unsetLink'
      let href = url
      if (url) {
        cmd = 'setLink'
        if (!href.toLowerCase().startsWith('http')) href = `//${href}`
      }
      this.editor.chain().focus().extendMarkRange('link')[cmd]({ href }).run()
      this.hideLinkMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.menu {
  /* position: absolute; */
  z-index: 20;
  background: hsl(0, 0%, 18%);
  border-radius: calc(0.5 * var(--rem));
  padding: 0 0.5em;
  &-content {
    display: flex;
    align-items: center;
  }
  &.is-bottom {
    margin-bottom: 0;
    margin-top: calc(0.5 * var(--rem));
    &::before {
      top: 0;
      bottom: initial;
    }
  }
  .dropdown-is-active::after {
    background: hsl(var(--primary-color-500) / 1);
    content: '';
    position: absolute;
    left: 2px;
    top: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
  }

  :deep(.menu-button) {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: rgba($white, 0.7);
    padding: 4px;
    border-radius: 3px;
    cursor: pointer;
    height: 42px;

    &:hover {
      color: $white;
    }

    &:last-child {
      margin-right: 0;
    }

    &.menu-button.is-active {
      color: hsl(190, 100%, 50%);
    }
    &.menu-button.is-disabled {
      color: rgba($white, 0.3);
      cursor: disabled;
    }
  }

  &-divider {
    background-color: rgba($white, 0.18);
    width: 1px;
    height: 30px;
    margin: 0 0.5em;
  }

  &-form {
    display: flex;
    align-items: center;
  }

  &-input {
    font: inherit;
    border: none;
    background: transparent;
    color: $white;
  }
}

@keyframes popUpwards {
  0% {
    transform: matrix(0.97, 0, 0, 1, 0, 12) translateX(-50%);
    opacity: 0;
  }

  20% {
    transform: matrix(0.99, 0, 0, 1, 0, 2) translateX(-50%);
    opacity: 0.7;
  }

  40% {
    transform: matrix(1, 0, 0, 1, 0, -1) translateX(-50%);
    opacity: 1;
  }

  70% {
    transform: matrix(1, 0, 0, 1, 0, 0) translateX(-50%);
    opacity: 1;
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0) translateX(-50%);
    opacity: 1;
  }
}
@keyframes popDownwards {
  0% {
    transform: matrix(0.97, 0, 0, 1, 0, -12)
      translate(-50%, calc(100% + calc(2.2 * var(--rem))));
    opacity: 0;
  }

  20% {
    transform: matrix(0.99, 0, 0, 1, 0, -2)
      translate(-50%, calc(100% + calc(2.2 * var(--rem))));
    opacity: 0.7;
  }

  40% {
    transform: matrix(1, 0, 0, 1, 0, 1)
      translate(-50%, calc(100% + calc(2.2 * var(--rem))));
    opacity: 1;
  }

  70% {
    transform: matrix(1, 0, 0, 1, 0, 0)
      translate(-50%, calc(100% + calc(2.2 * var(--rem))));
    opacity: 1;
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0)
      translate(-50%, calc(100% + calc(2.2 * var(--rem))));
    opacity: 1;
  }
}
</style>
<style lang="scss">
@import '@cling/styles/main.scss';

.texteditor-tooltip-dropdown {
  display: flex;
  flex-wrap: wrap;
  color: rgba($white, 0.7);
  font-size: 15px;
  &-inner {
    &#{&} .v-popper__inner {
      padding: 4px;
      margin-left: calc(-0.5 * var(--rem));
      border-radius: calc(0.35 * var(--rem));
      background: hsl(0, 0%, 18%);
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
      &.is-color-menu {
        margin: -12px 0;
      }
    }
  }
  &-item {
    min-width: 14ch;
    padding: 5px 1em;
    cursor: pointer;
    line-height: 1.4;
    border-radius: 5px;
    margin-bottom: 1px;
    &:hover {
      color: $white;
    }
    &.is-active {
      background-color: hsla(190, 100%, 50%, 0.15);
      color: hsl(190, 100%, 50%);
    }
  }
}
</style>
