<template>
  <div>
    <div class="options-wrapper">
      <div class="pb-4 text-lg font-semibold">{{ $t('title') }}</div>
      <div class="print-options">
        <div class="select-none pb-1 text-sm font-medium text-gray-800">
          {{ $t('_common:document') }}
        </div>
        <div class="option-item">
          <div
            :class="{ disabled: !document.attachedFiles.length }"
            class="option-item__inner bg-gray-100 hover:bg-gray-200"
            @click="options.appendAttachments = !options.appendAttachments"
          >
            <CCheckbox
              :model-value="
                document.attachedFiles.length
                  ? options.appendAttachments
                  : false
              "
              type="secondary"
              size="small"
              style="flex: 0 0 30px"
            />
            <div>
              <div>{{ $t('appendAttachments') }}</div>
              <div class="has-text-grey-dark" style="font-size: 13px">
                {{
                  $tc('_common:attachment', {
                    count: document.attachedFiles.length
                  })
                }}
              </div>
            </div>
          </div>
          <div v-tooltip="omitAttachmentBlockTooltip">
            <div
              :class="{
                disabled: !document.getAllNodesWithType('attachments').length
              }"
              class="option-item__inner border-t-2 border-white bg-gray-100 hover:bg-gray-200"
              @click="
                options.hideDocumentAttachmentBlocks =
                  !options.hideDocumentAttachmentBlocks
              "
            >
              <CCheckbox
                :model-value="
                  !document.getAllNodesWithType('attachments').length
                    ? false
                    : options.hideDocumentAttachmentBlocks
                "
                type="secondary"
                size="small"
                style="flex: 0 0 30px"
              />
              <div>
                <div>{{ $t('hideDocumentAttachmentBlocks') }}</div>
                <div class="has-text-grey-dark" style="font-size: 13px">
                  {{
                    $tc('_common:block', {
                      count: document.getAllNodesWithType('attachments').length
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 1.25rem" />
        <div class="select-none pb-1 text-sm font-medium text-gray-800">
          {{ $t('_common:verification').capitalize() }}
        </div>
        <div :class="{ disabled: !allowVerification }" class="option-item">
          <div
            class="option-item__inner bg-gray-100 hover:bg-gray-200"
            @click="options.showVerification = !options.showVerification"
          >
            <CCheckbox
              :model-value="options.showVerification"
              type="secondary"
              size="small"
              style="flex: 0 0 30px"
            />
            <div>
              <div>{{ $t('showVerification') }}</div>
              <div class="has-text-grey-dark" style="font-size: 13px">
                {{ $t('showVerificationSub') }}
              </div>
            </div>
          </div>
          <div
            v-if="options.showVerification"
            class="option-item__inner border-t-2 border-white bg-gray-100 pl-8 hover:bg-gray-200"
            @click="options.showEvents = !options.showEvents"
          >
            <CCheckbox
              :model-value="options.showEvents"
              type="secondary"
              size="small"
              style="flex: 0 0 30px"
            />
            <div>
              <div>{{ $t('showEvents') }}</div>
            </div>
          </div>
          <div v-if="options.showVerification && hasBankIdSignatures">
            <div
              class="mb-1 mt-3 inline-flex cursor-pointer select-none items-center rounded p-2 text-xs font-medium leading-none text-gray-500 hover:bg-gray-100"
              @click="showMoreOptions = !showMoreOptions"
            >
              {{ $t('_common:more').capitalize() }}
              <CIcon
                type="chevron-right"
                :class="{ 'rotate-90': showMoreOptions }"
                class="ml-0.5 transition-transform"
                stroke-width="2.25"
                size="12"
              />
            </div>
            <Animation>
              <div
                v-if="showMoreOptions"
                class="option-item__inner bg-gray-100 hover:bg-gray-200"
                @click="options.showLast4ssn = !options.showLast4ssn"
              >
                <CCheckbox
                  :model-value="options.showLast4ssn"
                  type="secondary"
                  size="small"
                  style="flex: 0 0 30px"
                />
                <div>
                  <div>{{ $t('showLast4ssn') }}</div>
                </div>
              </div>
            </Animation>
          </div>
        </div>
        <div v-if="showAdminOptions">
          <div style="height: 1.25rem" />
          <div class="select-none pb-1 text-sm font-medium text-gray-800">
            Superuser + VPN
          </div>
          <div
            class="option-item__inner border-t-2 border-white bg-gray-100 text-xs opacity-80 hover:bg-gray-200"
            @click="options.forceDoCreatePdf = !options.forceDoCreatePdf"
          >
            <CCheckbox
              :model-value="options.forceDoCreatePdf"
              size="small"
              class="py-2"
            >
              Force re-create PDF
            </CCheckbox>
          </div>
          <div
            class="option-item__inner border-t-2 border-white bg-gray-100 text-xs opacity-80 hover:bg-gray-200"
            @click="options.compressImages = !options.compressImages"
          >
            <CCheckbox
              :model-value="options.compressImages"
              type="secondary"
              size="small"
              style="flex: 0 0 30px"
            />
            <div>
              <div>Compress images</div>
            </div>
          </div>
          <div
            class="option-item__inner border-t-2 border-white bg-gray-100 text-xs opacity-80 hover:bg-gray-200"
            @click="options.debugPage = !options.debugPage"
          >
            <CCheckbox
              :model-value="options.debugPage"
              size="small"
              class="py-2"
            >
              Debug page output
            </CCheckbox>
          </div>
          <div
            class="border-t-2 border-white bg-gray-100 text-xs opacity-80 hover:bg-gray-200"
          >
            <CInput
              v-model.number="extraTimeout"
              type="number"
              filled
              size="small"
              placeholder="Optional extra timeout (ms)"
            />
          </div>
        </div>
      </div>
    </div>
    <CButton type="secondary" size="medium" wide @click="generatePdf">
      {{ $t('buttonText') }}
    </CButton>
  </div>
</template>

<script>
import { pdfJob } from '@cling/api'
import CCheckbox from '@cling/components/ui/Checkbox'
import config from '@cling/config'
import { showMessage } from '@cling/services/messages'
import { wait } from '@cling/utils'
import webStorage from '@cling/utils/webStorage'

export default {
  i18nOptions: {
    namespaces: 'documentPrint',
    messages: {
      en: {
        title: 'Print options',
        appendAttachments: 'Append attachments',
        appendAttachmentsSub: 'Append attachments',
        hideDocumentAttachmentBlocks: 'Hide document attachment blocks',
        hideDocumentAttachmentBlocksTooltip:
          'Hide blocks with the type of attachments.',
        showVerification: 'Include verification',
        showVerificationSub: 'Signature verification.',
        showLast4ssn:
          'Include the last 4 digits of the personal identification number from the BankID signature.',
        showEvents: 'Show events',
        buttonText: 'Download PDF',
        preparingPdf: 'Preparing PDF...',
        preparingPdfDone: 'PDF is complete',
        preparingPdfError: 'Could not create PDF. Try again.'
      },
      sv: {
        title: 'Utskriftsinställningar',
        appendAttachments: 'Bifoga bilagor',
        appendAttachmentsSub: 'Inkludera bilagor i slutet av PDF:en.',
        hideDocumentAttachmentBlocks: 'Göm dokumentblock som är bilagor',
        hideDocumentAttachmentBlocksTooltip: 'Dölj blocken av typen bilagor.',
        showVerification: 'Inkludera verifikat',
        showVerificationSub: 'Signaturverifikat.',
        showLast4ssn:
          'Inkludera de sista 4-siffrorna i personnummret från BankID-signaturen',
        showEvents: 'Visa händelser',
        buttonText: 'Ladda ner PDF',
        preparingPdf: 'Förbereder PDF...',
        preparingPdfDone: 'PDF är redo',
        preparingPdfError: 'Kunde inte skapa PDF. Pröva igen.'
      }
    }
  },
  name: 'DocumentPrint',
  components: {
    CCheckbox
  },
  emits: ['close'],
  props: {
    document: {
      type: Object,
      required: true
    },
    isSuperUser: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      options: {
        appendAttachments: true,
        hideDocumentAttachmentBlocks: false,
        showVerification: true,
        showEvents: true,
        showLast4ssn: true,
        forceDoCreatePdf: false, // Only for superuser
        compressImages: false, // Only for superuser
        debugPage: false, // Only for superuser
        extraTimeout: 0 // Only for superuser
      },
      showMoreOptions: false,
      omitAttachmentBlockTooltip: {
        content:
          '<svg style="margin-top: 2px;" width="193" height="122" viewBox="0 0 193 122" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="193" height="122" rx="5" fill="white"/><text fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="11" font-weight="bold" letter-spacing="0em"><tspan x="13" y="25.5">' +
          this.$t('_common:attachment_plural').capitalize() +
          '</tspan></text><path d="M14 33H25.2941L30 38.225V52H14V33Z" fill="#FFC4C4"/><path opacity="0.34" d="M14 60H25.5L30 64.5V79H14V60Z" fill="#42A4FF"/><text fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="11" letter-spacing="0em"><tspan x="41" y="46.5">' +
          `${this.$t('_common:file').capitalize()} #1` +
          '</tspan></text><text fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="11" letter-spacing="0em"><tspan x="41" y="73.5">' +
          `${this.$t('_common:file').capitalize()} #2` +
          '</tspan></text><rect x="13" y="87" width="61" height="12" fill="#E2E2E2"/><rect x="13" y="103" width="168" height="6" fill="#E2E2E2"/><rect x="13" y="112" width="151" height="6" fill="#E2E2E2"/><rect x="13" y="3" width="97" height="6" fill="#E2E2E2"/><rect x="14" y="47" width="16" height="5" fill="#FF1515"/><rect x="14" y="74" width="16" height="5" fill="#42A4FF"/></svg>' +
          `<div style="max-width: 193px; padding: 2px 0 8px 0;">
            ${this.$t('hideDocumentAttachmentBlocksTooltip')}
          </div>`,
        placement: 'left',
        html: true
      }
    }
  },
  computed: {
    extraTimeout: {
      get() {
        return this.options.extraTimeout || null
      },
      set(value) {
        if (!value) this.options.extraTimeout = 0
        else this.options.extraTimeout = parseInt(value, 10)
      }
    },
    allowVerification() {
      return this.document && this.document.allowVerification
    },
    showAdminOptions() {
      return config.showAdminSettings || this.isSuperUser
    },
    hasBankIdSignatures() {
      const { clients, senderClient } = this.document
      if (
        senderClient?.socialNo &&
        senderClient.answerMethod?.accept === 'bankId'
      )
        return true

      return clients.some(
        client => client.socialNo && client.answerMethod?.accept === 'bankId'
      )
    }
  },
  created() {
    Object.assign(
      this.options,
      JSON.parse(webStorage.getItem('print-settings'))
    )

    if (!this.allowVerification) this.options.showVerification = false

    this.$watch('options', {
      deep: true,
      handler(v) {
        // Store latest changes
        webStorage.setItem('print-settings', JSON.stringify(v))

        // Return suboptions to their defaults if parent gets disabled
        if (!v.showVerification && !v.showEvents) this.options.showEvents = true
        if (!v.showVerification && !v.showLast4ssn) {
          this.options.showLast4ssn = true
        }
      }
    })
  },
  methods: {
    async generatePdf() {
      if (!this.document || !this.document.publicId) return

      const doneMessage = this.$t('preparingPdfDone')
      const errorMessage = this.$t('preparingPdfError')
      const loadingMessage = this.$t('preparingPdf')
      const downloadText = this.$t('_common:download')

      const { data: jobId } = await pdfJob.addDocument(this.document.publicId, {
        ...this.options,
        brand: config.brand // this.$brand not defined on init
        // locale: this.$i18n.locale,
        // timeZone: get(Intl.DateTimeFormat().resolvedOptions(), 'timeZone') || '',
      })

      let status
      let url

      // Get PDF job and update status/url
      const getPdf = async () => {
        const { data } = (await pdfJob.get(jobId)) || {}
        ;({ status, url } = data)
      }

      // Poll recursive and open pdf url if done
      const poller = async () => {
        try {
          await getPdf() // Update status and url
          if (status === 'canceled')
            throw new Error(
              `Canceled preparing document pdf for publicId: ${this.document.publicId}`
            )
          else if (status === 'completed' && url) {
            const openPdfUrl = () => {
              const win = window.open(url, '_blank')
              if (win) {
                win.focus()
                return true
              }
              return false
            }

            if (!openPdfUrl()) {
              showMessage({
                type: 'success',
                message: doneMessage,
                time: null, // won't close notification after default 6 sec
                closeButton: true,
                actions: {
                  submit: {
                    text: downloadText,
                    callback: openPdfUrl
                  }
                }
              })
            }
            return 'close'
          }
          await wait(3000)
          return poller()
        } catch (err) {
          showMessage({
            type: 'error',
            message: errorMessage
          })
          this.$error(err, { showMessage: false }) // Log error but do not show it to user as we just did
          return 'close'
        }
      }

      await showMessage({
        type: 'spinner',
        message: loadingMessage,
        time: poller,
        closeButton: true
      })
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.options-wrapper {
  max-width: 600px;
  padding: 0 0 2rem 0;
}

.option {
  &-item {
    font-size: 15px;
    &.disabled,
    &__inner.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &__inner {
      padding: 0.5em 0.7em;
      display: flex;
      cursor: pointer;
      border-radius: 0.5rem;
      & > * {
        user-select: none;
        pointer-events: none;
      }
    }
    &__inner__inner {
      padding-left: 2rem;
      border-top: 1px solid hsl(0, 0%, 100%);
    }
  }
}
</style>
