import dateFnsLocales from '@cling/static/dateFnsLocales'

import {
  format as _format,
  formatDistanceToNow as _formatDistanceToNow
} from 'date-fns'
import { enUS, sv } from 'date-fns/locale'

import EventEmitter from './eventEmitter'
import LocaleLookup from './localeLookup'
import { getBestMatch } from './utils'

const locales = {
  sv,
  'en-US': enUS
}

async function loadDateFnsLocale(locale) {
  try {
    const cdn = `https://cdn.jsdelivr.net/npm/date-fns@2.29.3/esm/locale/${locale}/index.js`
    const { default: result } = await import(/* @vite-ignore */ cdn)

    if (result) locales[locale] = result
    return null
  } catch (err) {
    return err
  }
}

const format = (date, formatStr = 'P', locale) => {
  if (!date) return ''
  return _format(new Date(date), formatStr, { locale: locales[locale] })
}

const formatDistanceToNow = (dateToCompare, options, locale) => {
  if (!dateToCompare) return ''
  return _formatDistanceToNow(new Date(dateToCompare), {
    ...options,
    locale: locales[locale]
  })
}

const isIE10 =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.userAgent &&
  window.navigator.userAgent.indexOf('MSIE') > -1

/**
 * Options:
 * locale - specify what locale to use
 * fallbackLocale - locale to use if no valid one is found
 * initImmediate - whether or not to load immediate or rely on nextTick
 */

class L10n extends EventEmitter {
  constructor(options = {}) {
    super()
    if (isIE10) EventEmitter.call(this) // <= IE10 fix (unable to call parent constructor)

    this.options = {
      fallbackLocale: 'en-US',
      ...options
    }
  }

  init(options = {}, callback) {
    this.options = { ...this.options, ...options }
    this.localeLookup = new LocaleLookup(this.options)

    const load = () => {
      this.changeLocale(this.options.locale, { cache: false }, err => {
        this.isInitialized = true
        this.emit('initialized', this.options)
        if (callback) callback(err)
      })
    }
    if (!this.options.initImmediate) load()
    else setTimeout(load, 0)
  }

  changeLocale(locale, options = { cache: true }, callback) {
    this.isLocaleChangingTo = locale
    this.emit('localeChanging', locale)

    // Date-fns supported locale
    const setDateFnsLocale = loc => {
      let _locale = loc
      if (!dateFnsLocales.includes(_locale))
        _locale = getBestMatch(_locale) || this.options.fallbackLocale

      // check if the targeted locale is loaded
      if (locales[_locale]) this.dateFnsLocale = _locale
      else {
        // Set dateFnsLocale to default for a brief period
        // async loads target locale and emits to parent that new locale exists
        this.dateFnsLocale = this.options.fallbackLocale
        loadDateFnsLocale(_locale).then(err => {
          if (!err) {
            this.dateFnsLocale = _locale
            this.emit('localeChanged', _locale)
          }
        })
      }
    }

    // Global App locale
    const setLocale = loc => {
      const l = typeof loc === 'string' ? loc : this.options.fallbackLocale
      this.locale = l
      setDateFnsLocale(l)
      this.emit('localeChanged', l)
      if (options.cache) this.localeLookup.cacheUserLanguage(l)
    }

    let _locale = locale
    if (!_locale) _locale = this.localeLookup.detect()
    setLocale(_locale)

    if (callback) callback(_locale)
  }

  // date-fns methods
  formatDate(
    date,
    formatStr = 'P',
    locale = this.dateFnsLocale || this.locale
  ) {
    return format(date, formatStr, locale)
  }

  formatDistanceToNow(date, options, locale = this.locale) {
    return formatDistanceToNow(date, options, locale)
  }
}

export default new L10n()
