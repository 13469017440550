<template>
  <div id="modals-container">
    <Modal
      v-for="modal in modals"
      v-bind="modal.modalAttrs"
      :key="modal.id"
      v-on="modal.modalListeners"
      @closed="remove(modal.id)"
    >
      <component
        :is="modal.component"
        v-bind="modal.componentAttrs"
        v-on="modal.modalListeners"
        @close="$modal.hide(modal.modalAttrs.name, $event)"
      />
    </Modal>
  </div>
</template>
<script>
import { markRaw } from 'vue'

import Modal from './Modal.vue'
import { generateId } from '../utils'

const PREFIX = 'dynamic_modal_'

export default {
  components: {
    Modal
  },
  data() {
    return {
      modals: []
    }
  },
  created() {
    /**
     * Register ModalContainer so that it was availiable inside the plugin
     */
    this.$root.__modalContainer = this
    this.$modal.subscription.$emit('set-modal-container', this)
  },
  mounted() {
    this.$modal.subscription.$on('hide-all', () => {
      this.modals = []
    })
  },
  methods: {
    add(component, componentAttrs = {}, modalAttrs = {}, modalListeners = {}) {
      const id = generateId()
      const name = modalAttrs.name || PREFIX + id

      this.modals.push({
        id,
        modalAttrs: { ...modalAttrs, name },
        modalListeners,
        component: markRaw(component),
        componentAttrs
      })

      this.$nextTick(() => {
        this.$modal.show(name)
      })
    },
    remove(id) {
      this.$modal.subscription.$emit('remove', id)

      const index = this.modals.findIndex(v => v.id === id)

      if (index !== -1) {
        this.modals.splice(index, 1)
      }
    }
  }
}
</script>
