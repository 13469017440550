<template>
  <div :class="rootClasses" class="control" v-bind="rootAttrs">
    <input
      v-if="type !== 'textarea'"
      v-bind="fallthroughAttrs"
      ref="input"
      :class="[inputClasses, customClass]"
      :style="[inputSuffixOffset, inputPrefixOffset]"
      :type="newType"
      :autocomplete="newAutocomplete"
      :maxlength="maxlength"
      :value="computedValue"
      class="input"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      @keyup.esc="isFocused ? () => $event.stopPropagation() : null"
    />
    <textarea
      v-else
      v-bind="fallthroughAttrs"
      ref="textarea"
      :class="[
        inputClasses,
        customClass,
        {
          autosize: autoResize
        }
      ]"
      :maxlength="maxlength"
      :value="computedValue"
      class="textarea"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      @keyup.esc="isFocused ? () => $event.stopPropagation() : null"
    />
    <div
      v-if="icon"
      :class="{ 'is-clickable': iconClickable }"
      class="is-left icon"
      @click="iconClick('icon-click', $event)"
    >
      <CIcon v-bind="{ size: iconSize, ...iconProps }" :type="icon" />
    </div>
    <div
      v-if="!loading && hasIconRight"
      :class="{ 'is-clickable': passwordReveal || iconRightClickable }"
      class="is-right icon"
      @click="rightIconClick"
    >
      <CIcon
        v-bind="{ size: iconSize, ...iconProps }"
        :type="rightIcon"
        :class="statusTypeIconColor"
      />
    </div>
    <div v-if="$slots.prefix" class="prefix" :class="prefixClass">
      <slot name="prefix" />
    </div>
    <div v-if="$slots.suffix" class="suffix" :class="suffixClass">
      <slot name="suffix" />
    </div>
  </div>
</template>

<script>
import CIcon from '@cling/components/ui/Icon'
import CompatFallthroughMixin from '@cling/components/ui/utils/CompatFallthroughMixin'
import FormElementMixin from '@cling/components/ui/utils/FormElementMixin'

export default {
  name: 'CInput',
  components: {
    CIcon
  },
  mixins: [CompatFallthroughMixin, FormElementMixin],
  props: {
    modelValue: {
      type: [Number, String],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    passwordReveal: Boolean,
    iconClickable: Boolean,
    hasCounter: {
      type: Boolean,
      default: () => true // config.defaultInputHasCounter,
    },
    customClass: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    },
    iconRightClickable: Boolean,
    filled: Boolean,
    expanded: Boolean,
    autoResize: Boolean,
    reset: Boolean,
    static: Boolean,
    iconProps: {
      type: Object,
      default: () => ({})
    },
    prefixClass: {
      type: String,
      default: ''
    },
    suffixClass: {
      type: String,
      default: ''
    }
  },
  emits: ['icon-click', 'icon-right-click', 'update:modelValue'],
  data() {
    return {
      newValue: this.modelValue,
      newType: this.type,
      newAutocomplete: this.autocomplete || 'on', // config.defaultInputAutocomplete,
      isPasswordVisible: false,
      _elementRef: this.type === 'textarea' ? 'textarea' : 'input',
      inputPrefixOffset: null,
      inputSuffixOffset: null
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('update:modelValue', value)
        !this.isValid && this.checkHtml5Validity()
      }
    },
    rootClasses() {
      return [
        this.iconPosition,
        `is-${this.size}`,
        {
          'is-expanded': this.expanded,
          'is-loading': this.loading,
          'is-clearfix': !this.hasMessage
        }
      ]
    },
    inputClasses() {
      return [
        this.statusType,
        `is-${this.size}`,
        {
          'is-rounded': this.rounded,
          'is-filled': this.filled,
          'is-reset': this.reset,
          'is-static': this.static
        }
      ]
    },
    hasIconRight() {
      return (
        this.passwordReveal ||
        this.loading ||
        this.statusTypeIcon ||
        this.iconRight
      )
    },
    rightIcon() {
      if (this.passwordReveal) {
        return this.passwordVisibleIcon
      } else if (this.statusTypeIcon) {
        return this.statusTypeIcon
      }
      return this.iconRight
    },
    rightIconType() {
      if (this.passwordReveal) {
        return 'is-primary'
      } else if (this.statusTypeIcon) {
        return this.statusType
      }
      return null
    },
    /**
     * Position of the icon or if it's both sides.
     */
    iconPosition() {
      if (this.icon && this.hasIconRight) {
        return 'has-icons-left has-icons-right'
      } else if (!this.icon && this.hasIconRight) {
        return 'has-icons-right'
      } else if (this.icon) {
        return 'has-icons-left'
      }
      return ''
    },
    /**
     * Icon name (MDI) based on the type.
     */
    statusTypeIcon() {
      switch (this.statusType) {
        case 'is-success':
          return 'check'
        case 'is-danger':
          return 'alert-triangle'
        case 'is-info':
          return 'information'
        case 'is-warning':
          return 'alert'
        default:
          return ''
      }
    },
    statusTypeIconColor() {
      switch (this.statusType) {
        case 'is-success':
          return 'text-green-500'
        case 'is-danger':
          return 'text-red-500'
        case 'is-info':
          return 'text-blue-500'
        case 'is-warning':
          return 'text-orange-500'
        default:
          return ''
      }
    },
    /**
     * Check if have any message prop from parent if it's a Field.
     */
    hasMessage() {
      return !!this.statusMessage
    },
    /**
     * Current password-reveal icon name.
     */
    passwordVisibleIcon() {
      return !this.isPasswordVisible ? 'eye' : 'eye-off'
    },
    /**
     * Get value length
     */
    valueLength() {
      if (typeof this.computedValue === 'string') {
        return this.computedValue.length
      } else if (typeof this.computedValue === 'number') {
        return this.computedValue.toString().length
      }
      return 0
    }
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set internal value.
     *   2. Validate it if the value came from outside;
     *      i.e., not equal to computedValue
     */
    modelValue(value) {
      const fromOutside = this.computedValue != value
      this.newValue = value
      if (fromOutside) {
        // validation must wait for DOM updated
        this.$nextTick(() => {
          !this.isValid && this.checkHtml5Validity()
        })
      }
    }
  },
  mounted() {
    if (
      this.type === 'textarea' &&
      this.autoResize &&
      this.$refs.textarea.scrollHeight !== 0
    ) {
      this.$nextTick(() => {
        this.$refs.textarea.setAttribute(
          'style',
          `height: ${this.$refs.textarea.scrollHeight}px`
        )
      })
    }
    if (this.$slots.prefix) {
      this.inputPrefixOffset = this.calcInputPrefixOffset()
    }
    if (this.$slots.suffix) {
      this.inputSuffixOffset = this.calcInputSuffixOffset()
    }
  },
  methods: {
    /**
     * Toggle the visibility of a password-reveal input
     * by changing the type and focus the input right away.
     */
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible
      this.newType = this.isPasswordVisible ? 'text' : 'password'
      this.$nextTick(() => {
        this.$refs[this.$data._elementRef].focus()
      })
    },
    /**
     * Input's 'input' event listener, 'nextTick' is used to prevent event firing
     * before ui update, helps when using masks (Cleavejs and potentially others).
     */
    onInput(event) {
      if (event.target) this.computedValue = event.target.value

      this.$nextTick(() => {
        if (event.target) {
          if (this.autoResize && this.type === 'textarea') {
            this.resize(event)
          }
        }
      })
    },
    iconClick(emit, event) {
      this.$emit(emit, event)
      this.$nextTick(() => {
        this.$refs[this.$data._elementRef].focus()
      })
    },
    rightIconClick(event) {
      if (this.passwordReveal) {
        this.togglePasswordVisibility()
      } else if (this.iconRightClickable) {
        this.iconClick('icon-right-click', event)
      }
    },
    resize({ target }) {
      target.style.height = '1px'
      target.style.height = `${target.scrollHeight}px`
    },
    calcInputSuffixOffset() {
      if (!this.$slots.suffix) return null
      return {
        paddingRight: `${this.$el.querySelector('.suffix').offsetWidth + 10}px`
      }
    },
    calcInputPrefixOffset() {
      if (!this.$slots.prefix) return null
      return {
        paddingLeft: `${this.$el.querySelector('.prefix').offsetWidth + 10}px`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.autosize.textarea.textarea.textarea.textarea {
  resize: none;
  overflow: hidden;
  min-height: inherit;
  text-rendering: optimizeSpeed;
}
.prefix,
.suffix {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  transition: none;
  z-index: 3;
}

.prefix {
  left: 0.5em;
}

.suffix {
  right: 0.5em;
}
</style>
