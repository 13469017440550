<template>
  <label
    ref="label"
    :class="[
      `is-${size}`,
      {
        'is-disabled': disabled
      }
    ]"
    :disabled="disabledOrUndefined"
    class="c-checkbox checkbox"
    @click="focus"
    @keydown.prevent.enter="$refs.label.click()"
  >
    <input
      ref="input"
      v-model="computedValue"
      :indeterminate.prop="indeterminate"
      :disabled="disabledOrUndefined"
      :required="requiredOrUndefined"
      :name="name"
      :value="nativeValue"
      :true-value="trueValue"
      :false-value="falseValue"
      type="checkbox"
      @click.stop
    />
    <span
      :class="[
        `is-${type}`,
        {
          'is-inverted': inverted
        }
      ]"
      class="check"
    >
      <svg viewBox="0 0 30 30">
        <polyline points="21 8 12 20 7 15" />
      </svg>
    </span>
    <span v-if="$slots.default" class="control-label"><slot /></span>
  </label>
</template>

<script>
import CheckRadioMixin from '@cling/components/ui/utils/CheckRadioMixin'

export default {
  name: 'CCheckbox',
  mixins: [CheckRadioMixin],
  props: {
    indeterminate: Boolean,
    trueValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: true
    },
    falseValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: false
    },
    inverted: Boolean
  }
}
</script>
<style lang="scss">
@import '@cling/styles/theme/utilities/_all.sass';

$checkbox-active-background-color: $primary !default;
$checkbox-background-color: hsla(0, 0%, 0%, 0.14); // $border !default;
$checkbox-border-color: transparent; // $border !default;
$checkbox-border-radius: $radius !default;
$checkbox-border-width: 2px !default;
$checkbox-checkmark-color: $primary-invert !default;
$checkbox-size: 1.25em !default;
$speed-slow: 100ms;

.c-checkbox {
  &.checkbox {
    @extend %unselectable;
    outline: none;
    display: inline-flex;
    align-items: center;
    & + .checkbox {
      margin-left: 0.5em;
    }
    input[type='checkbox'] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
      + .check {
        width: $checkbox-size;
        height: $checkbox-size;
        flex-shrink: 0;
        border-radius: 0.35em;
        border: $checkbox-border-width solid $checkbox-border-color;
        transition: background $speed-slow $easing;
        background: $checkbox-background-color;
        color: $white;
        svg {
          left: 0;
          top: 0;
          width: 1em; // $checkbox-size;
          height: 1em; // $checkbox-size;
          stroke-width: 4.5;
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: currentColor;
          polyline {
            transition: stroke-dashoffset 0.1s ease;
            stroke-dasharray: 25;
            stroke-dashoffset: -25;
          }
        }
      }
      &:checked + .check {
        background: $checkbox-active-background-color;
        border-color: $checkbox-active-background-color;
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          $color-invert: nth($pair, 2);
          &.is-#{$name} {
            background: $color no-repeat center center;
            border-color: $color;
            color: $color-invert;
          }
        }
        svg polyline {
          stroke-dashoffset: 0;
        }
        // Modified inverted version with only checked
        // Use in project task list
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          $color-invert: nth($pair, 2);
          &.is-inverted.is-#{$name} {
            background: $color-invert no-repeat center center;
            border-color: $color-invert;
            color: $color;
          }
        }
      }
      &:indeterminate + .check {
        background: $checkbox-active-background-color;
        border-color: $checkbox-active-background-color;
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          $color-invert: nth($pair, 2);
          &.is-#{$name} {
            background: $color no-repeat center center;
            border-color: $color;
          }
          color: $color-invert;
        }
        svg polyline {
          stroke-dashoffset: 0;
        }
      }
      &:focus {
        + .check {
          box-shadow: 0 0 0 0.15em rgba($border-hover, 0.15);
        }
        &:checked + .check {
          box-shadow: 0 0 0 0.15em rgba($checkbox-active-background-color, 0.15);
          @each $name, $pair in $colors {
            $color: nth($pair, 1);
            &.is-#{$name} {
              box-shadow: 0 0 0 0.15em rgba($color, 0.15);
            }
          }
        }
      }
    }
    .control-label {
      padding-left: 0.5em;
      font-size: 1em;
    }
    &.button {
      display: flex;
    }
    &[disabled] {
      opacity: 0.5;
    }
    &:hover {
      input[type='checkbox']:not(:disabled):not(:checked) + .check {
        background-color: scale-color(
          $color: $checkbox-background-color,
          $alpha: 20%
        );
      }
    }
    &.is-transparent-dark
      input[type='checkbox']:not(:disabled):not(:checked)
      + .check {
      background-color: hsla(0, 0%, 0%, 0.15);
      border-color: transparent;
    }
    &.is-tiny {
      @include control-tiny;
      border-radius: $control-radius-small;
      font-size: 12px;
    }
    &.is-small {
      @include control-small;
    }
    &.is-normal,
    &.is-default {
      @include control-normal;
    }
    &.is-medium {
      @include control-medium;
    }
    &.is-large {
      @include control-large;
    }
  }
}

.c-checkbox.checkbox input[type='checkbox']:checked + .check.is-primary,
.c-checkbox.checkbox input[type='checkbox']:checked + .check.is-secondary {
  @apply bg-primary-500 border-primary-500 text-white;
}

.c-checkbox.checkbox
  input[type='checkbox']:checked
  + .check.is-inverted.is-primary,
.c-checkbox.checkbox
  input[type='checkbox']:checked
  + .check.is-inverted.is-secondary {
  @apply text-primary-500 border-white bg-white;
}

.c-checkbox.checkbox input[type='checkbox']:indeterminate + .check.is-primary,
.c-checkbox.checkbox
  input[type='checkbox']:indeterminate
  + .check.is-secondary {
  @apply bg-primary-500 border-primary-100;
}

.c-checkbox.checkbox input[type='checkbox']:focus:checked + .check.is-primary,
.c-checkbox.checkbox
  input[type='checkbox']:focus:checked
  + .check.is-secondary {
  @apply ring-primary-100 ring-2;
}
</style>
