import config from '@cling/config'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  sign: 'unterschreiben',
  accept: 'Akzeptieren',
  address: 'Adresse',
  and: 'und',
  answerFeedback: {
    intermediate: {
      title: 'Danke, dass Sie Ihre Gedanken mit uns teilen.'
    },
    no: {
      start: {
        action: 'Abschicken',
        placeholder: 'Was sollten wir verbessern?',
        text: 'Gibt es etwas Bestimmtes, das Sie uns mitteilen möchten, das wir verbessern können?',
        title: 'Vielen Dank für Ihren Beitrag.'
      },
      success: {
        action: 'Erfahren Sie mehr über $t(brand.name)',
        secondary: 'Gehen Sie zurück zum Dokument',
        text: 'Ihr Feedback wurde erfolgreich abgeschickt. Unser Team wird Ihr Feedback mit großer Sorgfalt prüfen.',
        title: 'Vielen Dank.'
      }
    },
    start: {
      no: 'Nein',
      title:
        'Hat es Ihnen Spaß gemacht, $t(brand.name) zur Anzeige dieses Dokuments zu verwenden?',
      yes: 'Ja'
    },
    yes: {
      action: 'Melden Sie sich kostenlos an',
      secondary: 'Erfahren Sie mehr über $t(brand.name)',
      text: 'Erstellen und versenden Sie schöne Dokumente mit $t(brand.name). Entdecken Sie alle Vorteile des Versands webbasierter Dokumente mit elektronischen Signaturen.',
      title: 'Nutzen Sie $t(brand.name) kostenlos.'
    },
    custom: {
      title: 'Vielen Dank.',
      text: 'Ihr Feedback wurde erfolgreich übermittelt. Unser Team wird Ihr Feedback mit großer Sorgfalt prüfen.',
      action: 'Gehen Sie zurück zum Dokument'
    }
  },
  answerModal: {
    answerSuccess: 'Ihre Antwort wurde erfolgreich gesendet.',
    bankId: {
      component: {
        defaultError:
          'Etwas ist schief gelaufen. Vergewissern Sie sich, dass Sie Ihre Sozialversicherungsnummer korrekt eingegeben haben und versuchen Sie es erneut.',
        signingInterrupted:
          'Die Unterschrift wurde unterbrochen, bitte versuchen Sie es erneut...',
        startOnThisDevice: 'BankID auf diesem Gerät starten',
        validations: {
          socialNo: {
            personalNumber: 'Ungültige Sozialversicherungsnummer',
            required: 'Geben Sie Ihre Sozialversicherungsnummer ein'
          }
        },
        viewText: {
          canceled: {
            text: 'Unterzeichnung abgebrochen. Bitte versuchen Sie es erneut',
            title: 'Unterzeichnung unterbrochen'
          },
          error: {
            text: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            title: 'Es ist ein Fehler aufgetreten'
          },
          form: {
            text: 'Geben Sie Ihre Sozialversicherungsnummer ein, um mit der Unterzeichnung der Vereinbarung zu beginnen.',
            title: 'Unterschreiben Sie das Dokument mit BankID'
          },
          new: {
            spinnerText: 'Scannen Sie den QR-Code auf Ihrem Gerät.',
            text: 'Öffnen Sie BankID auf Ihrem Gerät und unterschreiben Sie die Vereinbarung.',
            title: 'Unterschreiben Sie das Dokument'
          },
          signing: {
            spinnerText: 'Unterschreiben Sie das Dokument.',
            text: 'Öffnen Sie BankID auf Ihrem Gerät und unterzeichnen Sie die Vereinbarung.',
            title: 'Unterzeichnung läuft'
          },
          waiting: {
            spinnerText: 'Ich warte darauf, dass Sie BankID öffnen.',
            text: 'Öffnen Sie BankId auf Ihrem Gerät und unterschreiben Sie die Vereinbarung.',
            title: 'Unterschreiben Sie das Dokument'
          }
        }
      },
      start: {
        action: 'Unterschreiben mit BankID',
        text: 'Akzeptieren und signieren Sie das Dokument mit BankID.',
        title: 'Unterschreiben mit BankID'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    decline: {
      component: {
        label: 'Kommentar (optional)'
      },
      start: {
        action: 'Ablehnen',
        text: 'Um dieses Dokument abzulehnen, bestätigen Sie bitte, indem Sie auf die Schaltfläche unten klicken',
        title: 'Dokument ablehnen'
      },
      success: {
        text: 'Danke, dass Sie eine Antwort hinterlassen haben.',
        textFallback: '$t(answerModal.decline.success.text)',
        title: 'Sie haben das Dokument abgelehnt.'
      }
    },
    signature: {
      component: {
        draw: {
          canvasLabel: 'Signatur zeichnen',
          tab: 'Zeichnen'
        },
        subTitle: 'Unterschriftsmethode wählen',
        terms:
          'Mit meiner Unterschrift erkläre ich mich damit einverstanden, dass die verwendete elektronische Signatur eine Darstellung meiner handschriftlichen Unterschrift ist - und ebenso gültig ist.',
        text: {
          canvasLabel: 'Vorschau',
          nameLabel: 'Unterzeichnet von',
          namePlaceholder: 'Geben Sie Ihren Namen ein',
          tab: 'Typ'
        }
      },
      start: {
        action: 'Unterschreiben & Akzeptieren',
        text: '',
        title: 'Dokument akzeptieren'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    versionError: {
      accept: { title: 'Dokument konnte nicht unterzeichnet werden' },
      decline: { title: 'Dokument konnte nicht abgelehnt werden' },
      text: 'Das Dokument wurde während Ihrer Ansicht geändert. Bitte laden Sie es erneut und überprüfen Sie das neue Dokument, um zu antworten.',
      btn: 'Neu laden'
    },
    successText:
      'Sobald das Dokument von allen beteiligten Parteien unterzeichnet wurde, erhalten Sie eine E-Mail an {{email}} mit einer versiegelten Kopie des Dokuments.',
    successTextFallback:
      'Sobald das Dokument von allen beteiligten Parteien unterzeichnet wurde, können Sie zu dieser Webseite zurückkehren, um eine versiegelte und vollständige Kopie des Dokuments herunterzuladen.',
    successTextFallbackSingle:
      'Das Dokument ist nun unterzeichnet und vervollständigt.',
    successTextSingle:
      'Es wurde eine Bestätigungs-E-Mail an {{email}} mit einer versiegelten Kopie des Dokuments gesendet.',
    successTitle: 'Sie haben das Dokument signiert.'
  },
  approved: 'genehmigt',
  approx: 'ungefähr',
  cancel: 'Abbrechen',
  chapters: {
    answerButtons: {
      approveDescription:
        'Wir haben {{- sender}} informiert, dass das Dokument genehmigt wurde.',
      approveTitle: 'Das Dokument wurde genehmigt!',
      denyDescription:
        'Wir haben {{- sender}} informiert, dass Sie nicht interessiert sind.',
      denyTitle: 'Das Dokument wurde abgelehnt',
      expiredTitle: 'Das Dokument ist abgelaufen',
      expiredDescription:
        'Das Verfallsdatum des Dokuments ist abgelaufen. Es ist nicht mehr möglich, das Dokument zu unterschreiben.',
      editPropDes: 'Eigentumsbezeichnung bearbeiten',
      enterPropDes: 'Eigentumsbezeichnung eingeben',
      inPersonSignatureHint:
        'Es wurde eingestellt, dass Sie das Dokument in Person unterschreiben müssen. Wenn Sie stattdessen eine digitale Kopie unterschreiben möchten kontaktieren Sie uns bitte.',
      waitingDescription:
        'Es wird gewartet bis {{remaining}} Andere eine Antwort gegeben haben.',
      youHave: 'Sie haben '
    },
    articleItem: {
      eligibleForDeduction: 'Berechtigt für {{type}}-Rabatt'
    },
    articleList: {
      changeShowVatTooltip: 'Umschalten zum anzeigen von {{type}}',
      title: 'Services / Produkte'
    },
    packageGroup: {
      errorCallout: 'Wählen Sie mindestens eine Option',
      selectableOptions: 'Wählbare Optionen'
    },
    packageItem: {
      add: 'Option hinzufügen',
      select: 'Option auswählen',
      selected: 'Ausgewählt'
    },
    parties: {
      allRiskAndLiability: 'Hat alle Risiko- und Haftpflichtversicherungen',
      fTax: 'Zertifiziert für F-tax'
    },
    pricingSummary: {
      blocks: {
        approx: 'Ungefährer Preis',
        discount: 'Rabatt',
        deductionSubValue: 'Gesamtpreis ohne Rabatt {{price}}',
        maxPrice: 'Maximaler Preis',
        maxPriceVat: 'Maximaler Preis {{vat}}',
        net: 'Netto',
        price: 'Preis',
        rounding: 'Gerundet',
        subTitleArticles: 'Bei Produkten/Dienstleistungen mit fixen Kosten',
        summary: 'Zusammenfassung',
        total: 'Gesamt',
        typeDeduction: 'Nach {{type}}-Rabatt'
      },
      placeholder: {
        description:
          'Wählen Sie die Option(en) die Ihnen am besten passen, durch Klicken auf ',
        descriptionBold: 'Auswählen',
        title: 'Wählen Sie eine Option'
      },
      terms: {
        houseWork:
          'Der Gesamtpreis nach Steuerabzug für {{type}}-Arbeiten setzt voraus, dass die geleisteten Arbeiten steuerlich absetzbar sind, dass der Käufer einen ausreichenden Betrag für den Steuerabzug zur Verfügung hat und dass dieser nicht für andere {{type}}-Arbeiten verwendet wurde. Der Käufer ist für die Kontrolle dieser Informationen verantwortlich.\n',
        houseWorkApprox:
          'Der Preis ist eine Schätzung. Denken Sie daran, dass der Preis um bis zu 15% schwanken kann. Wenn die Dienstleistungen Boden-/Abbrucharbeiten oder ähnliches umfassen. Denken Sie daran, dass der Preis um bis zu 25 % schwanken kann.',
        reverseVat:
          'Hinweis: Die umgekehrte Steuerschuldnerschaft für Bauleistungen gilt.'
      }
    },
    termsDisclaimer: {
      description: 'Durch genehmigen des Dokuments stimmen Sie den ',
      termsAndConditions: 'Allgemeinen Geschäftsbedingungen zu',
      title: 'AGBs'
    }
  },
  close: 'Schließen',
  company: 'Firma',
  deduction: {
    after: 'Nach Rabatt',
    afterType: 'Nach {{type}}-Rabatt',
    deduction: 'Rabatt',
    greenRot15: 'Grün ROT',
    greenRot15_percentage: 'Grün ROT 15%',
    greenRot20: 'Grün ROT',
    greenRot20_percentage: 'Grün ROT 20%',
    greenRot50: 'Grün ROT',
    greenRot50_percentage: 'Grün ROT 50%',
    manualType: 'Individueller {{type}}-Rabatt',
    rot: 'ROT',
    rot_percentage: 'ROT',
    rut: 'RUT',
    rut_percentage: 'RUT',
    type: '{{type}}-Rabatt'
  },
  denied: 'Abgelehnt',
  deny: 'Ablehnen',
  denyModal: {
    denyButton: 'Dokument ablehnen',
    helpfulInformation:
      'Wir informieren {{- senderName}} bei {{- senderCompany}}, dass Sie das Dokument abgelehnt haben.',
    otherPlaceholder: 'Optional. Geben Sie einen Kommentar zur Ablehnung.',
    subTitle: 'Bestätigen Sie dies durch Klicken auf den Ablehnen Button.',
    successTitle: 'Sie haben dieses Dokument abgelehnt',
    thanksAnswer: 'Vielen Dank für Ihre Antwort!',
    title: 'Dokument ablehnen'
  },
  detail_plural: 'Details',
  document: 'Dokument',
  document_plural: 'Dokumente',
  documentDetails: {
    subTitle: 'Informationen über das Dokument.'
  },
  documentVoided: {
    title: 'Das Dokument ist nicht mehr verfügbar.',
    description:
      'Der Absender hat sich entschieden, das Dokument zu stornieren.'
  },
  documentTemplateView: {
    emailTemplate: {
      expires: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.expires.title) $t(documentTemplateView.emailTemplate.expires.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      first: {
        content:
          'Sie haben ein Dokument von {{company.name}} erhalten. Klicken Sie auf nachstehenden Link, um mehr zu lesen und Ihre Antwort zu hinterlassen.',
        subject: '$t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.first.title) $t(documentTemplateView.emailTemplate.first.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.greetingName)'
      },
      reminder: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.reminder.title) $t(documentTemplateView.emailTemplate.reminder.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      viewed: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.viewed.title) $t(documentTemplateView.emailTemplate.viewed.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      }
    },
    greetingName: 'Hallo{{clientFirstName}}!',
    readMore: 'Mehr lesen:',
    readMoreAndReply: 'Lesen Sie mehr & antworten Sie unter:',
    reminder: 'Zur Erinnerung!',
    sharedSubject: 'Dokument von {{company.name}} - {{document.data.name}}',
    smsContent:
      '{{company.name}} hat Ihnen ein Dokument gesendet auf das Sie antworten können.',
    smsTemplate: {
      expires: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      first: {
        text: '$t(documentTemplateView.greetingName)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      reminder: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      viewed: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      }
    }
  },
  email: 'E-Mail',
  enterYour: 'Geben Sie Ihr {{thing}} ein',
  event: {
    accepted: 'Dokument unterschrieben von {{by}}.',
    denied: 'Dokument abgelehnt von {{by}}.',
    event_plural: 'Veranstaltungen',
    expired: 'Dokument abgelaufen.',
    requestChange: 'Dokument manuell als Status {{status}} markiert.',
    sent: 'Dokument an {{to}} gesendet.',
    statusChanged: 'Dokument markiert als {{status}}.',
    viewed: 'Wurde angesehen',
    viewedName: '{{name}} hat das Dokument angesehen.'
  },
  goBack: 'Zurückgehen',
  less: 'weniger',
  login: 'Anmelden',
  logout: 'Abmelden',
  myPages: {
    docCallout: {
      form: {
        text: 'Über die Dokumentenbibliothek können Sie alle Dokumente einsehen, die Sie erhalten haben und die mit Ihrer E-Mail verbunden sind.',
        title: 'Dokumentenbibliothek'
      },
      loginLink: 'Anmeldelink',
      goToMyPagesTitle: 'Zu Ihren Dokumentseiten gehen'
    },
    documentItem: {
      approved: 'Genehmigt',
      expired: 'Abgelaufen'
    },
    documentsPlaceholder: 'Sie haben keine Dokumente erhalten.',
    pageDescription: 'Hier finden Sie alle Ihre empfangenen Dokumente.',
    requestForm: {
      alreadySentDescription:
        'Ein Anmeldelink wurde bereits an {{email}} gesendet. Es kann eine Minute dauern, bis Sie ihn erhalten.',
      alreadySentTitle: 'Anmeldelink wurde bereits gesendet',
      emailFormat: 'Gültiges Format: name@example.com',
      emailRequired: 'Geben Sie Ihre E-Mail ein',
      sendLink: 'Anmeldelink senden',
      sentDescription:
        'Wir haben einen Link an {{email}} gesendet. Verwenden Sie den Link, um sich anzumelden.',
      sentDescriptionSub:
        'Aus Sicherheitsgründen können Sie sich nur über den Anmeldelink anmelden.',
      sentTitle: 'Anmeldelink an Ihren Posteingang gesendet'
    },
    title: 'Meine Seiten'
  },
  name: 'Name',
  orgNoShort: 'Co. Registrierungsnummer',
  participant_plural: 'Teilnehmer',
  sender: 'absender',
  phone: 'Telefon',
  price: {
    approx: 'Ungefährer Preis',
    discount: 'Rabatt',
    fixed: 'Fixer Preis',
    markup: 'Handelsspanne',
    maxPrice: 'Maximaler Preis',
    net: 'Netto gesamt',
    openAcc: 'Konto öffnen',
    openAccMaxPrice: 'Konto öffnen mit Maximalem Preis',
    openAccPrice: 'Konto öffnen',
    price: 'Preis',
    price_plural: 'Preise',
    pricing: 'Preisgestaltung',
    proposal: 'Packetoptionen',
    total: 'Gesamter Preis'
  },
  print: '{{thing,spaceAfter}}drucken',
  download: '{{thing,spaceAfter}}herunterladen',
  reference: 'Referenz',
  replyBy: 'Antworten über',
  see: 'Ansehen',
  select: '{{thing,spaceAfter}}auswählen',
  send: '{{thing,spaceAfter}}senden',
  series: {
    ata: 'Vereinbarung einer Einhaltung',
    contract: 'Vertrag',
    default: 'Dokument',
    document: 'Dokument',
    proposal: 'Vorschlag'
  },
  show: '{{thing,spaceAfter}}anzeigen ',
  socialNo: 'Sozialversicherungsnummer',
  status: {
    accepted: 'Akzeptiert',
    active: 'Aktiv',
    archived: 'Archiviert',
    expired: 'Abgelaufen',
    voided: 'Entwertet',
    completed: 'Abgeschlossen',
    denied: 'Abgelehent',
    draft: 'Entwurf',
    new: 'Entwurf',
    semiSigned: 'Teilweise unterschrieben',
    sent: 'Gesendet',
    signed: 'Unterschrieben'
  },
  summaryBottom: {
    chooseFrom: 'Wählen Sie aus den wählbaren Optionen',
    forDetails: 'Für Details',
    noOfOptions: '{{count}}',
    option: 'Option',
    option_plural: 'Optionen',
    reverseVat: 'Mwst. {{- amount}}. Umgekehrte Steuerschuldnerschaft gilt'
  },
  theDocument: 'Das Dokument',
  theSender: 'Der Versender',
  times: 'Zeit',
  times_plural: 'Zeiten',
  total: 'Gesamt',
  tryAgain: 'Erneut versuchen',
  unitType: {
    day: 'Tag',
    hour: 'h',
    kg: 'kg',
    km: 'km',
    kWh: 'kWh',
    litre: 'liter',
    m: 'm',
    m2: 'm²',
    m3: 'm³',
    mile: 'Meile',
    minute: 'min',
    month: 'mon',
    second: 's',
    ton: 'Tonne',
    unit: 'St',
    unitType: 'Gerätetyp',
    unitType_plural: 'Gerätetypen',
    week: 'Woche',
    week_plural: 'Wochen',
    year: 'Jahr'
  },
  vat: {
    exVat: 'ex. {{- thing}}',
    incVat: 'inkl. {{- thing}}',
    rate: 'Mwst  / Steuersatz',
    reverseVat: 'Steuerschuldschaft umkehren',
    reverseVatApplies: 'Umgekehrte Steuerschuldschaft gilt',
    sharedTitle: 'Mwst./Steuer',
    tax: 'Steuer',
    vat: 'Mwst',
    vatIdentificationNumberShort: 'Mwst. Identifizierungsnummer.'
  }
}
