export default {
  install(app, { store } = {}) {
    app.mixin({
      computed: {
        $viewSettings() {
          return store.state.application.viewSettings
        }
      }
    })
  }
}
