<template>
  <div
    style="
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
    "
  >
    <div
      style="
        flex: 1 1 40%;
        width: 62px;
        margin: 7px 5px;
        position: relative;
        display: flex;
        height: 26px;
        align-items: center;
      "
    >
      <div
        style="
          overflow: hidden;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          pointer-events: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          user-select: none;
        "
      >
        {{ label }}
      </div>
      <!-- eslint-disable-next-line -->
      <svg
        v-if="infoTooltip"
        v-tooltip="infoTooltip"
        class="info-tooltip"
        width="13"
        height="13"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="square"
        stroke-linejoin="bevel"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12" y2="8"></line>
      </svg>
    </div>
    <div style="flex: 1 1 60%; margin: 7px 0 7px 0; width: 1px; display: flex">
      <template v-for="(input, i) in inputs" :key="i">
        <div
          v-if="input.component === 'input'"
          :class="{ 'input-wrapper': input.props.type !== 'range' }"
        >
          <input
            v-bind="input.props"
            :class="{
              slider: input.props.type === 'range'
            }"
            :style="[
              input.props.type === 'range' && {
                '--progress': `${
                  ((input.props.value - input.props.min) * 100) /
                  (input.props.max - input.props.min)
                }%`
              }
            ]"
            @input="input.onInput ? input.onInput($event.target.value) : null"
            @keypress="
              input.props.type === 'number'
                ? onKeyPressNumberRestrict($event)
                : null
            "
          />
        </div>
        <div
          v-else-if="input.component === 'inputSelect'"
          :key="i + 'input'"
          class="input-wrapper"
        >
          <input
            v-bind="input.props.input"
            style="
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              margin-right: 1px;
            "
            @input="
              input.props.input.onInput
                ? input.props.input.onInput($event.target.value)
                : null
            "
            @keypress="
              input.props.input.type === 'number'
                ? onKeyPressNumberRestrict($event)
                : null
            "
          />
          <div class="select-wrapper">
            <select
              v-bind="input.props.select"
              style="
                border-left: 1px solid #ddd;
                width: 20px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              "
              @input="
                input.props.select.onInput
                  ? input.props.select.onInput($event.target.value)
                  : null
              "
            >
              <option
                v-for="opt in input.props.select.options"
                :key="opt.value"
                :value="opt.value"
                :selected="opt.value === input.props.select.value"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-else-if="input.component === 'step'"
          :key="i + 'step'"
          class="input-wrapper"
        >
          <button
            style="
              width: 50%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            "
            @click="input.onDecrease"
          >
            <CIcon size="15" type="minus" style="opacity: 0.85" />
          </button>
          <div
            style="
              width: 1px;
              height: 100%;
              background-color: hsl(0, 0%, 86%);
              margin: 0 1px;
            "
          />
          <button
            style="
              width: 50%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            "
            @click="input.onIncrease"
          >
            <CIcon size="15" type="plus" style="opacity: 0.85" />
          </button>
        </div>
        <div
          v-else-if="input.component === 'select'"
          :key="i + 'select'"
          class="input-wrapper"
        >
          <select
            v-bind="input.props"
            @input="input.onInput ? input.onInput($event.target.value) : null"
          >
            <option
              v-for="opt in input.props.options"
              :key="opt.value"
              :value="opt.value"
              :selected="opt.value === input.props.value"
            >
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div
          v-else-if="input.component === 'color'"
          :key="i + 'color'"
          class="input-wrapper"
        >
          <CDropdown
            :menu-styling="{ width: '240px' }"
            :position="input.props.position || 'is-top-right'"
            paddingless
            expanded
            style="width: 100%"
          >
            <template #trigger>
              <div
                style="
                  height: 26px;
                  display: flex;
                  align-items: center;
                  flex-wrap: nowrap;
                  overflow: hidden;
                  border-radius: 5px;
                  font-size: 13px;
                  cursor: pointer;
                "
              >
                <div
                  :style="{ 'background-color': input.props.value }"
                  style="
                    height: 20px;
                    width: 26px;
                    margin: 0px 3px;
                    border-radius: 3px;
                    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
                  "
                />
                <div style="padding: 0 5px">{{ input.props.value }}</div>
                <div
                  v-if="input.props.value && input.props.showReset"
                  class="plain-button"
                  style="
                    position: absolute;
                    width: 24px;
                    right: 2px;
                    display: grid;
                    place-items: center;
                    top: 2px;
                    bottom: 2px;
                    border-radius: 4px;
                  "
                  @click.prevent.stop="input.onInput('')"
                >
                  <CIcon type="reset" size="14" stroke-width="2.5" />
                </div>
              </div>
            </template>
            <ColorPicker
              v-bind="input.props"
              style="box-shadow: none"
              :model-value="input.props.value"
              @update:modelValue="
                ({ a, hex, hex8 }) =>
                  input.onInput && input.onInput(a === 1 ? hex : hex8)
              "
            />
          </CDropdown>
        </div>
        <div
          v-else-if="input.component === 'font'"
          :key="i + 'font'"
          class="input-wrapper"
        >
          <FontPickerDropdown
            v-bind="input.props"
            :model-value="input.props.value"
            class="font-input-wrapper"
            @update:modelValue="v => input.onInput && input.onInput(v)"
          >
            <template #default="{ selectedFamily }">
              <button class="truncate-text">
                <span style="width: 90%; display: block">
                  {{ selectedFamily }}
                </span>
              </button>
            </template>
          </FontPickerDropdown>
        </div>
        <div
          v-else-if="input.component === 'currency'"
          :key="i + 'currency'"
          class="input-wrapper"
        >
          <CCurrencyDropdown
            v-bind="input.props"
            :model-value="input.props.value"
            class="currency-input-wrapper"
            @update:modelValue="v => input.onInput && input.onInput(v)"
          >
            <template #default="{ selectedValue }">
              <button class="truncate-text">
                <span style="width: 90%; display: block">
                  {{ selectedValue }}
                </span>
              </button>
            </template>
          </CCurrencyDropdown>
        </div>
        <div
          v-else-if="input.component === 'switch'"
          :key="i + 'switch'"
          class="input-wrapper switch"
        >
          <CSwitch
            v-bind="input.props"
            :model-value="input.props.value"
            size="tiny"
            type="secondary"
            @update:modelValue="v => input.onInput && input.onInput(v)"
          />
        </div>
        <div
          v-else-if="input.component === 'popup'"
          :key="i + 'popup'"
          class="input-wrapper"
        >
          <CDropdown
            :menu-styling="{ width: input.props.width }"
            style="width: 100%"
          >
            <template #trigger>
              <div
                style="
                  height: 26px;
                  display: flex;
                  align-items: center;
                  flex-wrap: nowrap;
                  overflow: hidden;
                  border-radius: 5px;
                  font-size: 13px;
                  cursor: pointer;
                "
              >
                <div style="padding: 0 5px">{{ input.props.value }}</div>
              </div>
            </template>
            <div>
              <FormInputRow
                v-for="(itm, ii) in input.props.popup.inputs"
                :key="ii"
                :inputs="itm.inputs"
                :label="itm.label"
              />
            </div>
          </CDropdown>
        </div>
        <div
          v-else-if="input.component === 'button'"
          :key="i + 'button'"
          class="input-wrapper"
        >
          <button
            style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            "
            @click="input.onClick"
          >
            {{ input.props.text }}
          </button>
        </div>
        <template v-else>
          {{ input.component }}
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { FontPickerDropdown } from '@cling/components/fonts'
import ColorPicker from '@cling/components/ui/ColorPicker'
import CCurrencyDropdown from '@cling/components/ui/CurrencyDropdown'
import CDropdown from '@cling/components/ui/Dropdown'
import CSwitch from '@cling/components/ui/Switch'

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ColorPicker,
    FontPickerDropdown,
    CCurrencyDropdown,
    CDropdown,
    CSwitch,
    FormInputRow: defineAsyncComponent(() => import('./FormInputRow.vue'))
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    infoTooltip: {
      type: String,
      default: ''
    },
    inputs: {
      type: Array,
      required: true
    }
  },
  methods: {
    onKeyPressNumberRestrict(e) {
      if (['-', '+', 'e', 'E'].includes(e.key)) e.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

$background: hsl(var(--gray-color-100) / 1);

.input-wrapper {
  position: relative;
  display: flex;
  width: auto;
  height: 26px;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background: $background;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.75);
  flex: 1;
  input,
  select,
  button {
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 0 0.5em;
    line-height: 2;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    &:focus {
      box-shadow: 0 0 0 1px hsl(var(--primary-color-500) / 1);
    }
  }
  .select-wrapper {
    position: relative;
    &::after {
      position: absolute;
      left: 1px;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 5px;
      background-color: $background;
      content: '';
      pointer-events: none;
    }
  }
  .select-wrapper::after,
  select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='rgba(0,0,0,0.5)' stroke-width='2.5' stroke-linecap='square' stroke-linejoin='arcs'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    background-position: calc(100% - 4px) 50%;
    background-repeat: no-repeat;
  }
  + * {
    margin-left: 1em;
  }
  &.switch {
    background: none;
    flex-grow: 0;
  }
}

.font-input-wrapper {
  width: 100%;
  & :deep(.dropdown-trigger) {
    width: 143px;
    & button {
      text-align: start;
    }
  }
}

.currency-input-wrapper {
  width: 100%;
  & :deep(.dropdown-trigger) {
    width: 100%;
    & button {
      text-align: start;
    }
  }
}

.info-tooltip {
  opacity: 0.3;
  margin-left: 5px;
  line-height: 0;
}

.slider {
  height: 100%;
  width: 4.5em;
  -webkit-appearance: none;
  background-color: transparent;
}

.slider::-moz-focus-outer {
  border: 0;
}

.slider:focus {
  outline: none;
}

.slider::-webkit-slider-runnable-track {
  background: linear-gradient(
    90deg,
    hsl(var(--primary-color-500) / 1) calc(var(--progress)),
    hsl(0, 0%, 85%) calc(var(--progress))
  );
  border-radius: 2px;
  height: 2px;
  will-change: transform;
}

.slider::-moz-range-track {
  background: linear-gradient(
    90deg,
    hsl(var(--primary-color-500) / 1) calc(var(--progress)),
    hsl(0, 0%, 85%) calc(var(--progress))
  );
  border-radius: 2px;
  height: 2px;
  will-change: transform;
}

.slider:disabled::-webkit-slider-runnable-track {
  background: hsl(0, 0%, 92%);
}

.slider:disabled::-moz-range-track {
  background: hsl(0, 0%, 92%);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: $white;
  border: none;
  border-radius: 50%;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.3),
    0 1px 0 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  height: 12px;
  margin-top: -5px;
  opacity: 1;
  width: 12px;
  will-change: transform;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  background-color: $white;
  border: none;
  border-radius: 50%;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.3),
    0 1px 0 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  height: 12px;
  margin-top: -5px;
  opacity: 1;
  width: 12px;
  will-change: transform;
}

.slider:disabled::-webkit-slider-thumb {
  display: none;
}

.slider:disabled::-moz-range-thumb {
  display: none;
}

.plain-button {
  background-color: $background;
  cursor: pointer;
  color: hsl(var(--gray-color-500) / 1);
  &:hover {
    background-color: hsl(var(--gray-color-200) / 1);
  }
  &:active {
    background-color: hsl(var(--gray-color-300) / 1);
  }
}
</style>
