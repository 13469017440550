import Spinner from '@cling/components/ui/Spinner'
import FloatingVue from '@cling/libs/FloatingVue'
import VueJsModal from '@cling/libs/VueJsModal'
import rollbar from '@cling/services/error/rollbar'

export default {
  install(app) {
    if (app.config.globalProperties.$modal) {
      return
    }
    // Third party plugins
    app.config.globalProperties.$rollbar = rollbar

    app.use(VueJsModal, {
      dynamicDefaults: {
        transition: 'no-animation'
      }
    })

    app.use(FloatingVue)

    // Third party components
    app.component('Spinner', Spinner)
  }
}
