import { mutationTypes } from './constants'
import {
  convertArrToObject,
  deepMergeReplace,
  getUniqueArrayElements
} from '../../utils'

const {
  SET_COMPANY_REFERENCES,
  DELETE_COMPANY_REFERENCE,
  CLEAR_COMPANY_REFERENCES,
  SET_COMPANY_REFERENCE_FETCHING,
  SET_COMPANY_REFERENCE_POSTING
} = mutationTypes

export default {
  // Set
  // Should only be called after any normalization has been executed from action
  // companyReferences: Array with objects
  // doClearOld: Optional bool if old data should be removed before set
  [SET_COMPANY_REFERENCES](state, { data }) {
    if (typeof data !== 'object') {
      throw Error(
        'Data must be a company reference or an array of company references!'
      )
    }
    const refs = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(refs)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },
  // Delete one
  [DELETE_COMPANY_REFERENCE](state, id) {
    const reference = state.data[id]
    if (reference) {
      delete state.companyReferencePublicIdToId[state.data[id].publicId]

      delete state.data[id]
    }
    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_COMPANY_REFERENCES
   * Clear all companyReferences
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_COMPANY_REFERENCES](state) {
    state.data = {}
    state.ids = []
  },

  /**
   * @name SET_COMPANY_REFERENCE_FETCHING
   * Set fetching status
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_COMPANY_REFERENCE_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_COMPANY_REFERENCE_POSTING
   * Set posting status
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_COMPANY_REFERENCE_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}
