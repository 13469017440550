import lang from '@cling/language'

import articles from '@/router/account/articles'
import clients from '@/router/account/clients'
import document from '@/router/account/document'
import library from '@/router/account/library'
import notifications from '@/router/account/notifications'
import overview from '@/router/account/overview'
import settings from '@/router/account/settings'
import Project from '@/views/account/project/Project.vue'

const Account = () => import('@/views/account/Account.vue')

let children = {
  overview,
  settings,
  notifications,
  guide: {
    name: 'onboarding',
    components: {
      content: () => import('@/views/account/onboarding/Onboarding.vue')
    },
    meta: {
      fullscreen: true
    }
  },
  'project/:id': {
    name: 'project',
    alias: 'projekt/:id',
    meta: {
      body: { background: 'white' },
      scrollBehavior: false
    },
    components: {
      content: Project
    },
    props: {
      content: true
    }
  },
  statistics: {
    name: 'statistics',
    components: {
      content: () =>
        import('@/views/account/overview/statistics/Statistics.vue')
    },
    meta: {
      feature: 'stats',
      navbar: {
        type: 'default',
        title: lang.t('statistics', { postProcess: 'capitalize' })
      },
      body: {
        background: 'white',
        fullSize: true
      }
    }
  }
}

children = Object.keys(children).map(route => ({
  ...children[route],
  path: route
}))

export default {
  alias: '/konto',
  component: Account,
  meta: {
    requiresAuth: true
  },
  props: {
    model: true,
    content: true
  },
  children: [...children, ...articles, ...clients, ...document, ...library]
}
