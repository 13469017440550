import axios from 'axios'
import { reactive } from 'vue'

import {
  GOOGLE_FONTS_API_KEY,
  GOOGLE_FONTS_LIST_URL,
  variantsMap
} from './constants'

// Re fetch fonts every two weeks if not updated by api restart
const fontsCacheLimit = 1000 * 60 * 60 * 24 * 14

export const Fonts = reactive({
  list: [],
  fetchedAt: null,
  error: '',
  loading: false
})

const fetchFontsList = async (sort = 'popularity') => {
  let result = []
  try {
    const { data } = await axios.get(GOOGLE_FONTS_LIST_URL, {
      params: {
        sort,
        key: GOOGLE_FONTS_API_KEY
      }
    })
    result = data.items || []
  } catch (err) {
    Fonts.error = err
  }
  return result
}

Fonts.load = async () => {
  if (Fonts.loading) {
    await Fonts.loading
    return
  }

  if (!Fonts.fetchedAt || Fonts.fetchedAt < Date.now() - fontsCacheLimit) {
    // eslint-disable-next-line no-async-promise-executor
    Fonts.loading = new Promise(async resolve => {
      Fonts.list = await fetchFontsList()
      Fonts.fetchedAt = Date.now()
      resolve(false)
    })
    await Fonts.loading
  }
}

// Filter out unsupported variants
// Add font file link to supported ones
export const getFormattedFontOptions = (variants = []) =>
  (variants || []).filter(o => !!variantsMap[o]).map(o => variantsMap[o])

export const getFontOptions = async family => {
  if (!family || typeof family !== 'string') return {}

  if (!Fonts.list.length && !Fonts.error) await Fonts.load()

  const { variants } = Fonts.list.find(itm => itm.family === family) || {}

  return getFormattedFontOptions(variants || [])
}
