<template>
  <div style="height: 100%; width: 100%; position: relative">
    <div
      :class="{
        'recently-added': recentlyAdded,
        'recently-removed': recentlyRemoved
      }"
      class="task-list"
    >
      <Simplebar>
        <div class="h-spacing">
          <div class="task-form">
            <CInput
              ref="input"
              v-model="newTaskName"
              :custom-class="`task-form-textarea is-info ${
                recentlyAdded ? 'recently-added' : ''
              }`"
              :placeholder="$t('placeholder')"
              type="textarea"
              filled
              auto-resize
              class="task-form-textarea-wrapper"
              @keydown.enter.prevent
              @keyup.enter="onSubmit"
            />
            <CIcon
              v-tooltip="$t('_common:save')"
              class="task-form-icon"
              type="corner-down-left"
              size="18"
              @click="
                () => {
                  onSubmit()
                  $refs.input.focus()
                }
              "
            />
          </div>
        </div>
        <Sortable
          :list="tasks"
          item-key="_uniqueId"
          :options="{
            group: 'tasks',
            ghost: 'sortable-ghost',
            filter: '.sortable-no-drag',
            ghostClass: 'sortable-ghost',
            forceFallback: true,
            fallbackTolerance: 5,
            onUpdate,
            onStart,
            onEnd
          }"
          class="list-group"
        >
          <template #item="{ item: task, index: i }">
            <div
              :key="task._uniqueId"
              :class="[
                task.tag ? `has-background-${task.theme}` : 'none',
                {
                  'is-dragging': isDragging,
                  'is-dragged': hideIndex === i,
                  'is-done': task.isDone,
                  'has-details':
                    task.description ||
                    (task.checklists && task.checklists.length),
                  'has-text-grey-darker': !task.tag
                }
              ]"
              class="task-item"
              @click.self="openModal(task.id)"
            >
              <CCheckbox
                :type="task.theme || 'secondary'"
                :inverted="task.tag && task.isDone"
                :model-value="task.isDone"
                size="normal"
                class="sortable-no-drag"
                style="margin-right: 0.5em"
                @click.stop
                @update:modelValue="updateTaskDone(task.id, $event)"
              />
              <div style="user-select: none; pointer-events: none">
                <div class="task-name">
                  {{ task.name || $t('_common:nameMissing') }}
                </div>
                <div
                  v-if="
                    task.description ||
                    (task.checklists && task.checklists.length)
                  "
                  class="task-details flex w-full items-center"
                >
                  <CIcon
                    v-if="task.description"
                    type="paper"
                    size="14"
                    style="margin-right: 0.5em"
                  />
                  <div v-if="task.checklists && task.checklists.length">
                    {{ task.checklists.filter(x => x.isChecked).length }}/{{
                      task.checklists.length
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Sortable>
      </Simplebar>
    </div>
    <div v-if="isDragging" class="fixed-hint">
      {{ $t('draggingHint') }}
    </div>
  </div>
</template>

<script>
import CCheckbox from '@cling/components/ui/Checkbox'
import 'simplebar/dist/simplebar.min.css'
import Sortable, { moveArrayElement } from '@cling/components/ui/Sortable'
import windowSize from '@cling/mixins/windowSize'
import useTaskStore from '@cling/stores/tasks'
import Simplebar from 'simplebar-vue'

import ProjectTaskModal from './ProjectTaskModal.vue'

import useApplicationModalsStore from '@/stores/applicationModals'

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.taskList'
  },
  name: 'ProjectTaskList',
  components: {
    Sortable,
    Simplebar,
    CCheckbox
  },
  mixins: [windowSize],
  props: {
    projectId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const taskStore = useTaskStore()
    const applicationModals = useApplicationModalsStore()
    return {
      taskStore,
      setCanCloseModal: applicationModals.setCanCloseModal
    }
  },
  data() {
    return {
      newTaskName: '',
      recentlyAdded: false,
      recentlyRemoved: false,
      isDragging: false,
      hideIndex: null,
      disableAnimation: true
    }
  },
  computed: {
    tasks() {
      const list = this.taskStore
        .getTasksByProjectId(this.projectId)
        .map(task => ({ ...task, theme: this.taskStore.getTheme(task) }))
      return list.length
        ? list.sort((a, b) => (a.index === null ? -1 : a.index - b.index)) // Newly added comes first
        : []
    },
    animationName() {
      if (this.disableAnimation) return null
      if (this.recentlyAdded) return 'add-list'
      return this.isDragging ? 'flip-list' : null
    }
  },
  watch: {
    projectId: {
      immediate: true,
      async handler(v) {
        this.disableAnimation = true
        await this.taskStore.fetchByProjectId(v)
        this.disableAnimation = false
      }
    }
  },
  mounted() {
    if (this.mq !== 'sm') {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },
  beforeUnmount() {
    this.setCanCloseModal(true) // In place as a safety measure
  },
  methods: {
    onStart(e) {
      this.isDragging = true
      this.setCanCloseModal(false)
      this.hideIndex = e.oldIndex
    },
    async onEnd(e) {
      this.isDragging = false
      // Removal
      if (e && !e.originalEvent.target.closest('.task-list')) {
        const { id } = this.tasks[e.oldIndex]
        this.taskStore.remove(id)
        this.toggleClass('recentlyRemoved')
      }
      this.hideIndex = null

      // The plugin doesn't handle events properly.
      // This is a hack so that we can stop a click event from triggering
      // and closing the parent modal when drag'n'drop releasing on the modal overlay
      setTimeout(() => {
        this.setCanCloseModal(true)
      }, 50) // Arbitary timeout
    },
    async onUpdate(e) {
      if (e) {
        const newOrder = moveArrayElement(
          this.tasks.map(({ id }) => id),
          e.oldIndex,
          e.newIndex
        )
        await this.taskStore.setPositions(newOrder)
      }
    },
    async onSubmit() {
      this.toggleClass('recentlyAdded')
      if (!this.newTaskName) return
      await this.submit()
      this.newTaskName = ''
    },
    async submit() {
      const newTask = this.taskStore.createTask({
        name: this.newTaskName.substring(0, 256), // Silent validation clean up
        projectId: this.projectId
      })

      await this.taskStore.submit({ body: { ...newTask } })
    },
    async updateTaskDone(id, value) {
      // Need to pass whole body until api has support single put key/val updates
      await this.taskStore.submit({
        id,
        body: {
          ...this.taskStore.getTaskById(id),
          isDone: value
        }
      })
    },
    toggleClass(stateRef, timeout = 300) {
      // draggable items does work with css-prop transition
      // This allows the item to temporarily animate
      this[stateRef] = true
      setTimeout(() => {
        this[stateRef] = false
      }, timeout)
    },
    openModal(id) {
      this.$modal.show(
        ProjectTaskModal,
        { id },
        {
          adaptive: true,
          height: 'auto',
          width: '90%',
          maxWidth: 560,
          scrollable: true,
          classes: 'overflow-visible primary'
        }
      )
    },
    handleClick() {
      this.onSubmit()
      this.$refs.input.focus()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

$bg: hsl(0, 0%, 100%); // hsl(208, 38%, 59%); // hsl(208, 53%, 68%);
$itemBg: hsl(0, 0%, 92%); // hsl(32, 58%, 47%); // hsla(0, 0%, 0%, 0.18);
$itemBg-dark: hsla(0, 0%, 0%, 0.5);
$color: hsl(0, 0%, 25%); // rgba($white, 0.8);

.h-spacing {
  padding-left: 2em;
  padding-right: 2em;
}
.task {
  &-list {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $bg;
    color: $color;
    font-size: 16px;
    padding: 2em;
    z-index: 2;
  }
  &-item {
    background-color: $itemBg;
    color: $color;
    padding: 0.75em;
    border-radius: 0.5em;
    display: flex;
    align-items: flex-start;
    line-height: 1.25;
    margin-bottom: 1em;
    cursor: pointer;
    user-select: none;
    word-break: break-word;
    border: 2px solid $itemBg;
    &.is-done {
      opacity: 0.5;
      .task-name {
        text-decoration: line-through;
        white-space: pre-wrap;
      }
    }
    &.has-details {
      padding-bottom: 0.5em;
      padding-top: 0.5em;
    }
    & .task-details {
      font-size: 12px;
      font-weight: 600;
      padding-top: 5px;
      opacity: 0.8;
      letter-spacing: 0.06em;
      text-decoration: initial;
    }
    .recently-added & {
      transition: 350ms;
      width: 100%;
    }
    &.add-list-leave-active .recently-removed & {
      opacity: 0;
      transition: none !important;
      margin: 0 !important;
    }
    &:not(.is-dragging) {
      &:hover {
        border-color: $secondary;
      }
    }
  }
}
.task-item {
  color: $white;
  border-color: transparent;
}

// Simplebar scroll height fix when no scrollbar
.task-list :deep(.simplebar-content) {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .list-group {
    // Force wrapper to fill up
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2.5em;
    :deep(&) > div {
      // Make sure dragndrop is taking up all the space
      height: 100%;
      flex: 1 0 auto;
      @extend .h-spacing;
      padding-bottom: 5em;
      padding-top: 3em;
    }
  }
}

.task-form {
  position: relative;
  &-icon {
    position: absolute;
    right: 1em;
    bottom: 1em;
    color: rgba($color, 0.75);
    cursor: pointer;
    &:hover {
      color: $secondary;
    }
  }
}
.task-form-textarea.task-form-textarea.task-form-textarea {
  background-color: $itemBg;
  color: $color;
  transition: 200ms ease;
  padding-bottom: 2.5em;
  min-height: 8em;
  height: 8em;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
  &.recently-added {
    animation: quickFade 200ms ease none;
    @keyframes quickFade {
      0% {
        background-color: $itemBg;
        color: $color;
      }
      100% {
        background-color: rgba($itemBg, 0.5);
        color: transparent;
      }
    }
  }
  &::placeholder {
    color: rgba($color, 0.75);
  }
}

.fixed-hint {
  color: $itemBg-dark;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  bottom: 2em;
  width: 12em;
  left: calc(50% - 6em);
  padding: 4px 10px;
  border-radius: 8px;
  text-align: center;
  animation: fadeBounceInDown 150ms ease;
  pointer-events: none;
  z-index: 2;
}

.add-list {
  &-move {
    transition: 350ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: transform;
  }
  &-enter-active {
    transition: 350ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: opacity, transform;
  }
  &-enter-from {
    opacity: 0;
    transform: translateY(-1em) scale(0.8, 0.5);
  }
  &-enter-to {
    opacity: 1;
    transform: translateY(0) scale(1, 1);
  }
}
.task-list {
  :deep(.sortable-drag) {
    opacity: 1;
    box-shadow: none;
  }
  :deep(.no-move) {
    transition: none;
  }
  :deep(.flip-list-move) {
    transition: none;
  }
  :deep(.sortable-chosen) {
    cursor: grabbing;
  }
  :deep(.is-dragged),
  :deep(.sortable-ghost) {
    border: 2px dashed hsl(0, 0%, 80%) !important;
    background-color: transparent !important;
    * {
      visibility: hidden;
    }
  }
}
</style>
