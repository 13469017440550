import { getCurrentInstance } from 'vue'

import Modal from './components/Modal.vue'
import PluginCore from './PluginCore'

const Plugin = {
  install(app, options = {}) {
    if (app.config.globalProperties.$modal) {
      return
    }

    const plugin = PluginCore(app, options)

    app.config.globalProperties.$modal = plugin

    plugin.setDynamicModalContainer(app)

    app.provide('$modal', plugin)

    app.component(plugin.context.componentName, Modal)

    this.$modal = plugin
  }
}

const UNINITIATED_ERROR_MESSAGE =
  'vue-js-modal: No Vue instance in context. Make sure to register the vue-js-modal plugin'

export function useModal() {
  const instance = getCurrentInstance()
  if (!instance) throw new Error(UNINITIATED_ERROR_MESSAGE)

  const modal = instance.appContext.config.globalProperties.$modal
  if (!modal) throw new Error(UNINITIATED_ERROR_MESSAGE)

  // TODO might need to bind instance.proxy
  return {
    show: modal.show,
    hide: modal.hide,
    hideAll: modal.hideAll,
    toggle: modal.toggle,
    getOpenInstances: modal.getOpenInstances
  }
}

export default Plugin
